var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import SReducer from "./SReducer";
var STRIP_COMMENTS = /((\/\/.*$)|(\/\*[\s\S]*?\*\/))/mg;
var ARGUMENT_NAMES = /([^\s,]+)/g;
export var Model = {};
var getParamNames = function (func) {
    var fnStr = func.toString().replace(STRIP_COMMENTS, '');
    var result = fnStr.slice(fnStr.indexOf('(') + 1, fnStr.indexOf(')')).match(ARGUMENT_NAMES);
    if (result === null) {
        result = [];
    }
    else {
        var final = [];
        result.map(function (name) {
            if (name.indexOf("_ref") > -1) {
                console.log("Es ref");
                console.log(fnStr.slice(fnStr.indexOf('(') + 1, fnStr.indexOf(')')).match(ARGUMENT_NAMES));
            }
            final.push(name);
        });
    }
    return result;
};
var SModel = /** @class */ (function () {
    function SModel(props) {
        var _this = this;
        var _a;
        this._Reducer = function (state, action) {
            if (!state)
                return _this.Reducer.initialState();
            if (action.component == _this.info.component) {
                if (action.type == "CLEAR") {
                    return _this.Reducer.initialState();
                }
                if (action.type == "TEST") {
                    return _this._test(state, action);
                }
                state.estado = action === null || action === void 0 ? void 0 : action.estado;
                state.error = action === null || action === void 0 ? void 0 : action.error;
                state.type = action === null || action === void 0 ? void 0 : action.type;
                var _function = _this._getFunction(action.type);
                if (_function) {
                    var result = _this.Reducer[_function](state, action);
                    if (!result)
                        return __assign({}, state);
                    return result;
                }
                return __assign({}, state);
            }
            return state;
        };
        this.name = props.info.component;
        Model[this.name] = this;
        this.info = __assign({ version: "1.0" }, props.info);
        this.reducerName = props.reducerName;
        this.image = props.image;
        this.pk = (_a = props.pk) !== null && _a !== void 0 ? _a : "key";
        this.Columns = props.Columns;
        if (this.Columns) {
            var pks = Object.keys(this.Columns).filter(function (k) { return _this.Columns[k].pk; });
            if (pks.length > 0) {
                this.pk = pks[0];
            }
            else {
                console.warn("SModel name " + this.name + " no select pk in Columns ");
            }
        }
        // this.component = props.component;
        // this.version = props.version;
        this.Action = new props.Action(this);
        this.Reducer = new props.Reducer(this);
    }
    SModel.getModel = function (name) {
        if (!this._models)
            return null;
        return this._models[name];
    };
    SModel.declare = function (Models) {
        this._models = Models;
        var TEST = function () {
            Object.values(Models).map(function (obj) {
                obj.Action.TEST();
            });
        };
        var CLEAR = function () {
            Object.values(Models).map(function (obj) {
                obj.Action.CLEAR();
            });
        };
        var setStore = function (store) {
            Object.values(Models).map(function (obj) {
                obj.store = store;
            });
        };
        var combineReducers = function (reducers) {
            Object.keys(Models).map(function (className) {
                if (Models[className].reducerName) {
                    reducers[Models[className].reducerName] = Models[className]._Reducer;
                }
                else {
                    reducers[className + "Reducer"] = Models[className]._Reducer;
                }
            });
            return reducers;
        };
        var _events = {
            setStore: setStore,
            combineReducers: combineReducers,
            CLEAR: CLEAR,
            TEST: TEST
        };
        Model = __assign(__assign({}, Models), { _events: _events });
        return { _events: _events };
    };
    SModel.prototype._get_image_download_path = function (apis, key) {
        if (!this.image)
            return null;
        var api = apis[this.image.api];
        var src = api + this.image.name + "/" + key;
        return src;
    };
    SModel.prototype._get_image_upload_path = function (apis, key) {
        if (!this.image)
            return null;
        var api = apis[this.image.api];
        var src = api + "upload/" + this.image.name + "/" + key;
        return src;
    };
    SModel.prototype._getFunction = function (type) {
        var superNames = Object.getOwnPropertyNames(SReducer.prototype);
        var types = Object.getPrototypeOf(this.Reducer);
        var typeNames = Object.getOwnPropertyNames(types);
        var select;
        superNames.map(function (name_function) {
            if (name_function == type) {
                select = name_function;
            }
        });
        typeNames.map(function (name_function) {
            if (name_function == type) {
                select = name_function;
            }
        });
        return select;
    };
    SModel.prototype._getFunctions = function () {
        var obj = this;
        var pt = Object.getPrototypeOf(obj.Action);
        var ptc = Object.getPrototypeOf(pt);
        var list = [];
        list = list.concat.apply(list, __spreadArray(__spreadArray([], Object.getOwnPropertyNames(pt), false), Object.getOwnPropertyNames(ptc), false));
        list = list.filter(function (value, index, self) {
            return index === self.findIndex(function (t) { return (t == value); });
        });
        list = list.filter(function (elm) { return [
            "constructor",
            "_getState",
            "_dispatch"
        ].indexOf(elm) <= -1; });
        var arr = list.map(function (nameFunc) {
            console.log(nameFunc, getParamNames(obj.Action[nameFunc]));
            // console.log(obj.Action[nameFunc].toString())
            return {
                nameFunc: nameFunc
            };
        });
        return list;
    };
    SModel.prototype._test = function (state, action) {
        state.test = {
            time: new Date().getTime(),
            passed: true
        };
        return __assign({}, state);
    };
    SModel.prototype.getDetail = function () {
        return {};
    };
    return SModel;
}());
export default SModel;
