var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { SAction, Model } from "servisofts-model";
import SSocket from 'servisofts-socket';
// import Model from "../..";
var Action = /** @class */ (function (_super) {
    __extends(Action, _super);
    function Action() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Action.prototype.getAll = function () {
        var empresa = Model.empresa.Action.getSelect();
        if (!empresa)
            return null;
        return this.getAllByKeyEmpresa(empresa.key);
    };
    Action.prototype.getAllByKeyEmpresa = function (key_empresa) {
        var reducer = this._getReducer();
        if (reducer.key_empresa != key_empresa) {
            reducer.data = "";
            reducer.key_empresa = key_empresa;
        }
        var resp = _super.prototype.getAll.call(this, {
            key_empresa: key_empresa,
            key_usuario: Model.usuario.Action.getKey()
        });
        return resp;
    };
    Action.prototype.getSelect = function () {
        var data = this.getAll();
        if (!data)
            return null;
        return Object.values(data).find(function (a) { return a.estado == 2; });
    };
    Action.prototype.cerrar = function (extra) {
        var empresa = Model.empresa.Action.getSelect();
        return SSocket.sendPromise(__assign(__assign(__assign({}, this.model.info), { type: "cerrar", key_empresa: empresa === null || empresa === void 0 ? void 0 : empresa.key }), extra));
    };
    Action.prototype.abrir = function (extra) {
        var empresa = Model.empresa.Action.getSelect();
        return SSocket.sendPromise(__assign(__assign(__assign({}, this.model.info), { type: "abrir", key_empresa: empresa === null || empresa === void 0 ? void 0 : empresa.key }), extra));
    };
    return Action;
}(SAction));
export default Action;
