var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { Component } from 'react';
import { SText, SView, STheme, SThread, SIcon } from 'servisofts-component';
import { getDefaultConfig } from '../index';
import SSession from './SSession/index';
// import { connect } from 'react-redux';
import SHttp from './SHttp';
// type SocketConfProps = {
//     config: SSocketConfigProps
// }
var _PROPS = null;
var SSocket = /** @class */ (function (_super) {
    __extends(SSocket, _super);
    function SSocket(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            log: []
        };
        SSocket.SocketInstace = _this;
        SSocket.initApi();
        // var data = require("../../../index.js");
        // alert(JSON.stringify(data));
        _PROPS = {
            dispatch: props.store.dispatch
        };
        return _this;
    }
    SSocket.register = function (observado, callback) {
        SSocket.Observados[observado] = callback;
    };
    SSocket.getSession = function () {
        if (!SSocket.defaultConfig)
            return null;
        if (!SSocket.Instance) {
            SSocket.Instance = new SSession(SSocket.defaultConfig);
        }
        return SSocket.Instance;
    };
    SSocket.identificarse = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var session = _this.getSession();
            if (!session) {
                reject("Session is undefined");
                return;
            }
            session.identificarse();
            resolve("succes");
        });
    };
    SSocket.sendPromise = function (data, timeout) {
        if (timeout === void 0) { timeout = 15000; }
        return new Promise(function (resolve, reject) {
            SSocket.getSession().sendPromise(resolve, reject, data, timeout);
        });
    };
    SSocket.sendHttp = function (url, data) {
        return SHttp.post(url, data, _PROPS);
    };
    SSocket.sendHttpAsync = function (url, data) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, SHttp.postAsync(url, data)];
            });
        });
    };
    SSocket.send = function (data) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                //TODO: send data to server
                if (!SSocket.getSession()) {
                    // alert(data.component)
                    return [2 /*return*/];
                }
                if (!SSocket.getSession().isOpen()) {
                    new SThread(100, "", true).start(function () {
                        _this.send(data);
                    });
                    return [2 /*return*/];
                }
                SSocket.getSession().send(data);
                return [2 /*return*/];
            });
        });
    };
    SSocket.prototype.getState = function () {
        if (!this.props.store)
            return;
        if (!this.props.store.getState)
            return;
        return this.props.store.getState();
    };
    SSocket.prototype.dispatch = function (dat) {
        if (!this.props.store)
            return;
        if (!this.props.store.dispatch)
            return;
        this.props.store.dispatch(dat);
    };
    SSocket.prototype.componentDidMount = function () {
        SSocket.initApi();
        SSocket.getSession().init(this);
    };
    SSocket.initApi = function () {
        if (!SSocket.defaultConfig)
            SSocket.defaultConfig = getDefaultConfig();
        if (SSocket.defaultConfig.ssl) {
            SSocket.api.root = "https://" + SSocket.defaultConfig.host + "/images/";
            SSocket.api.img = "https://" + SSocket.defaultConfig.host + "/img/";
            SSocket.api.manejador = "https://" + SSocket.defaultConfig.host + "/manejador/";
        }
        else {
            SSocket.api.root = "http://" + SSocket.defaultConfig.host + ":" + SSocket.defaultConfig.port.http + "/";
            SSocket.api.img = "http://" + SSocket.defaultConfig.host + ":" + SSocket.defaultConfig.port.http + "/img/";
            SSocket.api.manejador = "http://" + SSocket.defaultConfig.host + ":" + SSocket.defaultConfig.port.http + "/manejador/";
        }
        Object.keys(SSocket.defaultConfig.apis).map(function (key) {
            SSocket.api[key] = SSocket.defaultConfig.apis[key];
        });
    };
    // getLogs() {
    //     return this.state.log.map((item, index) => {
    //         return <SText fontSize={12} >{"\n"}{item}</SText>
    //     })
    // }
    SSocket.prototype.notifyObserver = function () {
        var _this = this;
        Object.keys(SSocket.Observados).forEach(function (key) {
            SSocket.Observados[key](_this);
        });
    };
    SSocket.prototype.render = function () {
        this.notifyObserver();
        // return <></>
        if (SSocket.getSession().isOpen()) {
            return null;
        }
        return React.createElement(React.Fragment, null);
        return (React.createElement(SView, { style: {
                width: 200,
                height: 200,
                position: "absolute",
                backgroundColor: STheme.color.background + "aa",
                overflow: "hidden"
            }, center: true },
            React.createElement(SView, { col: "xs-3 md-2 xl-1" },
                React.createElement(SIcon, { name: "Wifi", fill: STheme.color.secondary })),
            React.createElement(SText, null, "Conexion perdida")));
    };
    SSocket.defaultConfig = getDefaultConfig();
    SSocket.Observados = {};
    SSocket.SocketInstace = null;
    SSocket.api = {
        root: "",
        manejador: "",
        img: "",
        rp: ""
    };
    SSocket.Instance = null;
    return SSocket;
}(Component));
// const initStates = (state) => {
// return { state }
// };
// export default connect(initStates)(SSocket);
export default SSocket;
