var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import Svg from "react-native-svg";
import { Path, Rect } from "react-native-svg";
var Web = function (props) { return (React.createElement(Svg, __assign({}, props, { viewBox: "0 0 141.73 141.73" }),
    React.createElement(Rect, { fill: "#7a509c", width: "141.73", height: "141.73", rx: "34.98" }),
    React.createElement(Path, { fill: "#1d1d1b", d: "M127.07,96.31,112.85,50.1h-6.64l-.37-3.66H95V39.12h18.29V9.86H65.78V39.12H84.07v7.32H73.27L72.9,50.1H62.12V25.19l-5.48,2.74L53,26.11l-3.66,1.82-3.66-1.82L42,27.93l-5.49-2.74V50.1H29.68L15.46,96.31a20.11,20.11,0,0,0-.89,5.91v13.71a7.32,7.32,0,0,0,7.32,7.32h98.75a7.32,7.32,0,0,0,7.32-7.32V102.22A20.11,20.11,0,0,0,127.07,96.31ZM69.44,35.47V13.52h40.23v22Zm21.94,3.65v7.32H87.73V39.12Zm-14.8,11h26L104,64.73H75.12Zm-36.4-19L42,32l3.65-1.82L49.32,32,53,30.2,56.64,32l1.82-.91V62.9H40.18Zm84.12,84.82a3.66,3.66,0,0,1-3.66,3.66H21.89a3.66,3.66,0,0,1-3.66-3.66V102.22A16.55,16.55,0,0,1,19,97.38L32.38,53.75h4.14V62.9H32.86v3.66H65.78V62.9H62.12V53.75H72.54L71.07,68.38h37l-1.47-14.63h3.58l13.42,43.63a16.23,16.23,0,0,1,.73,4.84v13.71Z" }),
    React.createElement(Rect, { fill: "#1d1d1b", x: "23.72", y: "97.65", width: "95.1", height: "3.66" }),
    React.createElement(Rect, { fill: "#1d1d1b", x: "58.46", y: "108.62", width: "25.6", height: "3.66" }),
    React.createElement(Path, { fill: "#1d1d1b", d: "M107.51,72H78.58V94H111.9Zm-7,3.66h4l1.09,5.49h-5.08ZM87.73,90.33H82.24V84.84h5.49Zm0-9.14H82.24V75.7h5.49Zm9.14,9.14H91.38V84.84h5.49Zm0-9.14H91.38V75.7h5.49Zm3.66,9.14V84.84h5.81l1.1,5.49Z" }),
    React.createElement(Path, { fill: "#1d1d1b", d: "M35,72,30.63,94H64V72Zm7,18.29H35.09l1.1-5.49H42Zm0-9.14H36.92L38,75.7h4Zm9.14,9.14H45.66V84.84h5.49Zm0-9.14H45.66V75.7h5.49Zm9.14,9.14H54.81V84.84h5.48Zm0-9.14H54.81V75.7h5.48Z" }),
    React.createElement(Rect, { fill: "#1d1d1b", x: "43.83", y: "35.47", width: "10.97", height: "3.66" }),
    React.createElement(Rect, { fill: "#1d1d1b", x: "43.83", y: "42.78", width: "10.97", height: "3.66" }),
    React.createElement(Rect, { fill: "#1d1d1b", x: "43.83", y: "50.1", width: "10.97", height: "3.66" }),
    React.createElement(Path, { fill: "#1d1d1b", d: "M73.09,17.18V31.81H106V17.18Zm29.27,11H76.75V20.84h25.61Z" }),
    React.createElement(Rect, { fill: "#1d1d1b", x: "80.41", y: "55.58", width: "3.66", height: "3.66" }),
    React.createElement(Rect, { fill: "#1d1d1b", x: "95.04", y: "55.58", width: "3.66", height: "3.66" }),
    React.createElement(Rect, { fill: "#1d1d1b", x: "69.44", y: "90.33", width: "3.66", height: "3.66" }),
    React.createElement(Rect, { fill: "#1d1d1b", x: "69.44", y: "81.19", width: "3.66", height: "3.66" }),
    React.createElement(Rect, { fill: "#1d1d1b", x: "69.44", y: "72.04", width: "3.66", height: "3.66" }))); };
var Native = Web;
export default { Native: Native, Web: Web };
