var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SAction } from "servisofts-model";
import { Model } from "servisofts-model";
var Action = /** @class */ (function (_super) {
    __extends(Action, _super);
    function Action() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Action.prototype.getAll = function () {
        var usr = Model.usuario.Action.getUsuarioLog();
        if (!usr)
            return null;
        return _super.prototype.getAll.call(this, {
            key_usuario: usr === null || usr === void 0 ? void 0 : usr.key
        });
    };
    Action.prototype.getRoles = function () {
        var reducer = this._getReducer();
        var data = this.getAll();
        if (!data)
            return null;
        return reducer.roles;
    };
    Action.prototype.getPages = function (path, onlyFirstChildren) {
        if (path === void 0) { path = "/"; }
        if (onlyFirstChildren === void 0) { onlyFirstChildren = false; }
        var pages = this.getAll();
        if (!pages)
            return null;
        var resp = {};
        var keys = Object.keys(pages);
        keys = keys.sort();
        keys.map(function (key) {
            if (key.indexOf(path) == 0) {
                if (onlyFirstChildren) {
                    if (key.split("/").length == path.split("/").length) {
                        var obj = pages[key];
                        resp[key] = obj;
                    }
                }
                else {
                    var obj = pages[key];
                    resp[key] = obj;
                }
            }
        });
        return resp;
    };
    Action.prototype.getPermisos = function (url_page) {
        var pages = this.getAll();
        if (!pages)
            return null;
        var page = pages[url_page];
        if (!page)
            return null;
        if (!page.permisos)
            return null;
        return page.permisos;
    };
    Action.prototype.getPermiso = function (_a) {
        var url = _a.url, permiso = _a.permiso, _b = _a.loading, loading = _b === void 0 ? "cargando" : _b;
        var pages = this.getAll();
        if (!pages)
            return loading;
        var page = pages[url];
        if (!page)
            return null;
        if (!page.permisos)
            return null;
        if (!page.permisos[permiso])
            return null;
        var obj = page.permisos[permiso];
        return page.permisos[permiso];
    };
    Action.prototype.getPage = function (_a) {
        var url = _a.url, _b = _a.loading, loading = _b === void 0 ? "cargando" : _b;
        var pages = this.getAll();
        if (!pages)
            return loading;
        var page = pages[url];
        if (!page)
            return null;
        return page;
    };
    return Action;
}(SAction));
export default Action;
