var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import { SOrdenador, SView } from 'servisofts-component';
import { Model } from 'servisofts-model';
import MenuButtom from '../MenuButtom';
var index = /** @class */ (function (_super) {
    __extends(index, _super);
    function index(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {};
        return _this;
    }
    index.prototype.getPages = function () {
        var _this = this;
        var dataPage = Model.usuarioPage.Action.getPages(this.props.path, this.props.onlyFirstChildren);
        if (!dataPage)
            return null;
        return new SOrdenador([{ key: "index", order: "asc", peso: 2, type: "number" }, { key: "descripcion", "order": "asc", peso: 1 }]).ordernarObjetoToLista(dataPage).map(function (obj) {
            var _a;
            if (_this.props.blackList) {
                if (!_this.props.blackList.indexOf(obj.url))
                    return null;
            }
            if (_this.props.filter) {
                if (!_this.props.filter(obj))
                    return null;
            }
            return React.createElement(MenuButtom, { key: obj.url, url: obj.url, index: obj.index, permiso: (_a = _this.props.permiso) !== null && _a !== void 0 ? _a : "ver", params: _this.props.params });
        });
    };
    index.prototype.render = function () {
        return (React.createElement(SView, { col: "xs-12", row: true },
            this.getPages(),
            this.props.children));
    };
    return index;
}(Component));
export default index;
// export default connect(index);
