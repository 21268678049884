import toJson from "./toJson";
import SSocket from 'servisofts-socket';
export default (function (page) {
    return new Promise(function (resolve, reject) {
        var json = toJson(page);
        var url = SSocket.api.spdf;
        console.log(json);
        SSocket.sendHttpAsync(url + "api", {
            "component": "pdf",
            "type": "registro",
            "data": json
        }).then(function (e) {
            resolve(e);
            window.open(url + e.data, "NombreVentana", "height=612,width=900");
        })["catch"](function (e) {
            reject(e);
            console.error(e);
        });
    });
});
