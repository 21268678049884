var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from 'react';
import { SHr, SImage, SInput, SList, SLoad, SText, SView } from 'servisofts-component';
import { Model } from 'servisofts-model';
import SSocket from 'servisofts-socket';
var EditarUsuarioRol = /** @class */ (function (_super) {
    __extends(EditarUsuarioRol, _super);
    function EditarUsuarioRol(props) {
        return _super.call(this, props) || this;
    }
    EditarUsuarioRol.prototype.render = function () {
        var _this = this;
        this.pk = this.props.key_usuario;
        var rol = Model.rol.Action.getAll();
        var usuarioRol = Model.usuarioRol.Action.getAllByKeyUsuario(this.pk);
        var allowEdit = Model.usuarioPage.Action.getPermiso({ url: this.props.url, permiso: this.props.permiso });
        if (!rol)
            return React.createElement(SLoad, null);
        if (!usuarioRol)
            return React.createElement(SLoad, null);
        if (allowEdit == "cargando")
            return React.createElement(SLoad, null);
        return (React.createElement(SView, { col: "xs-12" },
            React.createElement(SHr, null),
            React.createElement(SText, { fontSize: 16, bold: true }, "Roles"),
            React.createElement(SHr, null),
            React.createElement(SList, { data: rol, buscador: true, order: [{ key: "descripcion", order: "asc", peso: 1 }], filter: function (itm) {
                    if (!_this.props.onlyActives)
                        return !itm.is_admin;
                    var usuarioRolActivo = Object.values(usuarioRol).find(function (o) { return o.key_rol == itm.key && o.estado > 0; });
                    return !!usuarioRolActivo;
                }, render: function (itm) {
                    var usuarioRolActivo = Object.values(usuarioRol).find(function (o) { return o.key_rol == itm.key && o.estado > 0; });
                    return React.createElement(SView, { row: true, col: "xs-12", style: {
                            alignItems: 'center'
                        } },
                        React.createElement(SInput, { col: null, type: "checkBox", defaultValue: !!usuarioRolActivo, disabled: !allowEdit || !!_this.props.disabled, onChangeText: function (e) {
                                if (e) {
                                    Model.usuarioRol.Action.registro({
                                        data: {
                                            key_rol: itm.key,
                                            key_usuario: _this.pk
                                        },
                                        key_usuario: Model.usuario.Action.getKey()
                                    });
                                }
                                else {
                                    Model.usuarioRol.Action.editar({
                                        data: __assign(__assign({}, usuarioRolActivo), { estado: 0 }),
                                        key_usuario: Model.usuario.Action.getKey()
                                    });
                                }
                            } }),
                        React.createElement(SView, { width: 4 }),
                        React.createElement(SView, { width: 30, height: 30, card: true },
                            React.createElement(SImage, { src: Model.rol._get_image_download_path(SSocket.api, itm.key) })),
                        React.createElement(SView, { width: 4 }),
                        React.createElement(SText, null, itm.descripcion));
                } })));
    };
    return EditarUsuarioRol;
}(Component));
export default EditarUsuarioRol;
