var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from 'react';
import { SIcon, SInput, SList, SPopup, SText, STheme, SView } from 'servisofts-component';
import { Model } from 'servisofts-model';
var SEPARATOR = "-";
var Item = /** @class */ (function (_super) {
    __extends(Item, _super);
    function Item(props) {
        var _this = _super.call(this, props) || this;
        _this.widthSpace = 4;
        _this.widthOptions = 30;
        _this.heightCuenta = 45;
        _this.widthCode = 100;
        _this.fontSizeCode = 12;
        _this.state = {
            open: _this.props.open
        };
        return _this;
    }
    Item.prototype.addNewBtn = function (obj, hijos) {
        var _this = this;
        var _a, _b;
        if ((_b = (_a = this.props.parent) === null || _a === void 0 ? void 0 : _a.state) === null || _b === void 0 ? void 0 : _b["new"]) {
            if (this.props.parent.state["new"].key == this.props.obj.key) {
                return React.createElement(SView, { width: this.widthOptions });
            }
        }
        if (!this.state.open && this.props.lvl) {
            if (hijos.length > 0) {
                return React.createElement(SView, { width: this.widthOptions, height: this.widthOptions, center: true, card: true, onPress: function () {
                        _this.setState({ open: true });
                    } },
                    React.createElement(SIcon, { name: 'Eyes', height: "80%", fill: STheme.color.text }));
            }
        }
        if (this.props.disabled) {
            return React.createElement(SView, { width: this.widthOptions });
        }
        // var itmNewParent = this.props.parent.state.new;
        return React.createElement(SView, { width: this.widthOptions, center: true, card: true, onPress: function () {
                _this.props.parent.setState({ "new": obj });
            } },
            React.createElement(SText, { center: true, color: STheme.color.lightGray }, "+"));
    };
    Item.prototype.getDescripcion = function (obj) {
        var _this = this;
        var _a, _b;
        if ((_b = (_a = this.props.parent) === null || _a === void 0 ? void 0 : _a.state) === null || _b === void 0 ? void 0 : _b.edit) {
            if (this.props.parent.state.edit.key == this.props.obj.key) {
                var itmNewParent = this.props.parent.state.edit;
                return React.createElement(React.Fragment, null,
                    " ",
                    React.createElement(SInput, { ref: function (ref) { return _this.inEdit = ref; }, isRequired: true, customStyle: "clean", height: true, defaultValue: itmNewParent.descripcion, autoFocus: true, placeholder: "Nombre de la cuenta", col: null, flex: true, style: {
                            // borderWidth: 1,
                            backgroundColor: STheme.color.card
                        }, onChangeText: function (e) {
                            // return e.toUpperCase()
                        } }),
                    React.createElement(SText, { width: this.widthOptions, color: STheme.color.danger, center: true, bold: true, onPress: function () {
                            SPopup.confirm({
                                title: "Seguro de eliminar la cuenta",
                                onPress: function () {
                                    Model.cuenta_contable.Action.editar(__assign(__assign({}, itmNewParent), { estado: 0 })).then(function (resp) {
                                        _this.props.parent.setState({ edit: null });
                                    })["catch"](function (e) {
                                        console.log(e);
                                    });
                                }
                            });
                        } }, "X"),
                    React.createElement(SText, { width: this.widthOptions, color: STheme.color.success, center: true, bold: true, onPress: function () {
                            if (_this.inEdit.verify()) {
                                var nombre = _this.inEdit.getValue();
                                if (itmNewParent.descripcion == nombre) {
                                    _this.props.parent.setState({ edit: null });
                                    return;
                                }
                                ;
                                Model.cuenta_contable.Action.editar(__assign(__assign({}, itmNewParent), { descripcion: nombre })).then(function (resp) {
                                    _this.props.parent.setState({ edit: null });
                                })["catch"](function (e) {
                                    console.log(e);
                                });
                            }
                        } }, "✓"));
            }
        }
        return React.createElement(SText, { flex: true, onPress: function () {
                if (_this.props.onSelect) {
                    _this.props.onSelect(obj);
                    return;
                }
                _this.props.parent.setState({ edit: obj });
            }, style: {
            // opacity: 1-(1 / ((this.props.lvl / 7) * 2))
            } }, obj.descripcion);
    };
    Item.prototype.item = function (obj, hijos) {
        var _this = this;
        return React.createElement(SView, { row: true, col: "xs-12", height: this.heightCuenta, center: true, onPress: function () {
                if (_this.props.onSelect) {
                    _this.props.onSelect(obj);
                    return;
                }
                _this.props.parent.setState({ edit: obj });
            } },
            this.addNewBtn(obj, hijos),
            React.createElement(SText, { width: this.widthCode, fontSize: this.fontSizeCode },
                " ",
                obj.codigo),
            this.getDescripcion(obj));
    };
    Item.prototype.submitNew = function () {
        var _this = this;
        if (this.input.verify()) {
            var nombre = this.input.getValue();
            var codigo = this.inp_cod.getValue();
            var cuenta_data = {
                descripcion: nombre,
                codigo: codigo
            };
            Model.cuenta_contable.Action.registro(cuenta_data).then(function (resp) {
                _this.props.parent.setState({ "new": null });
            })["catch"](function (e) {
                console.log(e);
            });
        }
    };
    Item.prototype.formatCode = function (n) {
        if (n < 10)
            return "0" + n;
        return n;
    };
    Item.prototype.addNew = function (hijos) {
        var _this = this;
        var _a, _b;
        if (!((_b = (_a = this.props.parent) === null || _a === void 0 ? void 0 : _a.state) === null || _b === void 0 ? void 0 : _b["new"]))
            return null;
        if (this.props.parent.state["new"].key != this.props.obj.key)
            return null;
        var itmNewParent = this.props.parent.state["new"];
        return React.createElement(SView, { row: true, col: "xs-12", height: this.heightCuenta, center: true },
            !this.widthSpace ? null : React.createElement(SView, { width: this.widthSpace }),
            React.createElement(SView, { width: this.widthOptions, onPress: function () {
                    _this.props.parent.setState({ "new": null });
                } },
                React.createElement(SText, { center: true, bold: true, fontSize: 18, color: STheme.color.danger }, "-")),
            React.createElement(SInput, { ref: function (ref) { _this.inp_cod = ref; }, customStyle: "clean", defaultValue: (!itmNewParent.codigo ? itmNewParent.codigo : (itmNewParent.codigo + SEPARATOR)) + this.formatCode((hijos.length + 1)), onChangeText: function (e) {
                    if (e.length <= (itmNewParent.codigo.length + 1)) {
                        if (itmNewParent.codigo.length >= 0) {
                        }
                        return itmNewParent.codigo + SEPARATOR;
                    }
                    return e;
                }, col: null, width: this.widthCode - 8, height: true, style: {
                    // borderWidth: 1,
                    fontSize: this.fontSizeCode,
                    backgroundColor: STheme.color.card
                } }),
            React.createElement(SView, { width: 8 }),
            React.createElement(SInput, { ref: function (ref) { _this.input = ref; }, isRequired: true, customStyle: "clean", height: true, defaultValue: "", autoFocus: true, placeholder: "Nombre de la cuenta", col: null, flex: true, style: {
                    // borderWidth: 1,
                    backgroundColor: STheme.color.card
                }, onChangeText: function (e) {
                    // return e.toUpperCase()
                }, onKeyPress: function (evt) {
                    if (evt.key === 'Enter') {
                        _this.submitNew();
                    }
                } }),
            React.createElement(SText, { width: this.widthOptions, color: STheme.color.success, center: true, bold: true, onPress: function () {
                    _this.submitNew();
                } }, "✓"));
    };
    Item.prototype.getHijos = function (obj, data) {
        var _this = this;
        if (!this.state.open && this.props.lvl)
            return null;
        return React.createElement(SList, { data: data, center: true, space: 0, order: [{ key: "codigo", order: "asc", peso: 2 }], render: function (item) {
                var _a;
                return React.createElement(Item, { obj: item, open: _this.props.open, parent: _this.props.parent, onSelect: _this.props.onSelect, lvl: ((_a = _this.props.lvl) !== null && _a !== void 0 ? _a : 0) + 1, disabled: _this.props.disabled });
            } });
    };
    Item.prototype.render = function () {
        var obj = this.props.obj;
        if (!obj)
            return null;
        if (obj.estado != 1 && obj.codigo)
            return null;
        var hijos = Model.cuenta_contable.Action.getFirstChildrens(obj.codigo);
        return React.createElement(SView, { col: "xs-12", center: true, row: true },
            !this.widthSpace ? null : React.createElement(SView, { width: this.widthSpace }),
            React.createElement(SView, { flex: true, center: true },
                this.item(obj, hijos),
                this.getHijos(obj, hijos),
                this.addNew(hijos)));
    };
    return Item;
}(Component));
export default (Item);
