var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import PageAbstract from './PageAbstract';
import { SForm, SNavigation } from 'servisofts-component';
import SSocket from 'servisofts-socket';
import Enviroment from './Enviroment';
var index = /** @class */ (function (_super) {
    __extends(index, _super);
    function index(props, config) {
        var _this = _super.call(this, props, __assign({ type: "pageContainer" }, config), "new") || this;
        _this._params = SNavigation.getAllParams();
        return _this;
    }
    index.prototype.$inputs = function () {
        var _this = this;
        var _a;
        var headers = {};
        var excludes = (_a = this.excludes) !== null && _a !== void 0 ? _a : [];
        if (this.Parent.model.image) {
            headers["image_profile"] = { type: "image" };
        }
        Object.keys(this.Parent.model.Columns).map(function (key) {
            var _a, _b, _c;
            if (excludes.indexOf(key) > -1) {
                return;
            }
            var col = (_b = (_a = _this.Parent) === null || _a === void 0 ? void 0 : _a.model) === null || _b === void 0 ? void 0 : _b.Columns[key];
            var label = key;
            if (col) {
                label = (_c = col.label) !== null && _c !== void 0 ? _c : key;
            }
            var typeImput = "";
            var extra = {};
            switch (col["type"]) {
                case "text":
                    typeImput = "text";
                    break;
                case "date":
                    typeImput = "date";
                    break;
                case "integer":
                    typeImput = "number";
                    break;
                case "boolean":
                    typeImput = "select";
                    extra.options = [{ key: "true", content: "SI" }, { key: "false", content: "NO" }];
                    extra.defaultValue = "false";
                    // if (defaultValue) {
                    //     defaultValue = JSON.parse(defaultValue) == true ? "true" : "false"
                    //     // defaultValue = parseFloat(defaultValue).toFixed(2)
                    // }
                    break;
            }
            headers[key] = __assign({ label: label, required: col["notNull"], type: typeImput }, extra);
            if (_this._params[key]) {
                headers[key].defaultValue = _this._params[key];
                headers[key].editable = false;
            }
            if (col.fk) {
                // headers[key] = { label: label, required: col["notNull"], type: "text", editable:false, onPress:()=>{
                //     alert("asd")
                // } }
            }
        });
        return headers;
    };
    index.prototype._buildHeaders = function () {
        var heads = this.$inputs();
        var arr = [];
        Object.keys(heads).map(function (key, i) {
            arr.push(__assign({ key: key, index: i + 1 }, heads[key]));
        });
        arr = arr.sort(function (a, b) { return a.index - b.index; });
        var data = {};
        arr.map(function (obj) {
            data[obj.key] = obj;
        });
        return data;
    };
    index.prototype.$onSubmit = function (data) {
        return null;
    };
    index.prototype.$submitFile = function (pk) {
        console.log("entro al sumbut fil");
        this.form.uploadFiles(this.Parent.model._get_image_upload_path(SSocket.api, pk));
        return null;
    };
    index.prototype.$getData = function () {
        return true;
    };
    index.prototype.$submitName = function () {
        var _a;
        return (_a = Enviroment.buttoms.confirm) !== null && _a !== void 0 ? _a : "confirm";
    };
    index.prototype.$render = function () {
        var _this = this;
        var data = this.$getData();
        if (!data)
            return this.$onLoading();
        return (React.createElement(SForm, { row: true, style: {
                justifyContent: 'space-between'
            }, col: "xs-12", ref: function (ref) { return _this.form = ref; }, inputs: this._buildHeaders(), 
            // onSubmitProps={{
            //     type:"outline",
            //     variant:""
            // }}
            onSubmitName: this.$submitName(), onSubmit: this.$onSubmit.bind(this) }));
    };
    return index;
}(PageAbstract));
export default index;
