var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import Svg from "react-native-svg";
import { Path, Rect } from "react-native-svg";
var Web = function (props) { return (React.createElement(Svg, __assign({}, props, { viewBox: "0 0 141.73 141.73" }),
    React.createElement(Rect, { fill: "#6695d3", width: "141.73", height: "141.73", rx: "34.98" }),
    React.createElement(Rect, { fill: "#010101", x: "44.14", y: "26.85", width: "51", height: "6.23" }),
    React.createElement(Path, { fill: "#010101", d: "M108.46,74.16a20,20,0,0,0-10.2-6.39V24.28a9.35,9.35,0,0,0-9.34-9.35H50.37A9.35,9.35,0,0,0,41,24.28V61.69H38.45A9.35,9.35,0,0,0,29.1,71v.42a9.26,9.26,0,0,0,1.44,5,9.34,9.34,0,0,0-3.77,7.5v.41a9.34,9.34,0,0,0,3.77,7.5,9.23,9.23,0,0,0-1.44,5v.41a9.35,9.35,0,0,0,9.35,9.35H41a9.34,9.34,0,0,0,5.46,8.15c.29,3.27,1.27,9.4,4.6,14.18a3.1,3.1,0,0,0,2.56,1.34,3.12,3.12,0,0,0,2.55-4.9,22.93,22.93,0,0,1-3.35-9.78H78.61l.25.38A3.11,3.11,0,1,0,84,112.37c-3.71-5.37-2.73-11.18,3-17.76,1-.82,3.83-3.65,3.49-7.48-.26-2.91-2.21-5.36-5.81-7.27a2.41,2.41,0,0,0-.33-.15c-.15-.06-3.55-1.43-3-4.89.32-2.1,4.6-2.58,7.41-2.39,7.21.49,12.36,2.43,14.89,5.61,1.79,2.25,2.37,5.18,1.77,8.95-.13.81-.26,1.63-.39,2.48-1.79,11.58-4,26-15.53,35.71a3.12,3.12,0,0,0,4,4.76c13.29-11.21,15.82-27.57,17.67-39.52.13-.83.25-1.65.38-2.45C112.38,82.45,111.36,77.81,108.46,74.16ZM36.11,87.44A3.12,3.12,0,0,1,33,84.32v-.41a3.12,3.12,0,0,1,3.11-3.12h4.67v6.65H36.11ZM35.33,71a3.12,3.12,0,0,1,3.12-3.11h2.33v6.64H38.45a3.12,3.12,0,0,1-3.12-3.11Zm5.45,29.28H38.45a3.12,3.12,0,0,1-3.12-3.12v-.41a3.12,3.12,0,0,1,3.12-3.12h2.33Zm9.59,9a3.11,3.11,0,0,1-3.11-3.11v-2.58H75.74A16.6,16.6,0,0,0,76,109.3ZM75.12,73.9c-1,6.51,3.87,10.33,6.74,11.53,1.69.93,2.35,1.76,2.4,2.24.06.67-.8,1.77-1.4,2.26a3,3,0,0,0-.41.39,31.23,31.23,0,0,0-4.81,7.07H47.26V24.28a3.12,3.12,0,0,1,3.11-3.12H88.92A3.12,3.12,0,0,1,92,24.28V66.5c-.94-.12-1.91-.22-2.92-.29C81.27,65.69,75.91,68.63,75.12,73.9Z" }),
    React.createElement(Path, { fill: "#010101", d: "M67.2,40.83H59.71a1.56,1.56,0,0,0-1.56,1.56v7.49a1.56,1.56,0,0,0,1.56,1.56H67.2a1.56,1.56,0,0,0,1.56-1.56V42.39A1.56,1.56,0,0,0,67.2,40.83Z" }),
    React.createElement(Path, { fill: "#010101", d: "M65.18,59.42H61.27V55.67a1.56,1.56,0,0,0-3.12,0V61a1.56,1.56,0,0,0,1.56,1.56h5.47a1.56,1.56,0,0,0,0-3.12Z" }),
    React.createElement(Path, { fill: "#010101", d: "M78.86,40.83H71.37a1.56,1.56,0,0,0-1.56,1.56v7.49a1.56,1.56,0,0,0,1.56,1.56h7.49a1.56,1.56,0,0,0,1.56-1.56V42.39A1.56,1.56,0,0,0,78.86,40.83Zm-1.55,7.5H72.93V44h4.38Z" }),
    React.createElement(Path, { fill: "#010101", d: "M77.34,54.15H72.9a1.56,1.56,0,0,0-1.56,1.56v4.44a1.56,1.56,0,0,0,1.56,1.56h4.44a1.55,1.55,0,0,0,1.55-1.56V55.71A1.55,1.55,0,0,0,77.34,54.15Z" }))); };
var Native = Web;
export default { Native: Native, Web: Web };
