import { SModel } from "servisofts-model";
import Action from "./Action";
import Reducer from "./Reducer";
export default new SModel({
    info: {
        service: "contabilidad",
        component: "asiento_contable_tipo_cambio"
    },
    Columns: {
        "key": { type: "text", pk: true },
        "fecha_on": { type: "timestamp" },
        "estado": { type: "integer", editable: true },
        "key_usuario": { type: "text", fk: "usuario" },
        "key_asiento_contable": { type: "text", fk: "asiento_contable", notNull: true },
        "key_moneda": { type: "text", fk: "empresa_moneda", notNull: true },
        "tipo_cambio": { type: "double", editable: true }
    },
    Action: Action,
    Reducer: Reducer
});
