var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import SSocket from 'servisofts-socket';
var SAction = /** @class */ (function () {
    function SAction(_model) {
        this.model = _model;
        // this.store = this.model.store;
    }
    SAction.prototype._dispatch = function (obj) {
        var _a, _b;
        (_b = (_a = this.model) === null || _a === void 0 ? void 0 : _a.store) === null || _b === void 0 ? void 0 : _b.dispatch(obj);
    };
    SAction.prototype._getState = function () {
        var _a, _b;
        return (_b = (_a = this.model) === null || _a === void 0 ? void 0 : _a.store) === null || _b === void 0 ? void 0 : _b.getState();
    };
    SAction.prototype._getReducer = function () {
        var state = this._getState();
        if (!state)
            return null;
        var name = this.model.info.component;
        return state[name + "Reducer"];
    };
    SAction.prototype.getAll = function (extra) {
        if (extra === void 0) { extra = {}; }
        var reducer = this._getReducer();
        var data = reducer === null || reducer === void 0 ? void 0 : reducer.data;
        if (!data) {
            if (reducer.estado == "cargando")
                return null;
            var petition = __assign(__assign(__assign({}, this.model.info), { type: "getAll", estado: "cargando" }), extra);
            SSocket.send(petition);
            return null;
        }
        return data;
    };
    SAction.prototype.getAllBy = function (filters) {
        if (filters === void 0) { filters = {}; }
        var data = this.getAll();
        if (!data)
            return null;
        var newData = {};
        Object.keys(data).map(function (key) {
            var obj = data[key];
            var allow = true;
            Object.keys(filters).map(function (filtro_key) {
                var filtro = filters[filtro_key];
                if (obj[filtro_key] != filtro) {
                    allow = false;
                }
            });
            if (allow) {
                newData[key] = obj;
            }
        });
        return newData;
    };
    SAction.prototype.getByKey = function (key, extra, _default) {
        if (extra === void 0) { extra = {}; }
        var data = this.getAll(extra);
        if (!data)
            return null;
        if (!data[key])
            return _default;
        return data[key];
    };
    SAction.prototype.registro = function (extra) {
        if (extra === void 0) { extra = {}; }
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        var petition = __assign(__assign(__assign({}, _this.model.info), { type: "registro", estado: "cargando" }), extra);
                        SSocket.sendPromise(petition).then(function (resp) {
                            _this._dispatch(resp);
                            resolve(resp);
                        })["catch"](function (e) {
                            reject(e);
                        });
                    })];
            });
        });
    };
    SAction.prototype.editar = function (extra) {
        if (extra === void 0) { extra = {}; }
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        var petition = __assign(__assign(__assign({}, _this.model.info), { type: "editar", estado: "cargando" }), extra);
                        SSocket.sendPromise(petition).then(function (resp) {
                            _this._dispatch(resp);
                            resolve(resp);
                        })["catch"](function (e) {
                            reject(e);
                        });
                    })];
            });
        });
    };
    SAction.prototype.eliminar = function (extra) {
        if (extra === void 0) { extra = {}; }
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        var petition = __assign(__assign(__assign({}, _this.model.info), { type: "eliminar", estado: "cargando" }), extra);
                        SSocket.sendPromise(petition).then(function (resp) {
                            _this._dispatch(resp);
                            resolve(resp);
                        })["catch"](function (e) {
                            reject(e);
                        });
                    })];
            });
        });
    };
    SAction.prototype.CLEAR = function () {
        this._dispatch(__assign(__assign({}, this.model.info), { "type": "CLEAR" }));
    };
    SAction.prototype.TEST = function () {
        this._dispatch(__assign(__assign({}, this.model.info), { "type": "TEST" }));
    };
    return SAction;
}());
export default SAction;
