var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import { SImage, SNavigation, SText, STheme, SView } from 'servisofts-component';
import { Model } from 'servisofts-model';
import SSocket from 'servisofts-socket';
var MenuButtom = /** @class */ (function (_super) {
    __extends(MenuButtom, _super);
    function MenuButtom() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MenuButtom.prototype.buildIcon = function (label) {
        return React.createElement(SView, { col: "xs-12", height: true, style: {
                position: "absolute"
            }, backgroundColor: STheme.color.card });
    };
    MenuButtom.prototype.render = function () {
        var _this = this;
        var label = this.props.label;
        var icon = this.props.icon;
        if (this.props.permiso) {
            var permiso = Model.usuarioPage.Action.getPermiso({ url: this.props.url, permiso: this.props.permiso });
            var page = Model.usuarioPage.Action.getPage({ url: this.props.url });
            if (permiso == "cargando") {
                return null;
            }
            if (page == "cargando") {
                return null;
            }
            if (!permiso)
                return null;
            if (!icon) {
                icon = React.createElement(SImage, { src: SSocket.api.roles_permisos + "/page/" + permiso.key_page, style: {
                        resizeMode: "cover"
                    } });
            }
            if (!label) {
                label = page.descripcion;
            }
        }
        return React.createElement(SView, { height: 120, 
            // width={90}
            col: "xs-4 sm-3 md-2", center: true },
            React.createElement(SView, { center: true, activeOpacity: 0.8, flex: true, onPress: function () {
                    if (_this.props.onPress) {
                        _this.props.onPress();
                        return;
                    }
                    if (_this.props.params) {
                        SNavigation.navigate(_this.props.url, _this.props.params);
                        return;
                    }
                    SNavigation.navigate(_this.props.url);
                } },
                React.createElement(SView, { width: 65, height: 65, style: {
                        borderRadius: 8,
                        overflow: 'hidden'
                    }, center: true }, icon),
                React.createElement(SView, { height: 4 }),
                React.createElement(SView, { col: "xs-11", flex: true },
                    React.createElement(SText, { center: true, fontSize: 12, font: "OpenSans-Bold", col: "xs-12", style: {
                        // letterSpacing: -0,
                        }, bold: true }, label))));
    };
    return MenuButtom;
}(Component));
export default MenuButtom;
