export default (function (props) {
    if (!props)
        return;
    var txt = "";
    if (props.children) {
        if (Array.isArray(props.children)) {
            props.children.map(function (a) {
                txt += "" + a;
            });
        }
        else {
            txt = props.children;
        }
    }
    var data = {
        "type": "text",
        "childrens": txt
    };
    if (props.style) {
        data.style = props.style;
    }
    return data;
});
