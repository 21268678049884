import { SModel } from "servisofts-model";
import Action from "./Action";
import Reducer from "./Reducer";
export default new SModel({
    info: {
        service: "contabilidad",
        component: "asiento_contable"
    },
    Columns: {
        "key": { type: "text", pk: true },
        "fecha_on": { type: "timestamp" },
        "estado": { type: "integer", editable: true },
        "key_usuario": { type: "text", fk: "usuario" },
        "fecha": { type: "timestamp" },
        "key_gestion": { type: "text", fk: "gestion", notNull: true },
        "descripcion": { type: "text", notNull: true, editable: true },
        "observacion": { type: "text", notNull: true, editable: true },
        "codigo": { type: "text", notNull: true, editable: true },
        "tipo": { type: "text", notNull: true, editable: true }
    },
    Action: Action,
    Reducer: Reducer
});
