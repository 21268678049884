var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Component } from 'react';
import { SThread } from 'servisofts-component';
import { connect } from 'react-redux';
// const URL = "http://192.168.0.3:8080/"
var INSTANCE = null;
var SHttp = /** @class */ (function (_super) {
    __extends(SHttp, _super);
    // static dispatch(data) {
    //     // console.log("dispatch", data);
    //     return INSTANCE.dispatch(data);
    // }
    function SHttp(props) {
        var _this = _super.call(this, props) || this;
        INSTANCE = props;
        _this.state = {};
        return _this;
    }
    SHttp.reintent = function (obj, props) {
        if (typeof obj != 'object') {
            try {
                obj = JSON.parse(obj);
            }
            catch (e) {
                // this.log("ERROR", "notifyRedux error al convertir el mensaje a JSON")
                return;
            }
        }
        new SThread(5000, "hilo_reintent" + obj.component, true).start(function () {
            obj.estado = "";
            props.dispatch(obj);
            // this.notifyRedux(obj);
        });
    };
    SHttp.send = function (url, data, files, props) {
        if (!props)
            return;
        props.dispatch(data);
        var formdata = new FormData();
        formdata.append("data", JSON.stringify(data));
        if (files) {
            for (var i = 0; i < files.length; i++) {
                formdata.append("file" + i, files[i]);
            }
        }
        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };
        SHttp.reintent(data, props);
        fetch(url, requestOptions)
            .then(function (response) { return response.text(); })
            .then(function (result) {
            props.dispatch(JSON.parse(result));
        })["catch"](function (error) { return console.log('error', error); });
    };
    SHttp.postAsync = function (url, data) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        var myHeaders = new Headers();
                        myHeaders.append("Content-Type", "application/json");
                        var requestOptions = {
                            method: 'POST',
                            body: JSON.stringify(data),
                            redirect: 'follow'
                        };
                        fetch(url, requestOptions)
                            .then(function (response) { return response.text(); })
                            .then(function (result) {
                            resolve(JSON.parse(result));
                        })["catch"](function (error) { return reject(error); });
                    })];
            });
        });
    };
    SHttp.post = function (url, data, props) {
        if (!props)
            return;
        props.dispatch(data);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'POST',
            // headers: myHeaders,
            body: JSON.stringify(data),
            redirect: 'follow'
        };
        SHttp.reintent(data, props);
        fetch(url, requestOptions)
            .then(function (response) { return response.text(); })
            .then(function (result) {
            // console.log(result);
            props.dispatch(JSON.parse(result));
        })["catch"](function (error) { return console.log('error', error); });
    };
    SHttp.getState = function () {
        return INSTANCE.state;
    };
    SHttp.prototype.render = function () {
        INSTANCE = this.props;
        return (this.props.children);
    };
    return SHttp;
}(Component));
var initStates = function (state) {
    return { state: state };
};
export default connect(initStates)(SHttp);
