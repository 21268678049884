var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { SThread } from 'servisofts-component';
import SSClient from './SSClient/index';
var DEBUG = false;
var SSession = /** @class */ (function () {
    function SSession(props) {
        this.timePing = 10 * (1000); // time * (milliseconds)
        this.timeReconect = 4 * (1000); // time * (milliseconds)
        this.timeReintent = 10 * (1000); // time * (milliseconds)
        this.pendinPromises = {};
        this.mensajeTemp = "";
        this.props = props;
        DEBUG = this.props.debug;
        if (this.props.timeReconnect) {
            this.timeReconect = this.props.timeReconnect;
        }
        if (this.props.timeReintent) {
            this.timeReintent = this.props.timeReintent;
        }
        // this.log("Instanciando el socket");
        // this.log("props", JSON.stringify(props, null, "\t"));
        this.socket = new SSClient(props, this);
    }
    SSession.prototype.isOpen = function () {
        if (!this.identificado)
            return false;
        return this.socket.isOpen();
    };
    SSession.prototype.init = function (instance) {
        // this.log("Init")
        this.ssocketInstance = instance;
        this.socket.open();
    };
    SSession.prototype.ping = function () {
        var _this = this;
        new SThread(this.timePing, "hiloSocket_ping_" + this.props.name, true).start(function () {
            if (!_this.isOpen()) {
                return;
            }
            if (!_this)
                return;
            if (!_this.identificado) {
                if (!_this.identificarse)
                    return;
                _this.identificarse();
            }
            _this.socket.open();
            _this.ping();
        });
    };
    SSession.prototype.reconect = function () {
        var _this = this;
        new SThread(this.timeReconect, "hiloSocket_reconect_" + this.props.name, false).start(function () {
            if (_this.isOpen()) {
                return;
            }
            _this.log("intentando reconectar..." + _this.timeReconect);
            _this.socket.open();
            // this.reconect();
        });
    };
    SSession.prototype.identificarse = function () {
        if (!this.ssocketInstance.props)
            return;
        if (!this.ssocketInstance.props.identificarse)
            return;
        var prIdent = this.ssocketInstance.props.identificarse({ state: this.ssocketInstance.getState() });
        var usr = false;
        var deviceKey = "deviceKey";
        var objSend = __assign(__assign({ component: "usuario", type: "identificacion", data: {}, deviceKey: deviceKey }, prIdent), { estado: "cargando" });
        console.log("[ Servisofts ] [ SSocket ] : identificando");
        this.send(objSend);
    };
    SSession.prototype.indentificarse = function () {
        console.warn("[ Servisofts ] [ SSocket ] : Method indentificarse() is deprecated. Plase move to identificarse()");
        if (!this.ssocketInstance.props)
            return;
        if (!this.ssocketInstance.props.identificarse)
            return;
        var prIdent = this.ssocketInstance.props.identificarse({ state: this.ssocketInstance.getState() });
        var usr = false;
        var deviceKey = "deviceKey";
        var objSend = __assign(__assign({ component: "usuario", type: "identificacion", data: {}, deviceKey: deviceKey }, prIdent), { estado: "cargando" });
        this.send(objSend);
    };
    SSession.prototype.reintent = function (obj) {
        var _this = this;
        if (typeof obj != 'object') {
            try {
                obj = JSON.parse(obj);
            }
            catch (e) {
                this.log("ERROR", "notifyRedux error al convertir el mensaje a JSON");
                return;
            }
        }
        new SThread(this.timeReintent, "hilo_reintent" + obj.component, true).start(function () {
            obj.estado = "";
            _this.notifyRedux(obj);
            // if (obj["_ssocket_promise"]) {
            //     if (this.pendinPromises[obj["_ssocket_promise"]]) {
            //         obj.estado = "error";
            //         obj.error = "timeOut";
            //         this.pendinPromises[obj["_ssocket_promise"]].reject(obj);
            //         delete this.pendinPromises[obj["_ssocket_promise"]];
            //     }
            //     return;
            // }
        });
    };
    SSession.prototype.send = function (msn, noRedux) {
        if (noRedux === void 0) { noRedux = false; }
        return __awaiter(this, void 0, void 0, function () {
            var str;
            var _this = this;
            return __generator(this, function (_a) {
                str = msn;
                if (typeof msn == 'object') {
                    str = JSON.stringify(msn);
                }
                if (!noRedux) {
                    this.reintent(str);
                }
                if (!this.socket) {
                    return [2 /*return*/];
                }
                if (!this.socket.send)
                    return [2 /*return*/];
                new SThread(10, str, true).start(function () {
                    _this.log("[OUT]", msn === null || msn === void 0 ? void 0 : msn.component, msn === null || msn === void 0 ? void 0 : msn.type, msn === null || msn === void 0 ? void 0 : msn.estado, msn);
                    _this.socket.send(str);
                    if (!noRedux) {
                        _this.notifyRedux(str);
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    SSession.prototype.sendPromise = function (resolve, reject, obj, timeout, cant, key) {
        if (cant === void 0) { cant = 1; }
        if (key === void 0) { key = this.generateUUID(); }
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                obj["_ssocket_promise"] = key;
                if (!this.iniciado) {
                    new SThread(1000, "hilo_reintent" + obj["_ssocket_promise"], false).start(function () {
                        if (cant > 10) {
                            obj.estado = "error";
                            obj.error = "noIniciado";
                            reject(obj);
                            return;
                        }
                        _this.sendPromise(resolve, reject, obj, timeout, cant++, key);
                        return;
                    });
                    return [2 /*return*/];
                }
                this.pendinPromises[obj["_ssocket_promise"]] = {
                    resolve: resolve,
                    reject: reject
                };
                this.send(obj, true);
                new SThread(timeout, "hilo_reintent_promise" + obj["_ssocket_promise"], true).start(function () {
                    if (obj["_ssocket_promise"]) {
                        if (_this.pendinPromises[obj["_ssocket_promise"]]) {
                            obj.estado = "error";
                            obj.error = "timeOut";
                            _this.pendinPromises[obj["_ssocket_promise"]].reject(obj);
                            delete _this.pendinPromises[obj["_ssocket_promise"]];
                        }
                        return;
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    SSession.prototype.onOpen = function () {
        this.ping();
        this.identificado = false;
        this.identificarse();
        // this.log("onOpen");
    };
    SSession.prototype.onClose = function (e) {
        console.log(e);
        this.log("onClose");
        this.reconect();
        this.identificado = false;
    };
    SSession.prototype.onError = function (evt) {
        this.log("onError", JSON.stringify(evt));
    };
    SSession.prototype.notifyRedux = function (obj) {
        if (typeof obj != 'object') {
            try {
                obj = JSON.parse(obj);
            }
            catch (e) {
                // console.log(e);
                this.log("ERROR", "notifyRedux error al convertir el mensaje a JSON");
                return;
            }
        }
        if (!this.ssocketInstance) {
            this.log("ERROR", "No hay ssocketInstance, No se pudo hacer dispatch del mensaje.");
            return;
        }
        try {
            // new SThread(20, "as", false).start(() => {
            this.ssocketInstance.dispatch(obj);
            // });
        }
        catch (e) {
            // console.log(e);
        }
        return;
    };
    SSession.prototype.onMessage = function (msn) {
        this.mensajeTemp += msn;
        if (/---SSofts---/.test(msn)) {
            this.mensajeTemp = this.mensajeTemp.replace(/---SSofts---/, '');
            var arr = this.mensajeTemp.split("---SSkey---");
            this.mensajeTemp = ""; // reset cola;
            var mensaje = arr[0]; //data
            var key = arr[1]; // SSkey
            try {
                var obj = JSON.parse(mensaje);
                this.log("[IN]", obj === null || obj === void 0 ? void 0 : obj.component, obj === null || obj === void 0 ? void 0 : obj.type, obj === null || obj === void 0 ? void 0 : obj.estado, obj);
                if (obj["_ssocket_promise"]) {
                    console.log("Entro al socket promise");
                    if (this.pendinPromises[obj["_ssocket_promise"]]) {
                        if (obj.estado == "error") {
                            this.pendinPromises[obj["_ssocket_promise"]].reject(obj);
                        }
                        else {
                            this.pendinPromises[obj["_ssocket_promise"]].resolve(obj);
                        }
                        delete this.pendinPromises[obj["_ssocket_promise"]];
                    }
                    return;
                }
                this.notifyRedux(obj);
                this.manejadorInterno(obj);
            }
            catch (e) {
                this.log("onMessage Error", e);
            }
        }
    };
    SSession.prototype.manejadorInterno = function (obj) {
        if (!obj.component)
            return;
        switch (obj.component) {
            case "servicio":
                switch (obj.type) {
                    case "init":
                        this.iniciado = true;
                }
            case "usuario":
                switch (obj.type) {
                    case "identificacion":
                        if (obj.estado == "exito") {
                            this.identificado = true;
                            this.notifyRedux({
                                component: "usuario",
                                type: "identificacion",
                                estado: "exito"
                            });
                            // this.log("identificado con exito");
                            return;
                        }
                    default: return;
                }
            default: return;
        }
    };
    SSession.prototype.getTitle = function (color, val) {
        return "\u001B[" + color + "m" + val + "\u001B[39m";
    };
    SSession.prototype.log = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        if (!DEBUG)
            return;
        var other = args.slice(1);
        var type = args[0];
        type = this.getTitle(type == "[IN]" ? 32 : 33, type);
        console.log.apply(console, __spreadArray([this.getTitle(34, "[SS]") + type], other, false));
    };
    SSession.prototype.generateUUID = function () {
        var d = new Date().getTime(); //Timestamp
        var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0; //Time in microseconds since page-load or 0 if unsupported
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16; //random number between 0 and 16
            if (d > 0) { //Use timestamp until depleted
                r = (d + r) % 16 | 0;
                d = Math.floor(d / 16);
            }
            else { //Use microseconds since page-load if supported
                r = (d2 + r) % 16 | 0;
                d2 = Math.floor(d2 / 16);
            }
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    };
    return SSession;
}());
export default SSession;
