var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import { SHr, SMath, SText, STheme, SView } from 'servisofts-component';
var index = /** @class */ (function (_super) {
    __extends(index, _super);
    function index(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {};
        return _this;
    }
    index.prototype.numberItem = function (val) {
        return React.createElement(SView, { width: 110, center: true },
            React.createElement(SText, { color: STheme.color.lightGray, col: "xs-12", style: {
                    alignItems: 'flex-end'
                } }, SMath.formatMoney(val !== null && val !== void 0 ? val : 0)));
    };
    index.prototype.renderTotal = function (total) {
        if (!total && total !== 0)
            return null;
        return React.createElement(SView, { width: 110, center: true },
            React.createElement(SText, { color: STheme.color.lightGray, col: "xs-12", style: {
                    alignItems: 'flex-end'
                } }, SMath.formatMoney(total !== null && total !== void 0 ? total : 0)));
    };
    index.prototype.renderData = function () {
        var _this = this;
        // if (!this.props.renderData) return null;
        if (this.props.excelHeaders) {
            return this.props.excelHeaders.map(function (a) {
                // console.log(a, this.props.data);
                return _this.numberItem(_this.props.data[a.key]);
            });
        }
        // let data = this.props.renderData(this.props.data);
        return React.createElement(SView, null);
    };
    index.prototype.render = function () {
        // let childrens = Model.cuenta_contable.Action.getChildrens(this.props.data.codigo);
        var _a, _b;
        var style = {
            backgroundColor: null
        };
        // if (!childrens.length) {
        // style.backgroundColor = this.props.selectColor;
        // }
        if (this.props.select) {
            style.backgroundColor = this.props.selectColor;
        }
        return (React.createElement(React.Fragment, null,
            React.createElement(SHr, { h: this.props.space / 2 }),
            React.createElement(SView, { col: "xs-12", style: style, onPress: this.props.onPress },
                React.createElement(SHr, { h: this.props.padding / 2 }),
                React.createElement(SView, { col: "xs-12", row: true },
                    React.createElement(SView, { width: 150 },
                        React.createElement(SText, null, (_a = this.props.data) === null || _a === void 0 ? void 0 : _a.codigo)),
                    React.createElement(SView, { flex: true },
                        React.createElement(SText, null, (_b = this.props.data) === null || _b === void 0 ? void 0 : _b.descripcion)),
                    this.renderData(),
                    this.renderTotal(this.props.total)),
                React.createElement(SHr, { h: this.props.padding / 2 })),
            React.createElement(SHr, { h: this.props.space / 2 }),
            !this.props.underline ? null : React.createElement(SHr, { height: 1, color: this.props.underlineColor })));
    };
    index.defaultProps = {
        space: 4,
        padding: 4,
        underlineColor: STheme.color.card,
        selectColor: "#2527A866"
    };
    return index;
}(Component));
export default (index);
