var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from 'react';
import { SIcon, SText, STheme, SView } from 'servisofts-component';
var MenuItem = /** @class */ (function (_super) {
    __extends(MenuItem, _super);
    function MenuItem(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {};
        return _this;
    }
    MenuItem.prototype.getIcon = function () {
        if (!this.props.icon)
            return null;
        return React.createElement(React.Fragment, null,
            React.createElement(SView, { width: 8 }),
            React.createElement(SIcon, { name: this.props.icon, width: 12, height: 12, fill: STheme.color.text }),
            React.createElement(SView, { width: 4 }));
    };
    MenuItem.prototype.renderContent = function () {
        if (!this.props.children)
            return React.createElement(SText, { style: { padding: 4 } }, this.props.label);
        return this.props.children;
    };
    MenuItem.prototype.render = function () {
        return (React.createElement(SView, { style: __assign(__assign({ borderRadius: 4, backgroundColor: STheme.color.card, overflow: "hidden" }, (this.props.type == "flex" ? {
                marginTop: 4
            } : {})), (this.props.type == "scroll" ? {} : {})
            // width:200,
            ), onPress: this.props.onPress, center: true },
            React.createElement(SView, { row: true },
                React.createElement(SView, { center: true, height: true }, this.getIcon()),
                this.renderContent())));
    };
    return MenuItem;
}(Component));
export default MenuItem;
