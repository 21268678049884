export default {
    "buttoms": {
        "new": "+ Crear",
        "edit": "Editar",
        "delete": "Eliminar",
        "table": "Ver Tabla",
        "confirm": "Aceptar",
        "restore": "Recuperar"
    },
    "pages": {
        "list": "Lista",
        "table": "Tabla",
        "new": "Nuevo",
        "profile": "Perfil",
        "edit": "Editar",
        "delete": "Eliminar",
        "restore": "Recuperar"
    }
};
