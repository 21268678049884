export default (function (props) {
    if (!props)
        return;
    var childrens = [];
    var data = {
        "type": "page"
    };
    if (props.style) {
        data.style = props.style;
    }
    return data;
});
