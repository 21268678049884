var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from 'react';
import { STheme } from 'servisofts-component';
import { Svg, Circle, Text } from 'react-native-svg';
import * as Func from '../Functions';
var index = /** @class */ (function (_super) {
    __extends(index, _super);
    function index(props) {
        return _super.call(this, props) || this;
    }
    index.prototype.render = function () {
        var _this = this;
        var width = 250;
        var strokeWidth = width / 2;
        var scale = width - strokeWidth;
        var render_circles = function () {
            if (!_this.props.data)
                return null;
            var max = Func.calcular_suma(_this.props.data);
            var arr = _this.props.data.sort(function (a, b) { return b.val - a.val; });
            var actual = 0;
            return arr.map(function (obj, i) {
                var _a, _b;
                var porcent = Func.calcular_procentaje({ val: obj.val, max: max });
                if (arr[i - 1]) {
                    actual += 360 * (Func.calcular_procentaje({ val: arr[i - 1].val, max: max }));
                }
                var porcent_text = actual + ((porcent * 360) / 2);
                var txtDir = (porcent_text > 90 && porcent_text < 270) ? -1 : 1;
                return React.createElement(React.Fragment, null,
                    React.createElement(Circle, __assign({ cx: width / 2, cy: width / 2, r: (scale - _this.props.strokeWidth) / 2, stroke: (_a = obj.color) !== null && _a !== void 0 ? _a : Func.color_random(), fill: "transparent", strokeWidth: strokeWidth - _this.props.strokeWidth, strokeDasharray: scale * Math.PI, strokeDashoffset: (scale * Func.calcular_grados_to_strokeDashoffset(360 * (porcent))), transform: "rotate(".concat(actual, " ").concat(strokeWidth, " ").concat(strokeWidth, ")") }, Func.create_onPress(function () { return _this.props.onSelect ? _this.props.onSelect(obj) : null; }))),
                    React.createElement(Text, { fill: STheme.color.text, fontSize: "16", fontWeight: "bold", "font-family": "Arial", alignmentBaseline: "central", textAnchor: "middle", transform: "scale(".concat(txtDir, ",").concat(txtDir, ") rotate(").concat(porcent_text, ",").concat(strokeWidth * txtDir, ",").concat(strokeWidth * txtDir, ")"), x: (85 * txtDir) + "%", y: (50 * txtDir) + "%" }, (_b = obj.info) !== null && _b !== void 0 ? _b : (((porcent * 100)).toFixed(0) + "%")));
            });
        };
        return (React.createElement(Svg, { height: "100%", width: "100%", viewBox: "0 0 ".concat(width, " ").concat(width) },
            React.createElement(Circle, { cx: width / 2, cy: width / 2, r: scale - this.props.strokeWidth, stroke: this.props.stroke, fill: this.props.fill, strokeWidth: this.props.strokeWidth }),
            render_circles()));
    };
    index.defaultProps = {
        data: null,
        strokeWidth: 1
    };
    return index;
}(Component));
export default index;
