var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Model, SAction, SModel } from "servisofts-model";
import SSocket from 'servisofts-socket';
// import Model from "../..";
var Action = /** @class */ (function (_super) {
    __extends(Action, _super);
    function Action() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Action.prototype.getAll = function (_a) {
        var key_gestion = _a.key_gestion;
        return this.getAllByKeyGestion(key_gestion);
    };
    Action.prototype.getAllByKeyGestion = function (key_gestion) {
        var reducer = this._getReducer();
        if (reducer.key_gestion != key_gestion) {
            reducer.data = "";
            reducer.key_gestion = key_gestion;
        }
        var resp = _super.prototype.getAll.call(this, {
            key_gestion: key_gestion,
            key_usuario: SModel.getModel("usuario").Action.getKey()
        });
        return resp;
    };
    Action.prototype.getByKey = function (key, _a) {
        var key_gestion = _a.key_gestion;
        var data = this.getAllByKeyGestion(key_gestion);
        if (!data)
            return null;
        return data[key];
    };
    Action.prototype.getByKeyAsync = function (key) {
        return SSocket.sendPromise(__assign(__assign({}, this.model.info), { type: "getByKey", key: key, key_usuario: Model.usuario.Action.getKey() }));
    };
    Action.prototype.getByKeyCompletoAsync = function (key) {
        return SSocket.sendPromise(__assign(__assign({}, this.model.info), { type: "getByKeyCompleto", key: key, key_usuario: Model.usuario.Action.getKey() }));
    };
    Action.prototype.pdf = function (_a) {
        var key = _a.key;
        return SSocket.sendPromise(__assign(__assign({}, this.model.info), { type: "pdf", key: key, key_usuario: Model.usuario.Action.getKey() }));
    };
    Action.prototype.getStateInfo = function (key) {
        var info = {
            "ingreso": { key: "ingreso", color: "#84C4F4", codigo: "I" },
            "egreso": { key: "egreso", color: "#FBA3A3", codigo: "E" },
            "traspaso": { key: "traspaso", color: "#A2F994", codigo: "T" }
        };
        return !key ? info : info[key];
    };
    return Action;
}(SAction));
export default Action;
