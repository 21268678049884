var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import Icon from "./icon.svg";
var Native = Icon;
var Web = function (props) { return (React.createElement("svg", __assign({}, props, { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 141.73 141.73" }),
    React.createElement("g", { id: "Capa_2", "data-name": "Capa 2" },
        React.createElement("g", { id: "Capa_1-2", "data-name": "Capa 1" },
            React.createElement("rect", { width: "141.73", height: "141.73", rx: "34.98", fill: "#e83759" }),
            React.createElement("path", { d: "M86.53,52.91a2.65,2.65,0,0,0-2.65,2.65v50.19a2.66,2.66,0,0,0,5.31,0V55.56A2.66,2.66,0,0,0,86.53,52.91Z", fill: "#fff" }),
            React.createElement("path", { d: "M55.2,52.91a2.66,2.66,0,0,0-2.66,2.65v50.19a2.66,2.66,0,0,0,5.31,0V55.56A2.65,2.65,0,0,0,55.2,52.91Z", fill: "#fff" }),
            React.createElement("path", { d: "M32.36,45.58V111a14.65,14.65,0,0,0,3.9,10.1,13,13,0,0,0,9.49,4.1H96a13,13,0,0,0,9.48-4.1,14.65,14.65,0,0,0,3.9-10.1V45.58a10.14,10.14,0,0,0-2.6-19.94H93.17V22.32A10.41,10.41,0,0,0,82.66,11.83H59.08A10.41,10.41,0,0,0,48.56,22.32v3.32H35a10.14,10.14,0,0,0-2.61,19.94ZM96,119.9H45.75c-4.54,0-8.08-3.9-8.08-8.89V45.82h66.39V111C104.06,116,100.53,119.9,96,119.9ZM53.87,22.32a5.12,5.12,0,0,1,5.21-5.18H82.66a5.12,5.12,0,0,1,5.2,5.18v3.32h-34ZM35,31h71.8a4.78,4.78,0,1,1,0,9.56H35A4.78,4.78,0,1,1,35,31Z", fill: "#fff" }),
            React.createElement("path", { d: "M70.87,52.91a2.66,2.66,0,0,0-2.66,2.65v50.19a2.66,2.66,0,0,0,5.31,0V55.56A2.65,2.65,0,0,0,70.87,52.91Z", fill: "#fff" }))))); };
export default { Native: Native, Web: Web };
