var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from 'react';
// import { connect } from 'servisofts-page';
import { SHr, SInput, SLoad, SPopup, SScrollView2, SText, STheme, SView, SBuscador, SNavigation } from 'servisofts-component';
import Item from './Item';
import Model from '../../Model';
import PopupAddCuenta from './PopupAddCuenta';
import PopupEditarCuenta from './PopupEditarCuenta';
import ExportExcel from './exportExcel';
import ImportExcel from './importExcel';
var PlanDeCuentas = /** @class */ (function (_super) {
    __extends(PlanDeCuentas, _super);
    function PlanDeCuentas(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            nivel_open: 1
        };
        return _this;
    }
    PlanDeCuentas.prototype.loadData = function () {
        var _this = this;
        this.data = Model.cuenta_contable.Action.getAllOrder();
        if (!this.data)
            return false;
        if (this.props.initialCode) {
            this.data = this.data.filter(function (o) { return (o.codigo + "").startsWith(_this.props.initialCode); });
        }
        if (this.props.witheList) {
            this.data = this.data.filter(function (o) {
                // 
                var valid = false;
                _this.props.witheList.map(function (code) {
                    if ((o.codigo + "").startsWith(code)) {
                        valid = true;
                    }
                });
                return valid;
            });
        }
        if (this.props.close) {
            this.niveles = Model.cuenta_contable.Action.getNiveles(this.data);
            var sizeLvl = Object.keys(this.niveles)[this.state.nivel_open - 1];
            this.data = this.data.filter(function (o) { return (o.codigo + "").length <= parseFloat(sizeLvl); });
        }
        return true;
    };
    PlanDeCuentas.prototype.getCuentas = function () {
        var _this = this;
        return Object.values(SBuscador.filter({
            data: this.data,
            txt: this.state.buscar
        })).map(function (cuenta) {
            var _a, _b, _c;
            if (!cuenta.estado)
                return null;
            if (_this.props.soloHojas) {
                if (Model.cuenta_contable.Action.getFirstChildrens(cuenta.codigo).length > 0) {
                    return null;
                }
            }
            var total = null;
            if (_this.props.renderTotal) {
                total = (_a = _this.props.renderTotal(cuenta)) !== null && _a !== void 0 ? _a : 0;
            }
            if (_this.props.renderData) {
                var datar_1 = _this.props.renderData(cuenta);
                if (datar_1) {
                    Object.keys(datar_1).map(function (k) {
                        cuenta[k] = datar_1[k];
                    });
                }
                // cuenta = { ...cuenta, ...}
            }
            return React.createElement(Item, __assign({ select: ((_c = (_b = _this.state) === null || _b === void 0 ? void 0 : _b.select) === null || _c === void 0 ? void 0 : _c.codigo) == cuenta.codigo, renderData: _this.props.renderData, excelHeaders: _this.props.excelHeaders }, _this.props.itemProps, { onPress: function () {
                    var _a, _b;
                    if (_this.props.onSelect) {
                        console.log("entro on select");
                        if (Model.cuenta_contable.Action.getFirstChildrens(cuenta.codigo).length <= 0) {
                            _this.props.onSelect(cuenta);
                        }
                    }
                    if (((_b = (_a = _this.state) === null || _a === void 0 ? void 0 : _a.select) === null || _b === void 0 ? void 0 : _b.codigo) == cuenta.codigo) {
                        _this.setState({ select: {} });
                    }
                    else {
                        _this.setState({ select: cuenta });
                    }
                    // this.props.onSelect(cuenta);
                }, total: total, data: __assign(__assign({}, cuenta), { codigo: cuenta.codigo, descripcion: cuenta.descripcion }) }));
        });
    };
    PlanDeCuentas.prototype.eliminarCuenta = function () {
        var _this = this;
        SPopup.confirm({
            title: "Elimianr cuenta",
            message: "Seguro de elimar la cuenta?",
            onPress: function () {
                Model.cuenta_contable.Action.editar(__assign(__assign({}, _this.state.select), { estado: 0 })).then(function (resp) {
                })["catch"](function (e) {
                    console.log(e);
                });
            }
        });
    };
    PlanDeCuentas.prototype.getEliminar = function () {
        var _this = this;
        var _a, _b, _c, _d;
        if (!((_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.select) === null || _b === void 0 ? void 0 : _b.codigo))
            return;
        var childs = Model.cuenta_contable.Action.getFirstChildrens((_d = (_c = this.state) === null || _c === void 0 ? void 0 : _c.select) === null || _d === void 0 ? void 0 : _d.codigo);
        if (childs.length > 0) {
            return null;
        }
        return React.createElement(React.Fragment, null,
            React.createElement(SView, { padding: 8, card: true },
                React.createElement(SText, { onPress: function () {
                        _this.eliminarCuenta();
                    }, color: STheme.color.danger }, "Eliminar")),
            React.createElement(SView, { width: 16 }));
    };
    PlanDeCuentas.prototype.getCentroCosto = function () {
        var _this = this;
        var _a, _b, _c, _d;
        if (!((_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.select) === null || _b === void 0 ? void 0 : _b.codigo))
            return;
        var childs = Model.cuenta_contable.Action.getFirstChildrens((_d = (_c = this.state) === null || _c === void 0 ? void 0 : _c.select) === null || _d === void 0 ? void 0 : _d.codigo);
        if (childs.length > 0) {
            return null;
        }
        return React.createElement(React.Fragment, null,
            React.createElement(SView, { padding: 8, card: true },
                React.createElement(SText, { onPress: function () {
                        var _a, _b;
                        SNavigation.navigate("/contabilidad/cuenta_centro_costo", { codigo: (_b = (_a = _this === null || _this === void 0 ? void 0 : _this.state) === null || _a === void 0 ? void 0 : _a.select) === null || _b === void 0 ? void 0 : _b.codigo });
                        // this.eliminarCuenta()
                    } }, "CxC")),
            React.createElement(SView, { width: 16 }));
    };
    PlanDeCuentas.prototype.render_is_close = function () {
        var _this = this;
        if (!this.props.close)
            return null;
        return React.createElement(SView, { card: true, padding: 8, onPress: function () {
            } },
            React.createElement(SView, { row: true },
                React.createElement(SInput, { value: "Nivel = ".concat(this.state.nivel_open), type: 'select', options: [
                        { key: "1", component: "Seleccionar nivel" },
                        { key: "1", component: "1 - CAPITULO" },
                        { key: "2", component: "2 - GRUPO" },
                        { key: "3", component: "3 - CUENTA" },
                        // { key: "4", component: "4 - MONEDA" },
                        { key: "4", component: "4 - SUB_CUENTA" },
                        { key: "5", component: "5 - ANALITICA" },
                    ], customStyle: "clean", onChangeText: function (val) {
                        _this.setState({ nivel_open: val });
                    } })));
    };
    PlanDeCuentas.prototype.renderHeader = function () {
        var _this = this;
        var _a, _b;
        return React.createElement(SView, { col: "xs-12", center: true, style: {
                padding: 0
            } },
            React.createElement(SView, { col: "xs-12", row: true },
                !this.props.allowCreate ? null : React.createElement(React.Fragment, null,
                    React.createElement(SView, { padding: 8, card: true },
                        React.createElement(SText, { onPress: function () { PopupAddCuenta.open(_this.state.select); } }, "Crear")),
                    React.createElement(SView, { width: 16 })),
                !((_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.select) === null || _b === void 0 ? void 0 : _b.codigo) || !this.props.allowEdit ? null : React.createElement(React.Fragment, null,
                    React.createElement(SView, { padding: 8, card: true },
                        React.createElement(SText, { onPress: function () { PopupEditarCuenta.open(_this.state.select); } }, "Editar")),
                    React.createElement(SView, { width: 16 })),
                this.getCentroCosto(),
                this.getEliminar(),
                !this.props.allowExport ? null : React.createElement(React.Fragment, null,
                    " ",
                    React.createElement(ExportExcel, { data: this.data, renderTotal: this.props.renderTotal, excelName: this.props.excelName, header: this.props.excelHeaders }),
                    React.createElement(SView, { width: 16 })),
                !this.props.allowImport ? null : React.createElement(React.Fragment, null,
                    " ",
                    React.createElement(ImportExcel, { data: this.data }),
                    " ",
                    React.createElement(SView, { width: 16 })),
                this.render_is_close()),
            React.createElement(SHr, { height: 4 }),
            React.createElement(SBuscador, { data: this.data, onChange: function (txt) {
                    _this.setState({ buscar: txt });
                } }));
    };
    PlanDeCuentas.prototype.render = function () {
        var _a;
        if (!this.loadData())
            return React.createElement(SLoad, null);
        var width = (_a = this.props.width) !== null && _a !== void 0 ? _a : 500;
        return (React.createElement(SView, { col: "xs-12", height: true, center: true, style: {
                maxWidth: width
            } },
            this.renderHeader(),
            React.createElement(SView, { col: "xs-12", flex: true, center: true, style: {
                    maxWidth: width
                } },
                React.createElement(SScrollView2, { contentContainerStyle: {
                        width: null
                    } },
                    React.createElement(SHr, null),
                    React.createElement(SView, { width: width }, this.getCuentas()),
                    React.createElement(SHr, { h: 100 })))));
    };
    return PlanDeCuentas;
}(Component));
export default PlanDeCuentas;
