export default (function (props) {
    if (!props)
        return;
    // let childrens = [];
    // if (props.children) {
    //     if (Array.isArray(props.children)) {
    //         props.children.map(a => {
    //             let sf = toJson(a);
    //             if (sf) {
    //                 childrens.push(sf)
    //             }
    //         })
    //     } else {
    //         let sf = toJson(props.children);
    //         if (sf) {
    //             childrens.push(sf)
    //         }
    //     }
    // }
    var data = {
        "type": "div"
    };
    if (props.style) {
        data.style = props.style;
    }
    return data;
});
