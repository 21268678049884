var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import PageAbstract from './PageAbstract';
import { SList, SNavigation } from 'servisofts-component';
import Item from './item';
import Enviroment from './Enviroment';
var list = /** @class */ (function (_super) {
    __extends(list, _super);
    function list(props, config) {
        var _this = _super.call(this, props, __assign({ type: "pageContainer" }, config), "list") || this;
        _this.onSelect = SNavigation.getParam("onSelect");
        return _this;
    }
    list.prototype.$onSelect = function (obj) {
        var _a;
        if (this.onSelect) {
            if (!this.onSelect(obj)) {
                SNavigation.goBack();
            }
            return;
        }
        SNavigation.navigate(((_a = this.Parent) === null || _a === void 0 ? void 0 : _a.path) + "/profile", { pk: obj[this.Parent.model.pk] });
    };
    list.prototype.$item = function (obj, opt) {
        var _this = this;
        var ItemNew = this.item;
        if (ItemNew) {
            return React.createElement(ItemNew, { data: obj, header: opt === null || opt === void 0 ? void 0 : opt.header, footer: opt === null || opt === void 0 ? void 0 : opt.footer, itemType: this.itemType, Parent: this.Parent, excludes: this.excludes, row: true, onPress: function () {
                    _this.$onSelect(obj);
                } });
        }
        return React.createElement(Item, { data: obj, header: opt === null || opt === void 0 ? void 0 : opt.header, footer: opt === null || opt === void 0 ? void 0 : opt.footer, itemType: this.itemType, Parent: this.Parent, excludes: this.excludes, row: true, onPress: function () {
                _this.$onSelect(obj);
            } });
    };
    list.prototype.$filter = function (data) {
        return true;
    };
    list.prototype.onTable = function (params) {
        var _a;
        SNavigation.navigate(((_a = this.Parent) === null || _a === void 0 ? void 0 : _a.path) + "/table", params);
    };
    list.prototype.onNew = function (params) {
        var _a;
        SNavigation.navigate(((_a = this.Parent) === null || _a === void 0 ? void 0 : _a.path) + "/new", params);
    };
    list.prototype.$allowNew = function () {
        return false;
    };
    list.prototype.$allowTable = function () {
        return false;
    };
    list.prototype.$menu = function () {
        var _this = this;
        var arr = [];
        if (this.$allowNew()) {
            arr.push({ label: Enviroment.buttoms["new"], onPress: function () { return _this.onNew(_this.$params); } });
        }
        if (this.$allowTable()) {
            arr.push({ label: Enviroment.buttoms.table, onPress: function () { return _this.onTable(_this.$params); } });
        }
        return arr;
    };
    list.prototype.$order = function () {
        return null;
    };
    list.prototype.$render = function () {
        var _this = this;
        var data = this.$getData();
        if (!data)
            return this.$onLoading();
        return (React.createElement(SList, { buscador: true, order: this.$order(), limit: 10, data: data !== null && data !== void 0 ? data : {}, filter: this.$filter.bind(this), render: function (obj) { return _this.$item(obj); } }));
    };
    return list;
}(PageAbstract));
export default list;
