var _this = this;
var instaceType = function (a) {
    try {
        return a.type.bind(_this, a.props).apply();
    }
    catch (e) {
        return null;
    }
};
var loadChildrens = function (a) {
    try {
        var childrens_1 = [];
        if (a.props.children) {
            if (Array.isArray(a.props.children)) {
                a.props.children.map(function (a) {
                    var sf = toJson(a);
                    if (sf) {
                        if (Array.isArray(sf)) {
                            sf.map(function (c) {
                                childrens_1.push(c);
                            });
                        }
                        else {
                            childrens_1.push(sf);
                        }
                    }
                });
            }
            else {
                var sf = toJson(a.props.children);
                if (sf) {
                    childrens_1.push(sf);
                }
            }
        }
        return childrens_1;
    }
    catch (e) {
        console.error(e);
        return null;
    }
};
var toJson = function (a) {
    if (!a)
        return null;
    var JSON_COMPONENT = null;
    if (a.type) {
        JSON_COMPONENT = instaceType(a);
        if (JSON_COMPONENT) {
            if (!JSON_COMPONENT.childrens) {
                JSON_COMPONENT.childrens = loadChildrens(a);
            }
        }
    }
    else {
        if (Array.isArray(a)) {
            JSON_COMPONENT = [];
            a.map(function (b) {
                var JSON_TEMP = instaceType(b);
                if (JSON_TEMP) {
                    if (!JSON_TEMP.childrens) {
                        JSON_TEMP.childrens = loadChildrens(b);
                    }
                }
                JSON_COMPONENT.push(JSON_TEMP);
            });
        }
    }
    return JSON_COMPONENT;
};
export default toJson;
