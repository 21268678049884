var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from "react";
import { SExcel, SText, SView } from "servisofts-component";
export default (function (_a) {
    var data = _a.data, renderTotal = _a.renderTotal, excelName = _a.excelName, header = _a.header;
    var headers = [];
    headers.push({ key: "codigo", label: "codigo", type: "s", style: { width: 100 } });
    headers.push({ key: "descripcion", label: "descripcion", type: "s", style: {} });
    if (header) {
        headers = __spreadArray(__spreadArray([], headers, true), header, true);
    }
    if (renderTotal) {
        headers.push({ key: "total", label: "saldo", type: "n", z: "#,##0.00", style: { width: 100 } });
        data.map(function (o) { return o.total = renderTotal(o); });
    }
    return React.createElement(SExcel, { name: excelName !== null && excelName !== void 0 ? excelName : "Servisofts-exported", data: data, styleHeader: { width: 400 }, header: __spreadArray([], headers, true) },
        React.createElement(SView, { padding: 8, card: true },
            React.createElement(SText, null, "Exportar")));
});
