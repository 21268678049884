var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from 'react';
import { SView, STheme, SColorPicker } from '../../../../index';
import SPage from '../../../SPage';
var SIColorAlert = /** @class */ (function (_super) {
    __extends(SIColorAlert, _super);
    function SIColorAlert(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {};
        return _this;
    }
    SIColorAlert.prototype.componentWillUnmount = function () {
        if (this.props.onClose)
            this.props.onClose();
    };
    SIColorAlert.prototype.render = function () {
        return React.createElement(SView, { col: "xs-11 sm-9 md-7 lg-5 xl-4", center: true, withoutFeedback: true, style: {
                borderRadius: 8,
                backgroundColor: STheme.color.background,
                overflow: "hidden"
            } },
            SPage.backgroundComponent,
            React.createElement(SView, { col: "xs-12", colSquare: true, padding: 8, center: true },
                React.createElement(SColorPicker, __assign({}, this.props))));
    };
    SIColorAlert.defaultProps = {
        props: {},
        style: {}
    };
    return SIColorAlert;
}(Component));
export default SIColorAlert;
