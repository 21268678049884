import SSocket from "./SSocket";
var DefautlConfig = null;
export function getDefaultConfig() {
    return DefautlConfig;
}
export var setProps = function (props) {
    DefautlConfig = props;
    SSocket.initApi();
};
export default SSocket;
