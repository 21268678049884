var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var PARAMS = {
    separador: "-"
};
export var init = function (props) {
    // if (!Model.usuario) {
    //     console.error("Contabilidad required Model.usuario;")
    //     return;
    // }
    // if (!Model.roles_permisos) {
    //     console.error("Contabilidad  required Model.roles_permisos;")
    //     return;
    // }
    // if (!Model.empresa) {
    //     console.error("Contabilidad  required Model.roles_permisos;")
    //     return;
    // }
    PARAMS = __assign(__assign({}, PARAMS), props);
    console.log(PARAMS);
};
