import { SModel } from "servisofts-model";
import Action from "./Action";
import Reducer from "./Reducer";
export default new SModel({
    info: {
        service: "usuario",
        component: "usuario",
        version: "2.0"
    },
    image: {
        name: "usuario",
        api: "root"
    },
    Columns: {},
    Action: Action,
    Reducer: Reducer
});
