var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from 'react';
import { SDate, SHr, SLoad, SPopup, SScrollView2, SText, STheme, SUuid, SView } from 'servisofts-component';
import Header from './header';
import Body from './body';
import Footer from './footer';
import { Model } from 'servisofts-model';
var AsientoContable = /** @class */ (function (_super) {
    __extends(AsientoContable, _super);
    function AsientoContable(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            asiento_contable_detalle: {}
        };
        _this.addAsiento();
        return _this;
    }
    AsientoContable.prototype.loadData = function () {
        if (!this.props.key_gestion) {
            this.gestion = Model.gestion.Action.getSelect();
        }
        else {
            this.gestion = Model.gestion.Action.getByKey(this.props.key_gestion);
        }
        if (!this.gestion)
            return false;
        this.total = this.getTotal();
        this.cuentas_contables = Model.cuenta_contable.Action.getAll();
        if (!this.cuentas_contables)
            return false;
        if (this.props.key_asiento_contable) {
            this.asiento_contable = Model.asiento_contable.Action.getByKey(this.props.key_asiento_contable, { key_gestion: this.gestion.key });
            if (!this.asiento_contable)
                return false;
            this.asiento_contable_detalle = Model.asiento_contable_detalle.Action.getAll({ key_asiento_contable: this.props.key_asiento_contable });
            if (!this.asiento_contable_detalle)
                return false;
            if (!this.state.cargados) {
                this.state.asiento_contable_detalle = this.asiento_contable_detalle;
                this.state.cargados = true;
                this.setState(__assign({}, this.state));
            }
        }
        return true;
    };
    AsientoContable.prototype.addAsiento = function () {
        var key = SUuid();
        this.state.asiento_contable_detalle[key] = { key: key, estado: 1 };
        this.setState(__assign({}, this.state));
    };
    AsientoContable.prototype.getTotal = function () {
        var total_debe = 0;
        var total_haber = 0;
        Object.values(this.state.asiento_contable_detalle).map(function (obj) {
            var _a, _b, _c, _d;
            if (obj.debe)
                total_debe += (_b = parseFloat((_a = obj.debe) !== null && _a !== void 0 ? _a : 0)) !== null && _b !== void 0 ? _b : 0;
            if (obj.haber)
                total_haber += (_d = parseFloat((_c = obj.haber) !== null && _c !== void 0 ? _c : 0)) !== null && _d !== void 0 ? _d : 0;
        });
        return { debe: total_debe, haber: total_haber };
    };
    AsientoContable.prototype.render = function () {
        var _this = this;
        if (!this.loadData())
            return React.createElement(SLoad, null);
        return (React.createElement(SView, { col: "xs-12", center: true, height: true },
            React.createElement(SText, null,
                "Gesti\u00F3n ",
                new SDate(this.gestion.fecha).toString("yyyy-MM")),
            React.createElement(SView, { col: "xs-12", center: true, flex: true },
                React.createElement(Header, __assign({ asiento_contable: this.asiento_contable, ref: function (ref) { return _this.hr = ref; } }, this.props, { onAdd: function () {
                        _this.addAsiento();
                    } })),
                React.createElement(SHr, { h: 4 }),
                React.createElement(SHr, { h: 1, color: STheme.color.card }),
                React.createElement(SHr, { h: 4 }),
                React.createElement(SView, { col: "xs-12", flex: true },
                    React.createElement(SScrollView2, { disableHorizontal: true },
                        React.createElement(SHr, null),
                        React.createElement(SView, { col: "xs-12", height: 300 },
                            React.createElement(Body, __assign({}, this.props, { asiento_contable_detalle: this.state.asiento_contable_detalle, onChange: function (data) {
                                    if (!data.estado) {
                                        delete _this.state.asiento_contable_detalle[data.key];
                                    }
                                    else {
                                        _this.state.asiento_contable_detalle[data.key] = data;
                                    }
                                    _this.setState(__assign({}, _this.state));
                                } }))),
                        React.createElement(SHr, null))),
                React.createElement(SHr, { h: 4 }),
                React.createElement(SHr, { h: 1, color: STheme.color.card }),
                React.createElement(SHr, { h: 4 }),
                React.createElement(Footer, __assign({}, this.props, { asiento_contable: this.asiento_contable, total: this.total, onAdd: function (data) {
                        if (!data.exito) {
                            SPopup.alert("Error en el footer");
                            return;
                        }
                        var header = _this.hr.getValue();
                        if (!header.exito) {
                            SPopup.alert("Error en el header");
                            return;
                        }
                        var partida_doble = (parseFloat(_this.total.debe).toFixed(2) == parseFloat(_this.total.haber).toFixed(2)) && _this.total.debe > 0 && _this.total.haber > 0;
                        if (!partida_doble) {
                            SPopup.alert("Error de partida doble");
                            return;
                        }
                        var exito = true;
                        Object.values(_this.state.asiento_contable_detalle).map(function (acd) {
                            var _a;
                            if (acd.cuenta_contable) {
                                acd.key_cuenta_contable = (_a = acd.cuenta_contable) === null || _a === void 0 ? void 0 : _a.key;
                            }
                            if (!acd.key_cuenta_contable) {
                                exito = false;
                            }
                            if (!acd.debe && !acd.haber) {
                                exito = false;
                            }
                            if (!acd.descripcion) {
                                exito = false;
                            }
                        });
                        if (!exito) {
                            SPopup.alert("Error al verificar los movimientos.");
                            return;
                        }
                        if (!_this.asiento_contable || _this.props.clone) {
                            var empresa = Model.empresa.Action.getSelect();
                            Model.asiento_contable.Action.registro({
                                data: {
                                    descripcion: data.descripcion,
                                    observacion: header.observacion,
                                    key_gestion: _this.gestion.key,
                                    tipo: header.tipo,
                                    fecha: header.fecha,
                                    detalle: _this.state.asiento_contable_detalle
                                },
                                key_empresa: empresa.key,
                                key_usuario: Model.usuario.Action.getKey()
                            }).then(function (resp) {
                                SPopup.alert("EXITO");
                                console.log(resp);
                            })["catch"](function (e) {
                                SPopup.alert(e.error);
                                console.error(e);
                            });
                        }
                        else {
                            Model.asiento_contable.Action.editar({
                                data: __assign(__assign({}, _this.asiento_contable), { descripcion: data.descripcion, observacion: header.observacion, key_gestion: _this.gestion.key, tipo: header.tipo, fecha: header.fecha, detalle: _this.state.asiento_contable_detalle }),
                                key_usuario: Model.usuario.Action.getKey()
                            }).then(function (resp) {
                                SPopup.alert("EXITO");
                                console.log(resp);
                            })["catch"](function (e) {
                                SPopup.alert(e.error);
                                console.error(e);
                            });
                        }
                        // return;
                    } })))));
    };
    return AsientoContable;
}(Component));
export default AsientoContable;
