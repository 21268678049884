var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { SNavigation, SLoad, SPopup, SPage, SView, SHr, SText } from 'servisofts-component';
import DPAContainer from './components/DPAContainer';
import DPAMenu from './components/DPAMenu';
import Enviroment from './Enviroment';
var PageAbstract = /** @class */ (function (_super) {
    __extends(PageAbstract, _super);
    function PageAbstract(props, config, pageType) {
        var _this = this;
        var _a, _b, _c;
        _this = _super.call(this, props) || this;
        _this.state = {};
        _this.config = config;
        var title = config.title, Parent = config.Parent, excludes = config.excludes, item = config.item, params = config.params, defaultParams = config.defaultParams, type = config.type, itemType = config.itemType, limit = config.limit;
        _this.type = type !== null && type !== void 0 ? type : "component";
        _this.title = title;
        if (!_this.title) {
            if (Parent === null || Parent === void 0 ? void 0 : Parent.name) {
                _this.title = "".concat((_a = Enviroment.pages[pageType + ""]) !== null && _a !== void 0 ? _a : pageType, " ").concat(Parent === null || Parent === void 0 ? void 0 : Parent.name);
            }
        }
        _this.itemType = itemType;
        _this.limit = limit;
        _this.Parent = Parent !== null && Parent !== void 0 ? Parent : props.Parent;
        _this.excludes = (_b = excludes !== null && excludes !== void 0 ? excludes : props.excludes) !== null && _b !== void 0 ? _b : [];
        _this.item = item !== null && item !== void 0 ? item : props.item;
        _this.params = (_c = params !== null && params !== void 0 ? params : props.params) !== null && _c !== void 0 ? _c : [];
        _this.defaultParams = defaultParams !== null && defaultParams !== void 0 ? defaultParams : {};
        _this.$params = {};
        _this._validateParams();
        return _this;
    }
    PageAbstract.prototype._validateParams = function () {
        var _this = this;
        var parameters = {};
        var valid = true;
        this.params.map(function (key) {
            var _a;
            var last = key.charAt(key.length - 1);
            var required = true;
            if (last == "?") {
                key = key.slice(0, -1);
                required = false;
            }
            var param = (_a = SNavigation.getParam(key)) !== null && _a !== void 0 ? _a : _this.props[key];
            if (!param) {
                param = _this.defaultParams[key];
            }
            if (!param && required) {
                valid = false;
            }
            else {
                parameters[key] = param;
            }
        });
        if (!valid) {
            SNavigation.goBack();
        }
        this.$params = parameters;
        return valid;
    };
    PageAbstract.prototype.$header = function () {
        return null;
    };
    PageAbstract.prototype.$menu = function () {
        var arr = [];
        return arr;
    };
    PageAbstract.prototype.$footer = function () {
        return null;
    };
    PageAbstract.prototype.$allowAccess = function () {
        return "exito";
    };
    PageAbstract.prototype.$onLoading = function () {
        return React.createElement(SLoad, null);
    };
    PageAbstract.prototype.$getData = function () {
        return null;
    };
    PageAbstract.prototype.render = function () {
        if (this.$allowAccess() == "cargando") {
            return this.$onLoading();
        }
        if (!this.$allowAccess()) {
            SPopup.alert("Access denied to page " + this.Parent.name + " " + this.title);
            SNavigation.goBack();
            return null;
        }
        switch (this.type) {
            case "page":
                return React.createElement(SPage, { title: this.title, disableScroll: true, onRefresh: this.config.onRefresh, header: React.createElement(React.Fragment, null,
                        this.$header(),
                        React.createElement(DPAMenu, { data: this.$menu(), type: this.config.menuType })) },
                    this.$render(),
                    this.$footer());
            case "pageContainer":
                return React.createElement(SPage, { title: this.title, onRefresh: this.config.onRefresh, header: React.createElement(DPAContainer, null,
                        this.$header(),
                        React.createElement(DPAMenu, { data: this.$menu(), type: this.config.menuType })) },
                    React.createElement(DPAContainer, null,
                        this.$render(),
                        this.$footer()),
                    React.createElement(SHr, { height: 100 }));
            case "container":
                return React.createElement(DPAContainer, null,
                    this.$header(),
                    React.createElement(DPAMenu, { data: this.$menu(), type: this.config.menuType }),
                    this.$render(),
                    this.$footer(),
                    React.createElement(SHr, { height: 100 }));
            case "component":
                return React.createElement(SView, { col: "xs-12" },
                    this.$header(),
                    React.createElement(DPAMenu, { data: this.$menu(), type: this.config.menuType }),
                    this.$render(),
                    this.$footer());
            case "componentTitle":
                return React.createElement(SView, { col: "xs-12" },
                    React.createElement(SHr, null),
                    React.createElement(SText, { bold: true, fontSize: 16 }, this.title),
                    React.createElement(SHr, null),
                    this.$header(),
                    React.createElement(DPAMenu, { data: this.$menu(), type: this.config.menuType }),
                    this.$render(),
                    this.$footer());
        }
        return this.$render();
    };
    return PageAbstract;
}(React.Component));
export default PageAbstract;
