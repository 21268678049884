import gestion from "./gestion";
import cuenta_contable from "./cuenta_contable";
import centro_costo from "./centro_costo";
import centro_costo_detalle from "./centro_costo_detalle";
import asiento_contable from "./asiento_contable";
import cuenta_centro_costo from "./cuenta_centro_costo";
import asiento_contable_detalle from "./asiento_contable_detalle";
import asiento_contable_tipo_cambio from "./asiento_contable_tipo_cambio";
export default {
    gestion: gestion,
    cuenta_contable: cuenta_contable,
    centro_costo: centro_costo,
    centro_costo_detalle: centro_costo_detalle,
    asiento_contable: asiento_contable,
    asiento_contable_detalle: asiento_contable_detalle,
    asiento_contable_tipo_cambio: asiento_contable_tipo_cambio,
    cuenta_centro_costo: cuenta_centro_costo
};
