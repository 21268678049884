var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import { SHr, SList, SLoad, SText, SView } from 'servisofts-component';
import { Model } from 'servisofts-model';
var PlanDeCuentas2 = /** @class */ (function (_super) {
    __extends(PlanDeCuentas2, _super);
    function PlanDeCuentas2(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {};
        return _this;
    }
    PlanDeCuentas2.prototype.getLista = function () {
        var _this = this;
        var arr = Object.values(this.data);
        arr.sort(function (a, b) {
            var arra = b.codigo.split(_this.props.separator);
            var arrb = a.codigo.split(_this.props.separator);
            for (var i = 0; i < arra.length; i++) {
                if (arrb.length <= i) {
                    return -1;
                }
                if (parseInt(arra[i]) > parseInt(arrb[i])) {
                    return -1;
                }
                if (parseInt(arra[i]) < parseInt(arrb[i])) {
                    return 1;
                }
            }
            return 0;
        });
        return React.createElement(SList, { data: arr, buscador: true, filter: function (obj) { return obj.codigo.startsWith(_this.props.initialCode); }, space: 0, render: function (obj) {
                var lvl = obj.codigo.split(_this.props.separator).length;
                var style = {
                    fontSize: 16,
                    fontWeight: ""
                };
                switch (lvl) {
                    case 1:
                        style.fontWeight = "bold";
                        style.fontSize = 18;
                        break;
                    case 2:
                        style.fontWeight = "bold";
                        break;
                }
                var childs = Model.cuenta_contable.Action.getChildrens(obj.key);
                var onPress;
                if (!childs.length) {
                    //  style.textDecoration = "underline"
                    onPress = function () {
                        if (_this.props.onSelect) {
                            _this.props.onSelect(obj);
                            return;
                        }
                    };
                }
                return React.createElement(SView, { col: "xs-12", onPress: onPress },
                    React.createElement(SHr, { height: _this.props.space / 2 }),
                    React.createElement(SView, { col: "xs-12", row: true, card: !onPress ? false : true, style: {
                            padding: 4
                        } },
                        React.createElement(SText, { style: style, width: _this.props.codeWidth }, obj.codigo),
                        React.createElement(SView, { width: _this.props.space }),
                        React.createElement(SText, { style: style, flex: true }, obj.descripcion)),
                    React.createElement(SHr, { height: _this.props.space / 2 }));
            } });
    };
    PlanDeCuentas2.prototype.render = function () {
        this.data = Model.cuenta_contable.Action.getAll();
        if (!this.data)
            return React.createElement(SLoad, null);
        return (React.createElement(SView, { col: "xs-12", center: true }, this.getLista()));
    };
    PlanDeCuentas2.defaultProps = {
        space: 6,
        codeWidth: 100,
        separator: "-",
        initialCode: ""
    };
    return PlanDeCuentas2;
}(Component));
export default PlanDeCuentas2;
