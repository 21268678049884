import rol from "./rol";
import page from "./page";
import usuarioPage from "./usuarioPage";
import usuarioRol from "./usuarioRol";
import rolPermiso from "./rolPermiso";
import permiso from "./permiso";
export default {
    rol: rol,
    usuarioPage: usuarioPage,
    page: page,
    permiso: permiso,
    rolPermiso: rolPermiso,
    usuarioRol: usuarioRol
};
