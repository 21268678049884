var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import { SForm, SHr, SPopup, SText, STheme, SView } from 'servisofts-component';
import Model from '../../Model';
var POPUP_KEY = "popup_add_cuenta";
var PopupAddCuenta = /** @class */ (function (_super) {
    __extends(PopupAddCuenta, _super);
    function PopupAddCuenta(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {};
        return _this;
    }
    PopupAddCuenta.open = function (data) {
        // if (data?.codigo) {
        // if (Model.cuenta_contable.Action.getLvl(data.codigo) >= Model.cuenta_contable.Action.props.maxLvl) {
        //     SPopup.alert("No se pueden crear cuentas mayores al nivel " + Model.cuenta_contable.Action.props.maxLvl)
        //     return null;
        // }
        // }
        SPopup.open({
            key: POPUP_KEY,
            content: React.createElement(PopupAddCuenta, { data: data })
        });
    };
    PopupAddCuenta.close = function () {
        SPopup.close(POPUP_KEY);
    };
    PopupAddCuenta.prototype.formatCode = function (n) {
        return n < 10 ? "0" + n : n;
    };
    PopupAddCuenta.prototype.getForm = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f;
        // (!itmNewParent.codigo ? itmNewParent.codigo : (itmNewParent.codigo + SEPARATOR)) + this.formatCode((hijos.length + 1))
        var codigo = (_c = (_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.codigo) !== null && _c !== void 0 ? _c : "";
        // var hio
        // if (this.props?.data?.codigo) {
        var hijos = Model.cuenta_contable.Action.getFirstChildrens((_f = (_e = (_d = this.props) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e.codigo) !== null && _f !== void 0 ? _f : "");
        console.log(hijos);
        if (codigo.length <= 0) {
            codigo = codigo + Model.cuenta_contable.Action.getSeparador() + (hijos.length + 1);
        }
        else {
            codigo = codigo + Model.cuenta_contable.Action.getSeparador() + this.formatCode((hijos.length + 1));
        }
        // }
        return React.createElement(SForm, { inputs: {
                codigo: { label: "Codigo", defaultValue: codigo, required: true },
                descripcion: { label: "Nombre", autoFocus: true, required: true }
            }, onSubmitName: "GUARDAR", onSubmit: function (data) {
                _this.setState({ loading: true, error: "cargando" });
                Model.cuenta_contable.Action.registro(data).then(function (resp) {
                    _this.setState({ loading: false, error: "" });
                    PopupAddCuenta.close();
                })["catch"](function (e) {
                    _this.setState({ loading: false, error: "Error" });
                    console.log(e);
                });
            } });
    };
    PopupAddCuenta.prototype.render = function () {
        var _a, _b, _c, _d, _e;
        return React.createElement(SView, { col: "xs-12 sm-10 md-8", height: 350, withoutFeedback: true, style: {
                maxHeight: "100%"
            }, center: true },
            React.createElement(SView, { col: "xs-12", height: true, backgroundColor: STheme.color.background + "ee", style: {
                    borderRadius: 16,
                    padding: 8
                }, center: true },
                React.createElement(SView, { col: "xs-12 sm-10 md-8", center: true, flex: true },
                    React.createElement(SHr, null),
                    React.createElement(SView, { col: "xs-12", center: true },
                        React.createElement(SText, null, "Agregar nueva cuenta para "),
                        React.createElement(SHr, null),
                        React.createElement(SText, { fontSize: 16, bold: true }, (_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 :
                            _b.codigo,
                            " - ", (_d = (_c = this.props) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 :
                            _d.descripcion)),
                    React.createElement(SView, { col: "xs-12", flex: true },
                        this.getForm(),
                        React.createElement(SText, { color: STheme.color.danger }, (_e = this.state) === null || _e === void 0 ? void 0 : _e.error)))));
    };
    return PopupAddCuenta;
}(Component));
export default (PopupAddCuenta);
