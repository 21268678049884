import React from "react";
import { SDate, SExcel, SIcon, SText, SView } from "servisofts-component";
export default (function (_a) {
    var data = _a.data, asiento = _a.asiento, cuentas = _a.cuentas, excelName = _a.excelName;
    return React.createElement(SExcel, { name: excelName !== null && excelName !== void 0 ? excelName : "Servisofts-exported", data: data, styleHeader: { width: 400, height: 80 }, renderData: function (obj) {
            var _a, _b;
            obj.cuenta_c = cuentas[obj.key_cuenta_contable].codigo;
            obj.cuenta_d = cuentas[obj.key_cuenta_contable].descripcion;
            obj.sucursal = "TODO";
            obj.n_asiento = asiento.codigo;
            obj.debe = (_a = obj.debe) !== null && _a !== void 0 ? _a : 0;
            obj.haber = (_b = obj.haber) !== null && _b !== void 0 ? _b : 0;
            obj.moneda = "Bolivianos";
            obj.fecha = asiento.fecha;
            obj.fecha_on = new SDate(obj.fecha_on).toString("yyyy-MM-dd hh:mm");
            obj.tipo = (asiento.tipo + "").toUpperCase();
            obj.glosa = obj.descripcion;
            return obj;
        }, header: [
            { key: "cuenta_c", label: "Numero de cuenta contable", type: "s", style: { width: 100 } },
            { key: "cuenta_d", label: "Descripcion de la cuenta", type: "s", style: { width: 200 } },
            { key: "sucursal", label: "Sucursal asiento", type: "s", style: { width: 100 } },
            { key: "n_asiento", label: "Numero de asiento", type: "s", style: { width: 100 } },
            { key: "debe", label: "Monto debe", type: "n", z: "#,##0.00", style: { width: 100 } },
            { key: "haber", label: "Monto haber", type: "n", z: "#,##0.00", style: { width: 100 } },
            { key: "moneda", label: "Moneda", type: "s", style: { width: 100 } },
            { key: "glosa", label: "Glosa", type: "s", style: { width: 200 } },
            { key: "fecha_on", label: "Fecha de registro", type: "s", style: { width: 100 } },
            { key: "fecha", label: "Fecha contable", type: "s", style: { width: 100 } },
            { key: "tipo", label: "Tipo de comprobante", type: "s", style: { width: 100 } },
        ] },
        React.createElement(SView, { padding: 8, card: true, width: 100, center: true, row: true },
            React.createElement(SIcon, { name: "Excel", width: 20 }),
            React.createElement(SView, { width: 8 }),
            React.createElement(SText, null, "Exportar")));
});
