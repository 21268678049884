import { SAction } from "servisofts-model";
import Model from "../..";
export default class Action extends SAction {
    
    // getAllByKeyModelo(key) {
    //     var reducer = this._getReducer();
    //     if (reducer.key_modelo != key) {
    //         reducer.data = "";
    //         reducer.key_modelo = key;
    //     }
    //     return super.getAll({
    //         key_modelo: key
    //     })
    // }
}   