var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { SHr, SList2, SScrollView3, SView } from 'servisofts-component';
import MenuItem from './MenuItem';
var DPAMenu = /** @class */ (function (_super) {
    __extends(DPAMenu, _super);
    function DPAMenu(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {};
        return _this;
    }
    DPAMenu.prototype.render = function () {
        var _this = this;
        if (!this.props.data)
            return null;
        if (this.props.data.length <= 0)
            return null;
        if (this.props.type == "flex") {
            return (React.createElement(SView, { col: "xs-12" },
                React.createElement(SHr, null),
                React.createElement(SList2, { data: this.props.data, horizontal: true, space: 4, render: function (obj) {
                        return React.createElement(MenuItem, __assign({}, obj, { type: _this.props.type }), obj.children);
                    } }),
                React.createElement(SHr, null)));
        }
        // if (this.props.type == "scroll") {
        return (React.createElement(SView, { col: "xs-12" },
            React.createElement(SScrollView3, { horizontal: true, scroll: false, style: {} },
                React.createElement(SHr, null),
                React.createElement(SList2, { data: this.props.data, horizontal: true, render: function (obj) {
                        return React.createElement(MenuItem, __assign({ type: "scroll" }, obj), obj.children);
                    } })),
            React.createElement(SHr, null)));
        // }
    };
    return DPAMenu;
}(React.Component));
export default DPAMenu;
