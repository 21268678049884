var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import PageAbstract from './PageAbstract';
import { SDate, SImage, STable2, SView } from 'servisofts-component';
import SSocket from 'servisofts-socket';
var table = /** @class */ (function (_super) {
    __extends(table, _super);
    function table(props, config) {
        return _super.call(this, props, __assign({ type: "page" }, config), "table") || this;
    }
    table.prototype.$headers = function () {
        var _this = this;
        var _a;
        var excludes = (_a = this.excludes) !== null && _a !== void 0 ? _a : [];
        var headers = {};
        headers["index"] = { label: "#", width: 50 };
        if (this.Parent.model.image) {
            headers["key-image"] = {
                label: "Img",
                width: 50,
                center: true,
                render: function (key_r) {
                    return _this.Parent.model._get_image_download_path(SSocket.api, key_r);
                },
                component: function (src) {
                    return React.createElement(SView, { col: "xs-11", height: true, center: true, card: true },
                        React.createElement(SImage, { src: src, enablePreview: true }));
                }
            };
        }
        Object.keys(this.Parent.model.Columns).map(function (key) {
            var _a;
            var col = _this.Parent.model.Columns[key];
            if (excludes.indexOf(key) > -1) {
                return;
            }
            var render = null;
            if (col.type == "timestamp") {
                render = function (obj) { return new SDate(obj).toString(); };
            }
            if (col.type == "date") {
                render = function (obj) { return new SDate(obj).toString("dd/MM/yyyy"); };
            }
            if (col.type == "time") {
                render = function (obj) { return new SDate(obj).toString("hh:mm:ss"); };
            }
            var label = key;
            if (col) {
                label = (_a = col.label) !== null && _a !== void 0 ? _a : key;
            }
            headers[key] = ({
                key: key,
                label: label,
                width: 100,
                render: render
            });
        });
        return headers;
    };
    table.prototype._formatHeaders = function () {
        var heads = this.$headers();
        var arr = [];
        Object.keys(heads).map(function (key, i) {
            arr.push(__assign({ key: key, index: i + 1 }, heads[key]));
        });
        arr = arr.sort(function (a, b) { return a.index - b.index; });
        return arr;
    };
    table.prototype.$filter = function (data) {
        return true;
    };
    // $menu() {
    //     var arr = [];
    //     arr.push({ icon: "Arrow", label: "Back", onPress: () => { } });
    //     return arr;
    // }
    table.prototype.$render = function () {
        var data = this.$getData();
        if (!data)
            return this.$onLoading();
        return React.createElement(SView, { col: "xs-12", flex: true },
            React.createElement(STable2, { data: data, header: this._formatHeaders(), filter: this.$filter.bind(this) }));
    };
    return table;
}(PageAbstract));
export default table;
