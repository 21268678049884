var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import { connect } from 'servisofts-page';
import { SHr, SInput, SView } from 'servisofts-component';
import { Model } from 'servisofts-model';
import Item from './item';
var PlanDeCuentas = /** @class */ (function (_super) {
    __extends(PlanDeCuentas, _super);
    function PlanDeCuentas(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            "new": false,
            edit: false
        };
        return _this;
    }
    PlanDeCuentas.prototype.getBuscador = function () {
        if (!this.props.buscador)
            return null;
        return React.createElement(SView, { col: "xs-12" },
            React.createElement(SHr, null),
            React.createElement(SInput, { placeholder: "Buscar..." }),
            React.createElement(SHr, null));
    };
    PlanDeCuentas.prototype.render = function () {
        // var defaultKey = this.props.defaultKey;
        var defaultCode = this.props.defaultCode;
        // if (this.props.defaultCode) {
        // var codes = Model.cuenta_contable.Action.getFormat();
        // if (!codes) return null;
        // defaultKey = codes[this.props.defaultCode]?.key;
        // }
        var data = Model.cuenta_contable.Action.getFirstChildrens(defaultCode);
        if (!data)
            return null;
        return (React.createElement(SView, { col: "xs-12", center: true },
            this.getBuscador(),
            React.createElement(Item, { obj: { codigo: defaultCode }, disabled: this.props.disabled, open: this.props.open, parent: this, onSelect: this.props.onSelect })));
    };
    return PlanDeCuentas;
}(Component));
export default connect(PlanDeCuentas);
