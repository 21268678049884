var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from 'react';
import { STheme } from 'servisofts-component';
import { Svg, Text, Rect } from 'react-native-svg';
import * as Func from '../Functions';
var index = /** @class */ (function (_super) {
    __extends(index, _super);
    function index(props) {
        return _super.call(this, props) || this;
    }
    index.prototype.render = function () {
        var _this = this;
        var width = 250;
        var strokeWidth = width / 2;
        var scale = width - strokeWidth;
        var render_gr = function () {
            var _a;
            var conf = __assign({ separacion: 10, grosor: 30, endSpace: 45, textColor: STheme.color.text, fontSize: 12 }, (_a = _this.props.config) !== null && _a !== void 0 ? _a : {});
            if (!_this.props.data)
                return null;
            var max = Func.calcular_maximo(_this.props.data);
            var arr = _this.props.data.sort(function (a, b) { return b.val - a.val; });
            return arr.map(function (obj, i) {
                var _a, _b, _c;
                var color = (_a = obj.color) !== null && _a !== void 0 ? _a : Func.color_random();
                var porcent = Func.calcular_procentaje({ val: obj.val, max: max });
                var y = ((i * (conf.separacion + conf.grosor)) + conf.separacion);
                return React.createElement(React.Fragment, null,
                    React.createElement(Rect, __assign({ x: -conf.endSpace, y: y, width: Func.number_to_porcent_string(100 * porcent), height: conf.grosor, fill: color, ry: 4 }, Func.create_onPress(function () { return _this.props.onSelect ? _this.props.onSelect(obj) : null; }))),
                    React.createElement(Text, { fill: conf.textColor, fontSize: conf.fontSize, fontWeight: "bold", "font-family": "Arial", y: y + (conf.grosor / 2) + (conf.fontSize / 3), x: 2, textAnchor: 'start' }, (_b = obj.label) !== null && _b !== void 0 ? _b : obj.val),
                    React.createElement(Text, { fill: conf.textColor, fontSize: conf.fontSize, fontWeight: "bold", "font-family": "Arial", textAnchor: 'start', y: y + (conf.grosor / 2) + (conf.fontSize / 3), x: Func.number_to_porcent_string(100 * porcent), translateX: (-conf.endSpace + 2) }, (_c = obj.info) !== null && _c !== void 0 ? _c : Func.number_to_porcent_string(100 * porcent)));
            });
        };
        return (React.createElement(Svg, { height: "100%", width: "100%" }, render_gr()));
    };
    index.defaultProps = {
        data: null,
        strokeWidth: 1
    };
    return index;
}(Component));
export default index;
