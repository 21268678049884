var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import { SView } from 'servisofts-component';
import Item from './Item';
var index = /** @class */ (function (_super) {
    __extends(index, _super);
    function index(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {};
        _this.ref_detalles = [];
        return _this;
    }
    index.prototype.render_lista = function () {
        var _this = this;
        return Object.values(this.props.asiento_contable_detalle).sort(function (a, b) { var _a, _b; return ((_a = a === null || a === void 0 ? void 0 : a.index) !== null && _a !== void 0 ? _a : 0) - ((_b = b === null || b === void 0 ? void 0 : b.index) !== null && _b !== void 0 ? _b : 0); }).map(function (obj) {
            return React.createElement(Item, { key: obj.key, data: obj, onChange: function (data) {
                    if (_this.props.onChange) {
                        _this.props.onChange(data);
                    }
                    // this.state[key] = data;
                    // this.setState({ ...this.state })
                } });
        });
    };
    index.prototype.render = function () {
        return React.createElement(SView, { col: "xs-12", center: true }, this.render_lista());
    };
    return index;
}(Component));
export default index;
