var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SStorage } from "servisofts-component";
import { SReducer } from "servisofts-model";
import { PARAMS } from "../../PARAMS";
var Reducer = /** @class */ (function (_super) {
    __extends(Reducer, _super);
    function Reducer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Reducer.prototype.initialState = function () {
        var state = _super.prototype.initialState.call(this);
        SStorage.getItem(PARAMS.storage_name, function (resp) {
            if (!resp)
                return;
            state.usuarioLog = JSON.parse(resp);
        });
        return state;
    };
    Reducer.prototype.login = function (state, action) {
        if (action.estado != "exito")
            return;
        state.usuarioLog = action.data;
        SStorage.setItem(PARAMS.storage_name, JSON.stringify(action.data));
    };
    return Reducer;
}(SReducer));
export default Reducer;
