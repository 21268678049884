import { Model } from "servisofts-model";
export var PARAMS = {
    cabecera: "",
    storage_name: "usr_log",
    Columns: {}
};
export var init = function (_a) {
    var cabecera = _a.cabecera, _b = _a.storage_name, storage_name = _b === void 0 ? "usr_log" : _b, Columns = _a.Columns;
    Model.usuario.Columns = Columns;
    PARAMS.cabecera = cabecera;
    PARAMS.storage_name = storage_name !== null && storage_name !== void 0 ? storage_name : PARAMS.storage_name;
};
