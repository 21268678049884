var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import Icon from "./icon.svg";
var Native = Icon;
var Web = function (props) { return (React.createElement("svg", __assign({}, props, { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 113.39 113.39" }),
    React.createElement("path", { d: "M56.69,28.29A28.41,28.41,0,1,0,85.09,56.7,28.44,28.44,0,0,0,56.69,28.29Z" }),
    React.createElement("path", { d: "M56.69,20a5.55,5.55,0,0,1-5.54-5.54V5.54a5.54,5.54,0,0,1,11.08,0v8.87A5.55,5.55,0,0,1,56.69,20Z" }),
    React.createElement("path", { d: "M56.69,93.44A5.54,5.54,0,0,0,51.15,99v8.86a5.54,5.54,0,1,0,11.08,0V99A5.54,5.54,0,0,0,56.69,93.44Z" }),
    React.createElement("path", { d: "M82.67,30.71a5.55,5.55,0,0,1,0-7.84l6.27-6.27a5.54,5.54,0,0,1,7.84,7.84l-6.27,6.27A5.55,5.55,0,0,1,82.67,30.71Z" }),
    React.createElement("path", { d: "M30.71,82.68a5.53,5.53,0,0,0-7.84,0L16.6,89a5.54,5.54,0,1,0,7.84,7.83l6.27-6.27A5.54,5.54,0,0,0,30.71,82.68Z" }),
    React.createElement("path", { d: "M93.44,56.69A5.54,5.54,0,0,1,99,51.15h8.86a5.54,5.54,0,1,1,0,11.08H99A5.54,5.54,0,0,1,93.44,56.69Z" }),
    React.createElement("path", { d: "M20,56.69a5.55,5.55,0,0,0-5.55-5.54H5.54a5.54,5.54,0,0,0,0,11.08h8.87A5.55,5.55,0,0,0,20,56.69Z" }),
    React.createElement("path", { d: "M82.67,82.68a5.55,5.55,0,0,1,7.84,0L96.78,89a5.54,5.54,0,1,1-7.84,7.83l-6.27-6.27A5.54,5.54,0,0,1,82.67,82.68Z" }),
    React.createElement("path", { d: "M30.71,30.71a5.55,5.55,0,0,0,0-7.84l-6.27-6.26a5.54,5.54,0,0,0-7.84,7.83l6.27,6.27A5.53,5.53,0,0,0,30.71,30.71Z" }))); };
export default { Native: Native, Web: Web };
