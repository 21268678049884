var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import PageAbstract from './PageAbstract';
import { SNavigation, SView } from 'servisofts-component';
import Item from './item';
import Enviroment from './Enviroment';
var profile = /** @class */ (function (_super) {
    __extends(profile, _super);
    function profile(props, config) {
        var _this = this;
        var _a;
        config.params = __spreadArray(["pk"], (_a = config.params) !== null && _a !== void 0 ? _a : [], true);
        _this = _super.call(this, props, __assign({ type: "pageContainer" }, config), "profile") || this;
        _this.pk = _this.$params["pk"];
        _this.onSelect = SNavigation.getParam("onSelect");
        return _this;
    }
    profile.prototype.$item = function (obj) {
        var _this = this;
        var ItemNew = this.item;
        if (ItemNew) {
            return React.createElement(ItemNew, { data: obj, Parent: this.Parent, excludes: this.excludes, row: false, onPress: function () {
                    _this.$onSelect(obj);
                } });
        }
        return React.createElement(Item, { data: obj, itemType: this.itemType, Parent: this.Parent, excludes: this.excludes, row: false });
    };
    profile.prototype.$onSelect = function (obj) {
        return true;
    };
    profile.prototype.$allowEdit = function () {
        return false;
    };
    profile.prototype.$allowDelete = function () {
        return false;
    };
    profile.prototype.$allowRestore = function () {
        return false;
    };
    profile.prototype.$allowBack = function () {
        return false;
    };
    profile.prototype.onEdit = function () {
        var _a;
        SNavigation.navigate(((_a = this.Parent) === null || _a === void 0 ? void 0 : _a.path) + "/edit", { pk: this.data[this.Parent.model.pk] });
    };
    profile.prototype.onDelete = function () {
        var _a;
        SNavigation.navigate(((_a = this.Parent) === null || _a === void 0 ? void 0 : _a.path) + "/delete", { pk: this.data[this.Parent.model.pk] });
    };
    profile.prototype.onRestore = function () {
        var _a;
        SNavigation.navigate(((_a = this.Parent) === null || _a === void 0 ? void 0 : _a.path) + "/restore", { pk: this.data[this.Parent.model.pk] });
    };
    profile.prototype.onBack = function () {
        SNavigation.goBack();
    };
    profile.prototype.$menu = function () {
        var arr = [];
        if (this.$allowBack()) {
            arr.push({ icon: "Arrow", label: "Back", onPress: this.onBack.bind(this) });
        }
        if (this.$allowEdit()) {
            arr.push({ icon: "Pencil", label: Enviroment.buttoms.edit, onPress: this.onEdit.bind(this) });
        }
        // if (this.data) {
        // if (this.data.estado != 0) {
        if (this.$allowDelete()) {
            arr.push({ icon: "Close", label: Enviroment.buttoms["delete"], onPress: this.onDelete.bind(this) });
        }
        // } else {
        if (this.$allowRestore()) {
            arr.push({ icon: "Close", label: Enviroment.buttoms.restore, onPress: this.onRestore.bind(this) });
        }
        // }
        // }
        return arr;
    };
    profile.prototype.$render = function () {
        this.data = this.$getData();
        if (!this.data)
            return this.$onLoading();
        if (!this.state.loadingSuccess) {
            this.state.loadingSuccess = true;
            this.setState(__assign({}, this.state));
        }
        return React.createElement(SView, { col: "xs-12", center: true, style: {} }, this.$item(this.data));
    };
    return profile;
}(PageAbstract));
export default profile;
