var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import { SExcelReader, SHr, SIcon, SText, SUuid, SView } from 'servisofts-component';
import Item from './Item';
var index = /** @class */ (function (_super) {
    __extends(index, _super);
    function index(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {};
        _this.ref_detalles = [];
        return _this;
    }
    index.prototype.render_lista = function () {
        var _this = this;
        var i = 0;
        return Object.values(this.props.asiento_contable_detalle).sort(function (a, b) { var _a, _b; return ((_a = a === null || a === void 0 ? void 0 : a.index) !== null && _a !== void 0 ? _a : 0) - ((_b = b === null || b === void 0 ? void 0 : b.index) !== null && _b !== void 0 ? _b : 0); }).map(function (obj) {
            i++;
            return React.createElement(Item, { key: obj.key, index: i, data: obj, onChange: function (data) {
                    if (_this.props.onChange) {
                        _this.props.onChange(data);
                    }
                    // this.state[key] = data;
                    // this.setState({ ...this.state })
                } });
        });
    };
    index.prototype.render_import_excel = function () {
        var _this = this;
        return React.createElement(SExcelReader, { onSubmit: function (data, callback) {
                // console.log(this.props.asiento_contable_detalle);
                // console.log(data);
                var jsonFinal = {};
                data.map(function (obj) {
                    var df = {
                        key: SUuid(),
                        estado: 1,
                        descripcion: obj["Glosa"],
                        debe: obj["Monto debe"],
                        haber: obj["Monto haber"],
                        cuenta_contable: {
                            codigo: obj["Numero de cuenta contable"],
                            descripcion: obj["Descripcion de la cuenta"]
                        }
                    };
                    jsonFinal[df.key] = df;
                });
                if (_this.props.onChangeAll) {
                    _this.props.onChangeAll(jsonFinal);
                }
                callback();
            } },
            React.createElement(SView, { padding: 8, card: true, row: true },
                React.createElement(SIcon, { name: 'Excel', width: 18 }),
                React.createElement(SView, { width: 8 }),
                React.createElement(SText, null, "Importar excel")));
    };
    index.prototype.render = function () {
        return React.createElement(SView, { col: "xs-12", center: true },
            this.render_lista(),
            React.createElement(SHr, { h: 50 }),
            this.render_import_excel(),
            React.createElement(SHr, { h: 50 }));
    };
    return index;
}(Component));
export default index;
