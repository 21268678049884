var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from 'react';
import { connect } from 'servisofts-page';
import { SDate, SHr, SList, SLoad, SMath, SText, SView } from 'servisofts-component';
import { Model } from 'servisofts-model';
import Body from "./body";
import ExportExcel from './exportExcel';
var AsientoContableStatic = /** @class */ (function (_super) {
    __extends(AsientoContableStatic, _super);
    function AsientoContableStatic(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {};
        return _this;
    }
    AsientoContableStatic.prototype.componentDidMount = function () {
        var _this = this;
        // Model.asiento_contable.Action.getByKeyAsync(this.props.key_asiento_contable).then(resp => {
        Model.asiento_contable.Action.getByKeyCompletoAsync(this.props.key_asiento_contable).then(function (resp) {
            console.log(resp);
            _this.setState({ data: resp.data });
        })["catch"](function (e) {
            _this.setState({ error: e.error });
        });
    };
    AsientoContableStatic.prototype.loadData = function () {
        var _this = this;
        // if (!this.props.key_gestion) return;
        if (!this.props.key_asiento_contable)
            return;
        if (!this.state.data)
            return;
        this.cuentas_contables = Model.cuenta_contable.Action.getAll();
        if (!this.cuentas_contables)
            return false;
        this.asiento_contable = this.state.data;
        if (!this.asiento_contable)
            return false;
        // this.empresa = Model.empresa.Action.getByKey(this.asiento_contable?.gestion?.key_empresa);
        // if (!this.empresa) return false;
        this.asiento_contable_detalle = Model.asiento_contable_detalle.Action.getAll({ key_asiento_contable: this.props.key_asiento_contable });
        this.asiento_contable_tipo_cambio = Model.asiento_contable_tipo_cambio.Action.getAll({ key_asiento_contable: this.props.key_asiento_contable });
        if (!this.asiento_contable_detalle || !this.asiento_contable_tipo_cambio)
            return false;
        this.asiento_contable_detalle = Object.values(this.asiento_contable_detalle).sort(function (a, b) { var _a, _b; return ((_a = a === null || a === void 0 ? void 0 : a.index) !== null && _a !== void 0 ? _a : 0) - ((_b = b === null || b === void 0 ? void 0 : b.index) !== null && _b !== void 0 ? _b : 0); });
        this.totales = {
            debe: 0,
            haber: 0
        };
        this.asiento_contable_detalle.map(function (a) {
            _this.totales.debe += a.debe;
            _this.totales.haber += a.haber;
        });
        return true;
    };
    AsientoContableStatic.prototype.render_moneda = function () {
        return React.createElement(SView, { col: "xs-12" },
            React.createElement(SList, { data: this.asiento_contable_tipo_cambio, horizontal: true, order: [{ key: "tipo_cambio", order: "asc", peso: 1 }], render: function (moneda) {
                    return React.createElement(SView, { card: true, padding: 4 },
                        React.createElement(SText, { fontSize: 12 },
                            moneda.descripcion,
                            " ",
                            moneda.tipo_cambio));
                } }));
    };
    AsientoContableStatic.prototype.render_header = function () {
        var _a, _b, _c, _d;
        var props = { col: "xs-12", card: true, padding: 4, style: { minHeight: 110 } };
        if (!this.loadData())
            return React.createElement(SLoad, __assign({ type: 'skeleton' }, props));
        return React.createElement(SView, __assign({}, props),
            React.createElement(SText, { fontSize: 14, bold: true }, (_a = this.empresa) === null || _a === void 0 ? void 0 : _a.razon_social),
            React.createElement(SText, { fontSize: 14 },
                "NIT ", (_b = this.empresa) === null || _b === void 0 ? void 0 :
                _b.nit),
            React.createElement(SHr, { h: 4 }),
            React.createElement(SText, { fontSize: 14 },
                "Gestion ",
                new SDate((_d = (_c = this.asiento_contable) === null || _c === void 0 ? void 0 : _c.gestion) === null || _d === void 0 ? void 0 : _d.fecha).toString("yyyy-MM")),
            React.createElement(SHr, { h: 4 }),
            React.createElement(SText, { fontSize: 16, bold: true, col: "xs-12", center: true }, "COMPROBANTE DE ".concat(this.asiento_contable.tipo, " N ").concat(this.asiento_contable.codigo).toUpperCase()),
            React.createElement(SText, { fontSize: 12, col: "xs-12", center: true }, "( Expresado en Bs. )"),
            React.createElement(SHr, { h: 4 }),
            React.createElement(SText, { fontSize: 12 }, "Fecha: ".concat(new SDate(this.asiento_contable.fecha, "yyyy-MM-dd").toString("dd de MONTH de yyyy"))),
            React.createElement(SHr, { h: 4 }),
            React.createElement(SText, { fontSize: 12 }, "Concepto: ".concat(this.asiento_contable.descripcion)),
            React.createElement(SHr, { h: 4 }),
            this.render_moneda(),
            React.createElement(SHr, null),
            React.createElement(SView, { col: "xs-12", center: true, padding: 4, row: true },
                React.createElement(SView, { col: "xxs-12 xs-6", row: true }),
                React.createElement(SView, { col: "xxs-12 xs-6", row: true, style: {
                        marginTop: 4
                    } },
                    React.createElement(SView, { flex: true, center: true },
                        React.createElement(SText, { fontSize: 12, bold: true }, "DEBE")),
                    React.createElement(SView, { flex: true, center: true },
                        React.createElement(SText, { fontSize: 12, bold: true }, "HABER")),
                    React.createElement(SView, { flex: true, center: true },
                        React.createElement(SText, { fontSize: 12, bold: true }, "C")))));
    };
    AsientoContableStatic.prototype.render_body = function () {
        var props = { col: "xs-12", flex: true, card: true };
        if (!this.loadData())
            return React.createElement(SLoad, __assign({ type: 'skeleton' }, props));
        return React.createElement(Body, { data: this.asiento_contable_detalle, cuentas_contables: this.cuentas_contables });
    };
    AsientoContableStatic.prototype.render_footer = function () {
        var props = { col: "xs-12", height: 40, card: true };
        if (!this.loadData())
            return React.createElement(SLoad, __assign({ type: 'skeleton' }, props));
        return React.createElement(SView, __assign({}, props),
            React.createElement(SView, { col: "xs-12", center: true, padding: 4, row: true },
                React.createElement(SView, { col: "xxs-12 xs-6", row: true },
                    React.createElement(ExportExcel, { data: this.asiento_contable_detalle, asiento: this.asiento_contable, cuentas: this.cuentas_contables, excelName: "Comprobante" })),
                React.createElement(SView, { col: "xxs-12 xs-6", row: true, style: {
                        marginTop: 4
                    } },
                    React.createElement(SView, { flex: true, center: true },
                        React.createElement(SText, { fontSize: 12, bold: true }, SMath.formatMoney(this.totales.debe, 2, "."))),
                    React.createElement(SView, { flex: true, center: true },
                        React.createElement(SText, { fontSize: 12, bold: true }, SMath.formatMoney(this.totales.haber))),
                    React.createElement(SView, { flex: true, center: true }))));
    };
    AsientoContableStatic.prototype.render_content = function () {
        return React.createElement(SView, { col: "xs-12", flex: true },
            this.render_header(),
            React.createElement(SHr, { h: 4 }),
            this.render_body(),
            React.createElement(SHr, { h: 4 }),
            this.render_footer());
    };
    AsientoContableStatic.prototype.render = function () {
        return React.createElement(SView, { col: "xs-12", center: true, flex: true }, this.render_content());
    };
    return AsientoContableStatic;
}(Component));
export default connect(AsientoContableStatic);
