var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { SNavigation, SStorage } from "servisofts-component";
import { SAction } from "servisofts-model";
import SSocket from 'servisofts-socket';
import { Model } from "servisofts-model";
import { PARAMS } from "../../PARAMS";
var Action = /** @class */ (function (_super) {
    __extends(Action, _super);
    function Action() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.recuperarPass = function (_a) {
            var correo = _a.correo;
            return SSocket.sendPromise({
                service: "usuario",
                component: "usuario",
                type: "recuperarPass",
                estado: "cargando",
                data: correo
            });
        };
        _this.verificarCodigoPass = function (_a) {
            var codigo = _a.codigo;
            return SSocket.sendPromise({
                service: "usuario",
                component: "usuario",
                type: "verificarCodigoPass",
                estado: "cargando",
                data: codigo
            });
        };
        _this.cambiarPassByCodigo = function (_a) {
            var password = _a.password, usuario_recuperado = _a.usuario_recuperado;
            return SSocket.sendPromise({
                service: "usuario",
                component: "usuario",
                type: "cambiarPassByCodigo",
                estado: "cargando",
                data: password,
                usuario_recuperado: usuario_recuperado
            });
        };
        _this.syncUserLog = function () {
            return new Promise(function (resolve, reject) {
                SSocket.sendPromise(__assign(__assign({}, _this.model.info), { type: "getById", estado: "cargando", key: _this.getKey() })).then(function (resp) {
                    resp.type = "login";
                    _this._dispatch(resp);
                    resolve(resp);
                })["catch"](function (e) {
                    reject(e);
                });
            });
        };
        _this.getByKeyAsync = function (_a) {
            var key = _a.key;
            return SSocket.sendPromise(__assign(__assign({}, _this.model.info), { type: "getById", estado: "cargando", key: key }));
        };
        _this.getByDato = function (_a) {
            var key = _a.key, value = _a.value;
            return SSocket.sendPromise(__assign(__assign({}, _this.model.info), { type: "getByDato", estado: "cargando", cabecera: PARAMS.cabecera, key_dato: key, dato: value }));
        };
        return _this;
    }
    Action.prototype.getAll = function () {
        return _super.prototype.getAll.call(this, {
            cabecera: PARAMS.cabecera,
            key_usuario: ""
        });
    };
    Action.prototype.registro = function (data) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, _super.prototype.registro.call(this, __assign({ cabecera: PARAMS.cabecera, key_usuario: "" }, data))];
            });
        });
    };
    Action.prototype.editar = function (extra) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, _super.prototype.editar.call(this, __assign({ cabecera: PARAMS.cabecera, key_usuario: "" }, extra))];
            });
        });
    };
    Action.prototype.login = function (_a) {
        var usuario = _a.usuario, password = _a.password;
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_b) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        var petition = __assign(__assign({}, _this.model.info), { type: "login", estado: "cargando", data: {
                                usuario: usuario,
                                password: password
                            } });
                        SSocket.sendPromise(petition).then(function (resp) {
                            _this._dispatch(resp);
                            resolve(resp);
                            SSocket.identificarse();
                        })["catch"](function (e) {
                            reject(e);
                        });
                    })];
            });
        });
    };
    Action.prototype.loginByKey = function (_a) {
        var usuario = _a.usuario, password = _a.password;
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_b) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        var petition = __assign(__assign({}, _this.model.info), { type: "loginByKey", estado: "cargando", data: {
                                user: usuario,
                                password: password
                            } });
                        SSocket.sendPromise(petition).then(function (resp) {
                            resp.type = "login";
                            _this._dispatch(resp);
                            SSocket.identificarse();
                            resolve(resp);
                        })["catch"](function (e) {
                            reject(e);
                        });
                    })];
            });
        });
    };
    Action.prototype.unlogin = function (Models) {
        SStorage.removeItem(PARAMS.storage_name);
        if (Models) {
            Models._events.CLEAR();
        }
        else if (Model) {
            Model._events.CLEAR();
        }
        SSocket.identificarse();
        SNavigation.reset("/");
    };
    Action.prototype.getUsuarioLog = function () {
        var reducer = this._getReducer();
        return reducer.usuarioLog;
    };
    Action.prototype.getKey = function () {
        var _a;
        var reducer = this._getReducer();
        return (_a = reducer.usuarioLog) === null || _a === void 0 ? void 0 : _a.key;
    };
    Action.prototype.validateRegistro = function (data) {
        return SSocket.sendPromise({
            service: "usuario",
            version: "2.0",
            component: "usuario",
            type: "validateRegistro",
            estado: "cargando",
            cabecera: PARAMS.cabecera,
            data: data
        });
    };
    return Action;
}(SAction));
export default Action;
