var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import Svg, { Path } from "react-native-svg";
var Web = function (props) { return (React.createElement(Svg, __assign({}, props, { viewBox: "0 0 60 60.13" }),
    React.createElement(Path, { d: "M56.09,48.07a5.65,5.65,0,0,1,0,11.29,5.65,5.65,0,1,1,0-11.29Zm-16.18,0a5.65,5.65,0,1,1-5.66,5.67,5.61,5.61,0,0,1,5.66-5.67Zm-16.27,0a5.65,5.65,0,1,1,0,11.29,5.65,5.65,0,0,1,0-11.29ZM56.09,33.45a5.64,5.64,0,0,1,5.64,5.63,5.56,5.56,0,0,1-5.64,5.63,5.65,5.65,0,0,1-5.67-5.63,5.74,5.74,0,0,1,5.67-5.63Zm-16.18,0a5.73,5.73,0,0,1,5.66,5.63,5.64,5.64,0,0,1-5.66,5.63,5.58,5.58,0,0,1-5.66-5.63,5.67,5.67,0,0,1,5.66-5.63Zm-16.27,0a5.67,5.67,0,0,1,5.66,5.63,5.65,5.65,0,0,1-11.29,0,5.73,5.73,0,0,1,5.63-5.63ZM22.11,20.6h3.82a1.84,1.84,0,0,0,1.82-1.9V11.22A1.85,1.85,0,0,0,25.93,9.3H22.11a1.9,1.9,0,0,0-1.81,1.92V18.7a1.9,1.9,0,0,0,1.81,1.9ZM69,24.32V61.86a7.56,7.56,0,0,1-7.44,7.58h-45A7.59,7.59,0,0,1,9,61.86V24.32a7.48,7.48,0,0,1,7.55-7.44h1.81V18.7a3.86,3.86,0,0,0,3.75,3.84h3.82a3.86,3.86,0,0,0,3.75-3.84V16.88H48.42V18.7a3.79,3.79,0,0,0,3.76,3.84H55.9a3.77,3.77,0,0,0,3.72-3.84V16.88h1.94A7.45,7.45,0,0,1,69,24.32Zm-3.71,7.57c0-7.24-42.25-3.81-48.74-3.81a3.83,3.83,0,0,0-3.82,3.81v30a3.77,3.77,0,0,0,3.82,3.75h45a3.68,3.68,0,0,0,3.73-3.75ZM52.18,20.6H55.9a1.91,1.91,0,0,0,1.91-1.9V11.22A1.92,1.92,0,0,0,55.9,9.3H52.18a2,2,0,0,0-2,1.92V18.7a2,2,0,0,0,2,1.9Z", transform: "translate(-9 -9.3)" }))); };
var Native = Web;
export default { Native: Native, Web: Web };
