var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from 'react';
import { SHr, SList, SMath, SScrollView2, SText, STheme, SView } from 'servisofts-component';
var body = /** @class */ (function (_super) {
    __extends(body, _super);
    function body(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {};
        return _this;
    }
    body.prototype.render_header = function () {
        return React.createElement(SView, { col: "xs-12", row: true },
            React.createElement(SView, { flex: true, center: true },
                React.createElement(SText, { bold: true }, "CUENTA")),
            React.createElement(SView, { flex: true, center: true },
                React.createElement(SText, { bold: true }, "GLOSA")),
            React.createElement(SView, { width: this.props.monto_size, center: true },
                React.createElement(SText, { bold: true }, "DEBE")),
            React.createElement(SView, { width: this.props.monto_size, center: true },
                React.createElement(SText, { bold: true }, "HABER")),
            React.createElement(SView, { width: this.props.monto_size, center: true },
                React.createElement(SText, { bold: true }, "CHEQUE")));
    };
    body.prototype.render_item = function (obj) {
        var _a, _b, _c;
        var cuenta = this.props.cuentas_contables[obj.key_cuenta_contable];
        var textProps = {
            fontSize: 12
        };
        return React.createElement(React.Fragment, null,
            React.createElement(SView, { col: "xs-12", center: true, padding: 4, row: true },
                React.createElement(SView, { col: "xxs-12 xs-6", row: true },
                    React.createElement(SView, { col: "xs-12" },
                        React.createElement(SText, __assign({}, textProps), "".concat(cuenta === null || cuenta === void 0 ? void 0 : cuenta.codigo, " ").concat(cuenta === null || cuenta === void 0 ? void 0 : cuenta.descripcion))),
                    React.createElement(SView, { col: "xs-12" },
                        React.createElement(SText, __assign({}, textProps, { color: STheme.color.lightGray }), "".concat(obj.descripcion)))),
                React.createElement(SView, { col: "xxs-12 xs-6", row: true, style: {
                        marginTop: 4
                    } },
                    React.createElement(SView, { flex: true, center: true },
                        React.createElement(SText, __assign({}, textProps, { flex: true }), !obj.debe ? null : SMath.formatMoney((_a = obj.debe) !== null && _a !== void 0 ? _a : 0, 2, "."))),
                    React.createElement(SView, { flex: true, center: true },
                        React.createElement(SText, __assign({}, textProps, { flex: true }), !obj.haber ? null : SMath.formatMoney((_b = obj.haber) !== null && _b !== void 0 ? _b : 0, 2, "."))),
                    React.createElement(SView, { flex: true, center: true },
                        React.createElement(SText, __assign({}, textProps, { flex: true }), !obj.cheque ? null : SMath.formatMoney((_c = obj === null || obj === void 0 ? void 0 : obj.cheque) !== null && _c !== void 0 ? _c : 0, 2, "."))))),
            React.createElement(SHr, { height: 1, color: STheme.color.card }));
        return React.createElement(SView, { col: "xs-12", row: true },
            React.createElement(SView, { flex: true },
                React.createElement(SText, __assign({}, textProps), "".concat(cuenta === null || cuenta === void 0 ? void 0 : cuenta.codigo, " ").concat(cuenta === null || cuenta === void 0 ? void 0 : cuenta.descripcion))),
            React.createElement(SView, { flex: true },
                React.createElement(SText, __assign({}, textProps), obj.descripcion)),
            React.createElement(SView, { width: this.props.monto_size, style: { alignItems: "flex-end" } },
                React.createElement(SText, __assign({}, textProps), !obj.debe ? null : SMath.formatMoney(obj.debe))),
            React.createElement(SView, { width: this.props.monto_size, style: { alignItems: "flex-end" } },
                React.createElement(SText, __assign({}, textProps), !obj.haber ? null : SMath.formatMoney(obj.haber))),
            React.createElement(SView, { width: this.props.monto_size, style: { alignItems: "flex-end" } },
                React.createElement(SText, __assign({}, textProps), "")));
    };
    body.prototype.render_bars = function () {
        var styleitem = {
            borderWidth: 1,
            borderColor: STheme.color.card
        };
        return React.createElement(SView, { col: "xs-12", height: true, style: {
                position: "absolute"
            } },
            React.createElement(SView, { col: "xs-12", row: true, height: true },
                React.createElement(SView, { height: true, flex: true, style: styleitem }),
                React.createElement(SView, { height: true, flex: true, style: styleitem }),
                React.createElement(SView, { height: true, width: this.props.monto_size, style: styleitem }),
                React.createElement(SView, { height: true, width: this.props.monto_size, style: styleitem }),
                React.createElement(SView, { height: true, width: this.props.monto_size, style: styleitem })));
    };
    body.prototype.render_list = function () {
        return React.createElement(SList, { data: this.props.data, order: [{ key: "index", order: "asc", peso: 1 }], render: this.render_item.bind(this) });
    };
    body.prototype.render = function () {
        return React.createElement(SView, { col: 'xs-12', card: true, flex: true },
            React.createElement(SScrollView2, { disableHorizontal: true }, this.render_list()));
    };
    body.defaultProps = {
        data: null,
        cuentas_contables: null,
        monto_size: 100
    };
    return body;
}(Component));
export default body;
