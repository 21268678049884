import { Platform } from "react-native";
export var calcular_procentaje_100 = function (_a) {
    var val = _a.val, max = _a.max;
    return (val / max) * 100;
};
export var calcular_procentaje = function (_a) {
    var val = _a.val, max = _a.max;
    return (val / max);
};
export var number_to_porcent_string = function (val) {
    return parseFloat(val).toFixed(0) + "%";
};
export var calcular_maximo = function (arr) {
    var max = 0;
    arr.map(function (a) { return max < a.val ? max = a.val : null; });
    return max;
};
export var calcular_suma = function (arr) {
    var max = 0;
    arr.map(function (a) { return max += a.val; });
    return max;
};
export var calcular_promedio = function (arr) {
    return calcular_suma(arr) / arr.length;
};
export var calcular_porcentaje_to_grados = function (grados) { return (100 - ((grados * 100) / 360)); };
export var calcular_grados_to_strokeDashoffset = function (grados) {
    // ((porcentaje_to_grados(10) / 100) * Math.PI))
    // return (100 - ((grados * 100) / 360))
    return (1 - (grados / 360)) * Math.PI;
};
export var color_random = function () {
    var r = Math.floor(Math.random() * 156) + 50;
    var g = Math.floor(Math.random() * 156) + 50;
    var b = Math.floor(Math.random() * 156) + 50;
    return "rgb(" + r + ", " + g + ", " + b + ")";
};
export var create_onPress = function (callback) {
    if (Platform.OS == "web") {
        return {
            onClick: callback
        };
    }
    return {
        onPress: callback
    };
};
