var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Model, SAction, SModel } from "servisofts-model";
// import Model from "../..";
var Action = /** @class */ (function (_super) {
    __extends(Action, _super);
    function Action() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Action.prototype.getAll = function () {
        var empresa = SModel.getModel("empresa").Action.getSelect();
        if (!empresa)
            return null;
        return this.getAllByKeyEmpresa(empresa.key);
    };
    Action.prototype.getAllByKeyEmpresa = function (key_empresa) {
        var reducer = this._getReducer();
        if (reducer.key_empresa != key_empresa) {
            reducer.data = "";
            reducer.key_empresa = key_empresa;
        }
        var resp = _super.prototype.getAll.call(this, {
            key_empresa: key_empresa,
            key_usuario: SModel.getModel("usuario").Action.getKey()
        });
        return resp;
    };
    Action.prototype.registro = function (data) {
        var _a;
        if (!data.key_empresa) {
            data.key_empresa = (_a = Model.empresa.Action.getSelect()) === null || _a === void 0 ? void 0 : _a.key; //TODO
            if (!data.key_empresa) {
                return null;
            }
        }
        return _super.prototype.registro.call(this, {
            data: data,
            key_usuario: Model.usuario.Action.getKey()
        });
    };
    return Action;
}(SAction));
export default Action;
