var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from 'react';
import { SHr, SImage, SInput, SList, SLoad, SText, STheme, SView } from 'servisofts-component';
import SSocket from 'servisofts-socket';
import { Model } from 'servisofts-model';
var EditarPermisosRol = /** @class */ (function (_super) {
    __extends(EditarPermisosRol, _super);
    function EditarPermisosRol(props) {
        return _super.call(this, props) || this;
    }
    EditarPermisosRol.prototype.render = function () {
        var _this = this;
        var pages = Model.page.Action.getAll({
            key_usuario: ""
        });
        var permisos = Model.permiso.Action.getAll();
        var permisos_rol = Model.rolPermiso.Action.getAllByKeyRol(this.props.key_rol);
        var allowEdit = Model.usuarioPage.Action.getPermiso({ url: this.props.url_permiso, permiso: "edit" });
        var roles = Model.usuarioPage.Action.getRoles();
        if (!pages)
            return React.createElement(SLoad, null);
        if (!permisos)
            return React.createElement(SLoad, null);
        if (!permisos_rol)
            return React.createElement(SLoad, null);
        if (!roles)
            return React.createElement(SLoad, null);
        if (allowEdit == "cargando")
            return React.createElement(SLoad, null);
        return (React.createElement(SView, { col: "xs-12" },
            React.createElement(SHr, null),
            React.createElement(SText, { fontSize: 16, bold: true }, "Permisos"),
            React.createElement(SHr, null),
            React.createElement(SList, { buscador: true, order: [{ key: "url", order: "asc", peso: 1 }], 
                // horizontal={true}
                limit: 10, data: pages, render: function (itm) {
                    var pf = Object.values(permisos).filter(function (obj) { return obj.key_page == itm.key; });
                    var cant = itm.url.split(/\//g).length;
                    return React.createElement(SView, { col: "xs-12", style: {
                            padding: 8,
                            paddingLeft: (cant - 1) * 16
                        }, card: true },
                        React.createElement(SHr, { height: 16 }),
                        React.createElement(SView, { row: true, flex: true, style: {
                                alignItems: "center"
                            } },
                            React.createElement(SView, { width: 40, height: 40, card: true },
                                React.createElement(SImage, { src: Model.page._get_image_download_path(SSocket.api, itm.key) })),
                            React.createElement(SView, { width: 8 }),
                            React.createElement(SView, { flex: true },
                                React.createElement(SText, { fontSize: 16, bold: true }, itm.descripcion),
                                React.createElement(SHr, { height: 4 }),
                                React.createElement(SText, { fontSize: 12 }, itm.url))),
                        React.createElement(SHr, null),
                        React.createElement(SList, { data: pf, horizontal: true, order: [{ key: "type", order: "desc", "peso": 1 }], space: 16, render: function (itm2) {
                                var activo = Object.values(permisos_rol).find(function (elm) { return elm.key_permiso == itm2.key; });
                                return React.createElement(SView, { style: {
                                        alignItems: 'center',
                                        marginTop: 4
                                    } },
                                    React.createElement(SInput, { col: null, type: "checkBox", defaultValue: !!activo, disabled: !allowEdit, onChangeText: function (e) {
                                            if (e) {
                                                Model.rolPermiso.Action.registro({
                                                    key_rol: _this.props.key_rol,
                                                    key_permiso: itm2.key
                                                });
                                            }
                                            else {
                                                Model.rolPermiso.Action.editar(__assign(__assign({}, activo), { estado: 0 }));
                                            }
                                        } }),
                                    React.createElement(SText, { color: STheme.color.lightGray }, itm2.descripcion));
                            } }));
                } }),
            React.createElement(SHr, { height: 30 })));
    };
    return EditarPermisosRol;
}(Component));
export default EditarPermisosRol;
