var Counesies = [
    { es: "Afganistan", en: 'Afghanistan', flag: '🇦🇫', code: 'AF', dialCode: '+93', mask: '999 999 9999' },
    { es: "Aland adaları", en: 'Åland Islands', flag: '🇦🇽', code: 'AX', dialCode: '+358', mask: '999 9999999' },
    { es: "Arnavutluk", en: 'Albania', flag: '🇦🇱', code: 'AL', dialCode: '+355', mask: '999 999 9999' },
    { es: "Cezayir", en: 'Algeria', flag: '🇩🇿', code: 'DZ', dialCode: '+213', mask: '9999 99 99 99' },
    { es: "Amerikan Samoası", en: 'American Samoa', flag: '🇦🇸', code: 'AS', dialCode: '+1684', mask: '(999) 999-9999' },
    { es: "Andorra", en: 'Andorra', flag: '🇦🇩', code: 'AD', dialCode: '+376', mask: '999 999' },
    { es: "Angora", en: 'Angola', flag: '🇦🇴', code: 'AO', dialCode: '+244', mask: '999 999 999' },
    { es: "Anguilla", en: 'Anguilla', flag: '🇦🇮', code: 'AI', dialCode: '+1264', mask: '(999) 999-9999' },
    { es: "Antigua ve Barbuda", en: 'Antigua and Barbuda', flag: '🇦🇬', code: 'AG', dialCode: '+1268', mask: '(999) 999-9999' },
    { es: "Arjantin", en: 'Argentina', flag: '🇦🇷', code: 'AR', dialCode: '+54', mask: '999 99-9999-9999' },
    { es: "Ermenistan", en: 'Armenia', flag: '🇦🇲', code: 'AM', dialCode: '+374', mask: '999 999999' },
    { es: "Aruba", en: 'Aruba', flag: '🇦🇼', code: 'AW', dialCode: '+297', mask: '999 9999' },
    { es: "Avusesalya", en: 'Ausesalia', flag: '🇦🇺', code: 'AU', dialCode: '+61', mask: '9999 999 999' },
    { es: "Avusturya", en: 'Ausesia', flag: '🇦🇹', code: 'AT', dialCode: '+43', mask: '9999 999999' },
    { es: "Azerbeycan", en: 'Azerbaijan', flag: '🇦🇿', code: 'AZ', dialCode: '+994', mask: '999 999 99 99' },
    { es: "Bahamalar", en: 'Bahamas', flag: '🇧🇸', code: 'BS', dialCode: '+1242', mask: '(999) 999-999' },
    { es: "Bahreyn", en: 'Bahrain', flag: '🇧🇭', code: 'BH', dialCode: '+973', mask: '9999 9999' },
    { es: "Bangladeş", en: 'Bangladesh', flag: '🇧🇩', code: 'BD', dialCode: '+880', mask: '99999-999999' },
    { es: "Barbados", en: 'Barbados', flag: '🇧🇧', code: 'BB', dialCode: '+1246', mask: '(999) 999-9999' },
    { es: "Belarus", en: 'Belarus', flag: '🇧🇾', code: 'BY', dialCode: '+375', mask: '9 999 999-99-99' },
    { es: "Belçika", en: 'Belgium', flag: '🇧🇪', code: 'BE', dialCode: '+32', mask: '9999 99 99 99' },
    { es: "Belize", en: 'Belize', flag: '🇧🇿', code: 'BZ', dialCode: '+501', mask: '999-9999' },
    { es: "Benin", en: 'Benin', flag: '🇧🇯', code: 'BJ', dialCode: '+229', mask: '99 99 99 99' },
    { es: "Bermuda", en: 'Bermuda', flag: '🇧🇲', code: 'BM', dialCode: '+1441', mask: '(999) 999-9999' },
    { es: "Butan", en: 'Bhutan', flag: '🇧🇹', code: 'BT', dialCode: '+975', mask: '99 99 99 99' },
    { es: "Bolivia", en: 'Bolivia', flag: '🇧🇴', code: 'BO', dialCode: '+591', mask: '99999999' },
    { es: "Bosna Hersek", en: 'Bosnia and Herzegovina', flag: '🇧🇦', code: 'BA', dialCode: '+387', mask: '999 999 999' },
    { es: "Botsvana", en: 'Botswana', flag: '🇧🇼', code: 'BW', dialCode: '+267', mask: '99 999 999' },
    { es: "Bouvet Adası", en: 'Bouvet Island', flag: '🇧🇻', code: 'BV', dialCode: '+47', mask: '(99) 99999-9999' },
    { es: "Brezilya", en: 'Brazil', flag: '🇧🇷', code: 'BR', dialCode: '+55', mask: '(99) 99999 9999' },
    { es: "Britanya Hint Okyanusu Toprakları", en: 'British Indian Ocean Territory', flag: '🇮🇴', code: 'IO', dialCode: '+246', mask: '(999) 999-9999' },
    { es: "Brunei Sultanlığı", en: 'Brunei Darussalam', flag: '🇧🇳', code: 'BN', dialCode: '+673', mask: '999 9999' },
    { es: "Bulgaristan", en: 'Bulgaria', flag: '🇧🇬', code: 'BG', dialCode: '+359', mask: '999 999 999' },
    { es: "Burkina Faso", en: 'Burkina Faso', flag: '🇧🇫', code: 'BF', dialCode: '+226', mask: '99 99 99 99' },
    { es: "Burundi", en: 'Burundi', flag: '🇧🇮', code: 'BI', dialCode: '+257', mask: '99 99 99 99' },
    { es: "Kamboçya", en: 'Cambodia', flag: '🇰🇭', code: 'KH', dialCode: '+855', mask: '999 999 999' },
    { es: "Kamerun", en: 'Cameroon', flag: '🇨🇲', code: 'CM', dialCode: '+237', mask: '9 99 99 99 99' },
    { es: "Kanada", en: 'Canada', flag: '🇨🇦', code: 'CA', dialCode: '+1', mask: '(999) 999-9999' },
    { es: "Yeşil Burun Adaları", en: 'Cape Verde', flag: '🇨🇻', code: 'CV', dialCode: '+238', mask: '999 99 99' },
    { es: "Cayman Adaları", en: 'Cayman Islands', flag: '🇰🇾', code: 'KY', dialCode: '+345', mask: '999 9999' },
    { es: "Orta Afrika Cumhuriyeti", en: 'Cenesal African Republic', flag: '🇨🇫', code: 'CF', dialCode: '+236', mask: '99 99 99 99' },
    { es: "Çad", en: 'Chad', flag: '🇹🇩', code: 'TD', dialCode: '+235', mask: '99 99 99 99' },
    { es: "Şili", en: 'Chile', flag: '🇨🇱', code: 'CL', dialCode: '+56', mask: '(9) 9999 9999' },
    { es: "Çin", en: 'China', flag: '🇨🇳', code: 'CN', dialCode: '+86', mask: '999 9999 9999' },
    { es: "Noel Adası", en: 'Christmas Island', flag: '🇨🇽', code: 'CX', dialCode: '+61', mask: '9999 999 999' },
    { es: "Cocos (Keeling) Adaları", en: 'Cocos (Keeling) Islands', flag: '🇨🇨', code: 'CC', dialCode: '+61', mask: '9999 999 999' },
    { es: "Kolombiya", en: 'Colombia', flag: '🇨🇴', code: 'CO', dialCode: '+57', mask: '999 9999999' },
    { es: "Komorlar", en: 'Comoros', flag: '🇰🇲', code: 'KM', dialCode: '+269', mask: '999 99 99' },
    { es: "Kongo", en: 'Congo', flag: '🇨🇬', code: 'CG', dialCode: '+242', mask: '9999 999 999' },
    { es: "Kongo, Demokratik Kongo Cumhuriyeti", en: 'Congo, The Democratic Republic of the Congo', flag: '🇨🇩', code: 'CD', dialCode: '+243', mask: '99 999 9999' },
    { es: "Cook Adaları", en: 'Cook Islands', flag: '🇨🇰', code: 'CK', dialCode: '+682', mask: '99 999' },
    { es: "Kosta Rika", en: 'Costa Rica', flag: '🇨🇷', code: 'CR', dialCode: '+506', mask: '9999 9999' },
    { es: "Fildişi Sahili", en: "Cote d'Ivoire", flag: '🇨🇮', code: 'CI', dialCode: '+225', mask: '99 99 99 99' },
    { es: "Hırvatistan", en: 'Croatia', flag: '🇭🇷', code: 'HR', dialCode: '+385', mask: '999 999 9999' },
    { es: "Küba", en: 'Cuba', flag: '🇨🇺', code: 'CU', dialCode: '+53', mask: '99 9999999' },
    { es: "Kıbrıs", en: 'Cyprus', flag: '🇨🇾', code: 'CY', dialCode: '+357', mask: '99 999999' },
    { es: "Çek Cumhuriyeti", en: 'Czech Republic', flag: '🇨🇿', code: 'CZ', dialCode: '+420', mask: '999 999 999' },
    { es: "Danimarka", en: 'Denmark', flag: '🇩🇰', code: 'DK', dialCode: '+45', mask: '99 99 99 99' },
    { es: "Cibuti", en: 'Djibouti', flag: '🇩🇯', code: 'DJ', dialCode: '+253', mask: '99 99 99 99' },
    { es: "Dominika", en: 'Dominica', flag: '🇩🇲', code: 'DM', dialCode: '+1767', mask: '(999) 999-9999' },
    { es: "Ekvador", en: 'Ecuador', flag: '🇪🇨', code: 'EC', dialCode: '+593', mask: '999 999 9999' },
    { es: "Mısır", en: 'Egypt', flag: '🇪🇬', code: 'EG', dialCode: '+20', mask: '9999 999 9999' },
    { es: "El Salvador", en: 'El Salvador', flag: '🇸🇻', code: 'SV', dialCode: '+503', mask: '9999 9999' },
    { es: "Ekvator Ginesi", en: 'Equatorial Guinea', flag: '🇬🇶', code: 'GQ', dialCode: '+240', mask: '999 999 999' },
    { es: "Eriese", en: 'Eriesea', flag: '🇪🇷', code: 'ER', dialCode: '+291', mask: '99 999 999' },
    { es: "Estonya", en: 'Estonia', flag: '🇪🇪', code: 'EE', dialCode: '+372', mask: '9999 9999' },
    { es: "Etiyopya", en: 'Ethiopia', flag: '🇪🇹', code: 'ET', dialCode: '+251', mask: '999 999 9999' },
    { es: "Falkland Adaları (Malvinas)", en: 'Falkland Islands (Malvinas)', flag: '🇫🇰', code: 'FK', dialCode: '+500', mask: '99999' },
    { es: "Faroe Adaları", en: 'Faroe Islands', flag: '🇫🇴', code: 'FO', dialCode: '+298', mask: '999999' },
    { es: "Fiji", en: 'Fiji', flag: '🇫🇯', code: 'FJ', dialCode: '+679', mask: '999 9999' },
    { es: "Finlandiya", en: 'Finland', flag: '🇫🇮', code: 'FI', dialCode: '+358', mask: '999 9999999' },
    { es: "Fransa", en: 'France', flag: '🇫🇷', code: 'FR', dialCode: '+33', mask: '99 99 99 99 99' },
    { es: "Fransız Guyanası", en: 'French Guiana', flag: '🇬🇫', code: 'GF', dialCode: '+594', mask: '9999 99 99 99' },
    { es: "Fransız Polinezyası", en: 'French Polynesia', flag: '🇵🇫', code: 'PF', dialCode: '+689', mask: '99 99 99 99' },
    { es: "Gabon", en: 'Gabon', flag: '🇬🇦', code: 'GA', dialCode: '+241', mask: '99 99 99 99' },
    { es: "Gambiya", en: 'Gambia', flag: '🇬🇲', code: 'GM', dialCode: '+220', mask: '999 9999' },
    { es: "Gürcistan", en: 'Georgia', flag: '🇬🇪', code: 'GE', dialCode: '+995', mask: '999 99 99 99' },
    { es: "Almanya", en: 'Germany', flag: '🇩🇪', code: 'DE', dialCode: '+49', mask: '99999 9999999' },
    { es: "Gana", en: 'Ghana', flag: '🇬🇭', code: 'GH', dialCode: '+233', mask: '999 999 9999' },
    { es: "Cebelitarık", en: 'Gibraltar', flag: '🇬🇮', code: 'GI', dialCode: '+350', mask: '99999999' },
    { es: "Yunanistan", en: 'Greece', flag: '🇬🇷', code: 'GR', dialCode: '+30', mask: '999 999 9999' },
    { es: "Grönland", en: 'Greenland', flag: '🇬🇱', code: 'GL', dialCode: '+299', mask: '99 99 99' },
    { es: "Grenada", en: 'Grenada', flag: '🇬🇩', code: 'GD', dialCode: '+1473', mask: '(999) 999-9999' },
    { es: "Guadeloupe", en: 'Guadeloupe', flag: '🇬🇵', code: 'GP', dialCode: '+590', mask: '9999 99 99 99' },
    { es: "Guam", en: 'Guam', flag: '🇬🇺', code: 'GU', dialCode: '+1671', mask: '(999) 999-9999' },
    { es: "Guatemala", en: 'Guatemala', flag: '🇬🇹', code: 'GT', dialCode: '+502', mask: '9999 9999' },
    { es: "bir tür inek", en: 'Guernsey', flag: '🇬🇬', code: 'GG', dialCode: '+44', mask: '99999 999999' },
    { es: "Gine", en: 'Guinea', flag: '🇬🇳', code: 'GN', dialCode: '+224', mask: '999 99 99 99' },
    { es: "Gine-Bissau", en: 'Guinea-Bissau', flag: '🇬🇼', code: 'GW', dialCode: '+245', mask: '999 999 999' },
    { es: "Guyana", en: 'Guyana', flag: '🇬🇾', code: 'GY', dialCode: '+592', mask: '999 9999' },
    { es: "Haiti", en: 'Haiti', flag: '🇭🇹', code: 'HT', dialCode: '+509', mask: '99 99 9999' },
    { es: "Honduras", en: 'Honduras', flag: '🇭🇳', code: 'HN', dialCode: '+504', mask: '9999-9999' },
    { es: "Hong Kong", en: 'Hong Kong', flag: '🇭🇰', code: 'HK', dialCode: '+852', mask: '9999 9999' },
    { es: "Macaristan", en: 'Hungary', flag: '🇭🇺', code: 'HU', dialCode: '+36', mask: '(99) 999 9999' },
    { es: "İzlanda", en: 'Iceland', flag: '🇮🇸', code: 'IS', dialCode: '+354', mask: '999 9999' },
    { es: "Hindistan", en: 'India', flag: '🇮🇳', code: 'IN', dialCode: '+91', mask: '99999 99999' },
    { es: "Endonezya", en: 'Indonesia', flag: '🇮🇩', code: 'ID', dialCode: '+62', mask: '9999-999-999' },
    { es: "İran, Basra Körfezi İslam Cumhuriyeti", en: 'Iran, Islamic Republic of Persian Gulf', flag: '🇮🇷', code: 'IR', dialCode: '+98', mask: '9999 999 9999' },
    { es: "Irak", en: 'Iraq', flag: '🇮🇶', code: 'IQ', dialCode: '+964', mask: '9999 999 9999' },
    { es: "İrlanda", en: 'Ireland', flag: '🇮🇪', code: 'IE', dialCode: '+353', mask: '999 999 9999' },
    { es: "Man Adası", en: 'Isle of Man', flag: '🇮🇲', code: 'IM', dialCode: '+44', mask: '99999 999999' },
    { es: "İsrail", en: 'Israel', flag: '🇮🇱', code: 'IL', dialCode: '+972', mask: '999-999-9999' },
    { es: "İtalya", en: 'Italy', flag: '🇮🇹', code: 'IT', dialCode: '+39', mask: '999 999 9999' },
    { es: "Jamaika", en: 'Jamaica', flag: '🇯🇲', code: 'JM', dialCode: '+1876', mask: '(999) 999-9999' },
    { es: "Japonya", en: 'Japan', flag: '🇯🇵', code: 'JP', dialCode: '+81', mask: '999-9999-9999' },
    { es: "Jersey", en: 'Jersey', flag: '🇯🇪', code: 'JE', dialCode: '+44', mask: '99999 999999' },
    { es: "Ürdün", en: 'Jordan', flag: '🇯🇴', code: 'JO', dialCode: '+962', mask: '99 9999 9999' },
    { es: "Kazakistan", en: 'Kazakhstan', flag: '🇰🇿', code: 'KZ', dialCode: '+7', mask: '9 (999) 999 9999' },
    { es: "Kenya", en: 'Kenya', flag: '🇰🇪', code: 'KE', dialCode: '+254', mask: '9999 999999' },
    { es: "Kiribati", en: 'Kiribati', flag: '🇰🇮', code: 'KI', dialCode: '+686', mask: '99999999' },
    { es: "Kore, Kore Demokratik Halk Cumhuriyeti", en: "Korea, Democratic People's Republic of Korea", flag: '🇰🇵', code: 'KP', dialCode: '+850', mask: '(9) 99999' },
    { es: "Güney Kore Cumhuriyeti", en: 'Korea, Republic of South Korea', flag: '🇰🇷', code: 'KR', dialCode: '+82', mask: '999-9999-9999' },
    { es: "Kosova", en: 'Kosovo', flag: '🇽🇰', code: 'XK', dialCode: '+383', mask: '999 999 999' },
    { es: "Kuveyt", en: 'Kuwait', flag: '🇰🇼', code: 'KW', dialCode: '+965', mask: '999 99999' },
    { es: "Kırgızistan", en: 'Kyrgyzstan', flag: '🇰🇬', code: 'KG', dialCode: '+996', mask: '9999 999 999' },
    { es: "Laos", en: 'Laos', flag: '🇱🇦', code: 'LA', dialCode: '+856', mask: '999 99 999 999' },
    { es: "Letonya", en: 'Latvia', flag: '🇱🇻', code: 'LV', dialCode: '+371', mask: '99 999 999' },
    { es: "Lübnan", en: 'Lebanon', flag: '🇱🇧', code: 'LB', dialCode: '+961', mask: '99 999 999' },
    { es: "Lesotho", en: 'Lesotho', flag: '🇱🇸', code: 'LS', dialCode: '+266', mask: '9999 9999' },
    { es: "Liberya", en: 'Liberia', flag: '🇱🇷', code: 'LR', dialCode: '+231', mask: '999 999 9999' },
    { es: "Libya Arap Jamahiriya", en: 'Libyan Arab Jamahiriya', flag: '🇱🇾', code: 'LY', dialCode: '+218', mask: '999-9999999' },
    { es: "Lihtenştayn", en: 'Liechtenstein', flag: '🇱🇮', code: 'LI', dialCode: '+423', mask: '999 999 999' },
    { es: "Litvanya", en: 'Lithuania', flag: '🇱🇹', code: 'LT', dialCode: '+370', mask: '(9-999) 9999' },
    { es: "Lüksemburg", en: 'Luxembourg', flag: '🇱🇺', code: 'LU', dialCode: '+352', mask: '999 999 999' },
    { es: "Macao", en: 'Macao', flag: '🇲🇴', code: 'MO', dialCode: '+853', mask: '9999 9999' },
    { es: "Makedonya", en: 'Macedonia', flag: '🇲🇰', code: 'MK', dialCode: '+389', mask: '999 999 999' },
    { es: "Madagaskar", en: 'Madagascar', flag: '🇲🇬', code: 'MG', dialCode: '+261', mask: '999 99 999 99' },
    { es: "Malawi", en: 'Malawi', flag: '🇲🇼', code: 'MW', dialCode: '+265', mask: '9999 99 99 99' },
    { es: "Malezya", en: 'Malaysia', flag: '🇲🇾', code: 'MY', dialCode: '+60', mask: '999-999 9999' },
    { es: "Maldivler", en: 'Maldives', flag: '🇲🇻', code: 'MV', dialCode: '+960', mask: '999-9999' },
    { es: "Mali", en: 'Mali', flag: '🇲🇱', code: 'ML', dialCode: '+223', mask: '99 99 99 99' },
    { es: "Malta", en: 'Malta', flag: '🇲🇹', code: 'MT', dialCode: '+356', mask: '9999 9999' },
    { es: "Marşal Adaları", en: 'Marshall Islands', flag: '🇲🇭', code: 'MH', dialCode: '+692', mask: '999-9999' },
    { es: "Martinik", en: 'Martinique', flag: '🇲🇶', code: 'MQ', dialCode: '+596', mask: '9999 99 99 99' },
    { es: "Moritanya", en: 'Mauritania', flag: '🇲🇷', code: 'MR', dialCode: '+222', mask: '99 99 99 99' },
    { es: "Mauritius", en: 'Mauritius', flag: '🇲🇺', code: 'MU', dialCode: '+230', mask: '9999 9999' },
    { es: "Mayotte", en: 'Mayotte', flag: '🇾🇹', code: 'YT', dialCode: '+262', mask: '9999 99 99 99' },
    { es: "Meksika", en: 'Mexico', flag: '🇲🇽', code: 'MX', dialCode: '+52', mask: '999 999 999 9999' },
    { es: "Mikronezya, Mikronezya Federal Devletleri", en: 'Micronesia, Federated States of Micronesia', flag: '🇫🇲', code: 'FM', dialCode: '+691', mask: '999 9999' },
    { es: "Moldova", en: 'Moldova', flag: '🇲🇩', code: 'MD', dialCode: '+373', mask: '9999 99 999' },
    { es: "Monako", en: 'Monaco', flag: '🇲🇨', code: 'MC', dialCode: '+377', mask: '99 99 99 99 99' },
    { es: "Moğolistan", en: 'Mongolia', flag: '🇲🇳', code: 'MN', dialCode: '+976', mask: '9999 9999' },
    { es: "Karadağ", en: 'Montenegro', flag: '🇲🇪', code: 'ME', dialCode: '+382', mask: '999 999 999' },
    { es: "Montserrat", en: 'Montserrat', flag: '🇲🇸', code: 'MS', dialCode: '+1664', mask: '(999) 999-9999' },
    { es: "Fas", en: 'Morocco', flag: '🇲🇦', code: 'MA', dialCode: '+212', mask: '9999-999999' },
    { es: "Mozambik", en: 'Mozambique', flag: '🇲🇿', code: 'MZ', dialCode: '+258', mask: '99 999 9999' },
    { es: "Myanmar", en: 'Myanmar', flag: '🇲🇲', code: 'MM', dialCode: '+95', mask: '99 999 9999' },
    { es: "Namibya", en: 'Namibia', flag: '🇳🇦', code: 'NA', dialCode: '+264', mask: '999 999 99999' },
    { es: "Nauru", en: 'Nauru', flag: '🇳🇷', code: 'NR', dialCode: '+674', mask: '999 9999' },
    { es: "Nepal", en: 'Nepal', flag: '🇳🇵', code: 'NP', dialCode: '+977', mask: '999-9999999' },
    { es: "Hollanda", en: 'Netherlands', flag: '🇳🇱', code: 'NL', dialCode: '+31', mask: '99' },
    { es: "Yeni Kaledonya", en: 'New Caledonia', flag: '🇳🇨', code: 'NC', dialCode: '+687', mask: '99.99.99' },
    { es: "Yeni Zelanda", en: 'New Zealand', flag: '🇳🇿', code: 'NZ', dialCode: '+64', mask: '999 999 9999' },
    { es: "Nikaragua", en: 'Nicaragua', flag: '🇳🇮', code: 'NI', dialCode: '+505', mask: '9999 9999' },
    { es: "Nijer", en: 'Niger', flag: '🇳🇪', code: 'NE', dialCode: '+227', mask: '99 99 99 99' },
    { es: "Nijerya", en: 'Nigeria', flag: '🇳🇬', code: 'NG', dialCode: '+234', mask: '9999 999 9999' },
    { es: "Niue", en: 'Niue', flag: '🇳🇺', code: 'NU', dialCode: '+683', mask: '999 9999' },
    { es: "Norfolk Adası", en: 'Norfolk Island', flag: '🇳🇫', code: 'NF', dialCode: '+672', mask: '9 99999' },
    { es: "Kuzey Mariana Adaları", en: 'Northern Mariana Islands', flag: '🇲🇵', code: 'MP', dialCode: '+1670', mask: '(999) 999-9999' },
    { es: "Norveç", en: 'Norway', flag: '🇳🇴', code: 'NO', dialCode: '+47', mask: '999 99 999' },
    { es: "Umman", en: 'Oman', flag: '🇴🇲', code: 'OM', dialCode: '+968', mask: '9999 9999' },
    { es: "Pakistan", en: 'Pakistan', flag: '🇵🇰', code: 'PK', dialCode: '+92', mask: '9999 9999999' },
    { es: "Palau", en: 'Palau', flag: '🇵🇼', code: 'PW', dialCode: '+680', mask: '999 9999' },
    { es: "Filistin Arazisi, İşgal altında", en: 'Palestinian Territory, Occupied', flag: '🇵🇸', code: 'PS', dialCode: '+970', mask: '9999 999 999' },
    { es: "Panama", en: 'Panama', flag: '🇵🇦', code: 'PA', dialCode: '+507', mask: '9999-9999' },
    { es: "Papua Yeni Gine", en: 'Papua New Guinea', flag: '🇵🇬', code: 'PG', dialCode: '+675', mask: '9999 9999' },
    { es: "Paraguay", en: 'Paraguay', flag: '🇵🇾', code: 'PY', dialCode: '+595', mask: '9999 999999' },
    { es: "Peru", en: 'Peru', flag: '🇵🇪', code: 'PE', dialCode: '+51', mask: '999 999 999' },
    { es: "Filipinler", en: 'Philippines', flag: '🇵🇭', code: 'PH', dialCode: '+63', mask: '9999 999 9999' },
    { es: "Polonya", en: 'Poland', flag: '🇵🇱', code: 'PL', dialCode: '+48', mask: '999 999 999' },
    { es: "Portekiz", en: 'Portugal', flag: '🇵🇹', code: 'PT', dialCode: '+351', mask: '999 999 999' },
    { es: "Porto Riko", en: 'Puerto Rico', flag: '🇵🇷', code: 'PR', dialCode: '+1939', mask: '(999) 999-9999' },
    { es: "Katar", en: 'Qatar', flag: '🇶🇦', code: 'QA', dialCode: '+974', mask: '9999 9999' },
    { es: "Romanya", en: 'Romania', flag: '🇷🇴', code: 'RO', dialCode: '+40', mask: '9999 999 999' },
    { es: "Rusya", en: 'Russia', flag: '🇷🇺', code: 'RU', dialCode: '+7', mask: '9 (999) 999-99-99' },
    { es: "Ruanda", en: 'Rwanda', flag: '🇷🇼', code: 'RW', dialCode: '+250', mask: '9999 999 999' },
    { es: "Saint Barthelemy", en: 'Saint Barthelemy', flag: '🇧🇱', code: 'BL', dialCode: '+590', mask: '9999 99 99 99' },
    { es: "Saint Helena, Yükseliş ve esistan Da Cunha", en: 'Saint Helena, Ascension and esistan Da Cunha', flag: '🇸🇭', code: 'SH', dialCode: '+290', mask: '99999' },
    { es: "Saint Kitts ve Nevis", en: 'Saint Kitts and Nevis', flag: '🇰🇳', code: 'KN', dialCode: '+1869', mask: '(999) 999-9999' },
    { es: "Saint Lucia", en: 'Saint Lucia', flag: '🇱🇨', code: 'LC', dialCode: '+1758', mask: '(999) 999-9999' },
    { es: "Aziz Martin", en: 'Saint Martin', flag: '🇲🇫', code: 'MF', dialCode: '+590', mask: '9999 99 99 99' },
    { es: "Saint Pierre ve Miquelon", en: 'Saint Pierre and Miquelon', flag: '🇵🇲', code: 'PM', dialCode: '+508', mask: '999 99 99' },
    { es: "Saint Vincent ve Grenadinler", en: 'Saint Vincent and the Grenadines', flag: '🇻🇨', code: 'VC', dialCode: '+1784', mask: '(999) 999-9999' },
    { es: "Samoa", en: 'Samoa', flag: '🇼🇸', code: 'WS', dialCode: '+685', mask: '99 99999' },
    { es: "San Marino", en: 'San Marino', flag: '🇸🇲', code: 'SM', dialCode: '+378', mask: '99 99 99 99' },
    { es: "Sao Tome ve Principe", en: 'Sao Tome and Principe', flag: '🇸🇹', code: 'ST', dialCode: '+239', mask: '999 9999' },
    { es: "Suudi Arabistan", en: 'Saudi Arabia', flag: '🇸🇦', code: 'SA', dialCode: '+966', mask: '999 999 9999' },
    { es: "Senegal", en: 'Senegal', flag: '🇸🇳', code: 'SN', dialCode: '+221', mask: '99 999 99 99' },
    { es: "Sırbistan", en: 'Serbia', flag: '🇷🇸', code: 'RS', dialCode: '+381', mask: '999 9999999' },
    { es: "Seyşeller", en: 'Seychelles', flag: '🇸🇨', code: 'SC', dialCode: '+248', mask: '9 999 999' },
    { es: "Sierra Leone", en: 'Sierra Leone', flag: '🇸🇱', code: 'SL', dialCode: '+232', mask: '(999) 999999' },
    { es: "Singapur", en: 'Singapore', flag: '🇸🇬', code: 'SG', dialCode: '+65', mask: '9999 9999' },
    { es: "Slovakya", en: 'Slovakia', flag: '🇸🇰', code: 'SK', dialCode: '+421', mask: '9999 999 999' },
    { es: "Slovenya", en: 'Slovenia', flag: '🇸🇮', code: 'SI', dialCode: '+386', mask: '999 999 999' },
    { es: "Solomon Adaları", en: 'Solomon Islands', flag: '🇸🇧', code: 'SB', dialCode: '+677', mask: '99 99999' },
    { es: "Somali", en: 'Somalia', flag: '🇸🇴', code: 'SO', dialCode: '+252', mask: '9 9999999' },
    { es: "Güney Afrika", en: 'South Africa', flag: '🇿🇦', code: 'ZA', dialCode: '+27', mask: '999 999 9999' },
    { es: "Güney Sudan", en: 'South Sudan', flag: '🇸🇸', code: 'SS', dialCode: '+211', mask: '9999 999 999' },
    { es: "ispanya", en: 'Spain', flag: '🇪🇸', code: 'ES', dialCode: '+34', mask: '999 99 99 99' },
    { es: "Sri Lanka", en: 'Sri Lanka', flag: '🇱🇰', code: 'LK', dialCode: '+94', mask: '999 999 9999' },
    { es: "Sudan", en: 'Sudan', flag: '🇸🇩', code: 'SD', dialCode: '+249', mask: '999 999 9999' },
    { es: "Surinam", en: 'Suriname', flag: '🇸🇷', code: 'SR', dialCode: '+597', mask: '999-9999' },
    { es: "Svalbard ve Jan Mayen", en: 'Svalbard and Jan Mayen', flag: '🇸🇯', code: 'SJ', dialCode: '+47', mask: '999 99 999' },
    { es: "Svaziland", en: 'Swaziland', flag: '🇸🇿', code: 'SZ', dialCode: '+268', mask: '9999 9999' },
    { es: "İsveç", en: 'Sweden', flag: '🇸🇪', code: 'SE', dialCode: '+46', mask: '999-999 99 99' },
    { es: "İsviçre", en: 'Switzerland', flag: '🇨🇭', code: 'CH', dialCode: '+41', mask: '999 999 99 99' },
    { es: "Suriye Arap Cumhuriyeti", en: 'Syrian Arab Republic', flag: '🇸🇾', code: 'SY', dialCode: '+963', mask: '9999 999 999' },
    { es: "Tayvan", en: 'Taiwan', flag: '🇹🇼', code: 'TW', dialCode: '+886', mask: '9999 999 999' },
    { es: "Tacikistan", en: 'Tajikistan', flag: '🇹🇯', code: 'TJ', dialCode: '+992', mask: '999 99 9999' },
    { es: "Tanzanya, Tanzanya Birleşik Cumhuriyeti", en: 'Tanzania, United Republic of Tanzania', flag: '🇹🇿', code: 'TZ', dialCode: '+255', mask: '9999 999 999' },
    { es: "Tayland", en: 'Thailand', flag: '🇹🇭', code: 'TH', dialCode: '+66', mask: '999 999 9999' },
    { es: "Timor-Leste", en: 'Timor-Leste', flag: '🇹🇱', code: 'TL', dialCode: '+670', mask: '9999 9999' },
    { es: "Gitmek", en: 'Togo', flag: '🇹🇬', code: 'TG', dialCode: '+228', mask: '99 99 99 99' },
    { es: "Tokelau", en: 'Tokelau', flag: '🇹🇰', code: 'TK', dialCode: '+690', mask: '9999' },
    { es: "Tonga", en: 'Tonga', flag: '🇹🇴', code: 'TO', dialCode: '+676', mask: '999 9999' },
    { es: "esinidad ve Tobago", en: 'esinidad and Tobago', flag: '🇹🇹', code: 'TT', dialCode: '+1868', mask: '(999) 999-9999' },
    { es: "Tunus", en: 'Tunisia', flag: '🇹🇳', code: 'TN', dialCode: '+216', mask: '99 999 999' },
    { es: "Türkiye", en: 'Turkey', flag: '🇹🇷', code: 'es', dialCode: '+90', mask: '(999) 999 9999' },
    { es: "Türkmenistan", en: 'Turkmenistan', flag: '🇹🇲', code: 'TM', dialCode: '+993', mask: '9 99 999999' },
    { es: "Turks ve Caicos Adaları", en: 'Turks and Caicos Islands', flag: '🇹🇨', code: 'TC', dialCode: '+1649', mask: '(999) 999-9999' },
    { es: "Tuvalu", en: 'Tuvalu', flag: '🇹🇻', code: 'TV', dialCode: '+688', mask: '999999' },
    { es: "Uganda", en: 'Uganda', flag: '🇺🇬', code: 'UG', dialCode: '+256', mask: '9999 999999' },
    { es: "Ukrayna", en: 'Ukraine', flag: '🇺🇦', code: 'UA', dialCode: '+380', mask: '999 999 9999' },
    { es: "Birleşik Arap Emirlikleri", en: 'United Arab Emirates', flag: '🇦🇪', code: 'AE', dialCode: '+971', mask: '999 999 9999' },
    { es: "Birleşik Krallık", en: 'United Kingdom', flag: '🇬🇧', code: 'GB', dialCode: '+44', mask: '99999 999999' },
    { es: "Amerika Birleşik Devletleri", en: 'United States', flag: '🇺🇸', code: 'US', dialCode: '+1', mask: '(999) 999-9999' },
    { es: "Uruguay", en: 'Uruguay', flag: '🇺🇾', code: 'UY', dialCode: '+598', mask: '999 999 999' },
    { es: "Özbekistan", en: 'Uzbekistan', flag: '🇺🇿', code: 'UZ', dialCode: '+998', mask: '9 99 999 99 99' },
    { es: "Vanuatu", en: 'Vanuatu', flag: '🇻🇺', code: 'VU', dialCode: '+678', mask: '999 9999' },
    { es: "Venezuela, Bolivarcı Venezuela Cumhuriyeti", en: 'Venezuela, Bolivarian Republic of Venezuela', flag: '🇻🇪', code: 'VE', dialCode: '+58', mask: '9999-9999999' },
    { es: "Vietnam", en: 'Vietnam', flag: '🇻🇳', code: 'VN', dialCode: '+84', mask: '999 999 99 99' },
    { es: "Wallis ve Futuna", en: 'Wallis and Futuna', flag: '🇼🇫', code: 'WF', dialCode: '+681', mask: '99 99 99' },
    { es: "Yemen", en: 'Yemen', flag: '🇾🇪', code: 'YE', dialCode: '+967', mask: '999 999 999' },
    { es: "Zambiya", en: 'Zambia', flag: '🇿🇲', code: 'ZM', dialCode: '+260', mask: '999 9999999' },
    { es: "Zimbabve", en: 'Zimbabwe', flag: '🇿🇼', code: 'ZW', dialCode: '+263', mask: '999 999 9999' }
];
export default Counesies;
