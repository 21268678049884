var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from 'react';
import { SHr, SIcon, SInput, SNavigation, SPopup, SText, STheme, SThread, SView } from 'servisofts-component';
var Centro = /** @class */ (function (_super) {
    __extends(Centro, _super);
    function Centro(props) {
        var _this = _super.call(this, props) || this;
        _this.state = __assign({ debe: null, haber: null, centro_costo: null }, _this.props.data);
        return _this;
    }
    Centro.prototype.getValue = function () {
        var _a;
        var exito = true;
        if (!this.inp_glosa.verify())
            exito = false;
        if (!this.inp_cuenta.verify())
            exito = false;
        var glosa = this.inp_glosa.getValue();
        if (!exito)
            return null;
        return {
            debe: this.state.debe,
            haber: this.state.haber,
            codigo_cuenta_contable: (_a = this.state.centro_costo) === null || _a === void 0 ? void 0 : _a.codigo,
            descripcion: glosa
        };
    };
    Centro.prototype.render_header = function () {
        return React.createElement(SView, { col: "xs-12", row: true },
            React.createElement(SView, { flex: true, style: { padding: this.props.padding } },
                React.createElement(SText, null, "DETALLE")),
            React.createElement(SView, { width: this.props.space }),
            React.createElement(SView, { width: 100, center: true, style: { padding: this.props.padding } },
                React.createElement(SText, null, "DEBE")),
            React.createElement(SView, { width: this.props.space }),
            React.createElement(SView, { width: 100, center: true, style: { padding: this.props.padding } },
                React.createElement(SText, null, "HABER")));
    };
    Centro.prototype.saveChange = function () {
        if (this.props.onChange)
            this.props.onChange(this.state);
        this.setState(__assign({}, this.state));
    };
    Centro.prototype.render_new = function () {
        var _this = this;
        var _a, _b, _c, _d, _e;
        if (!this.state.centro_costo) {
            //     if (this.props?.data?.key_cuenta_contable) {
            //         var cuenta_contable = Model.cuenta_contable.Action.getByKey(this.props?.data?.key_cuenta_contable, {}, null);
            //         if (!cuenta_contable) return <SLoad />
            //         this.state.centro_costo = cuenta_contable;
            //     } else {
            this.state.centro_costo = {};
            //     }
        }
        var styleInput = {
            fontSize: 11
        };
        return React.createElement(SView, { col: "xs-12", row: true, style: {
                justifyContent: "space-between",
                borderColor: STheme.color.card,
                borderRadius: 4
            } },
            React.createElement(SView, { col: "xs-1" },
                React.createElement(SHr, { height: this.props.space }),
                React.createElement(SView, { flex: true }),
                React.createElement(SView, { col: "xs-12", height: 26, center: true }, this.getDelete())),
            React.createElement(SView, { col: "xs-11 md-5" },
                React.createElement(SHr, { height: this.props.space }),
                React.createElement(SView, { flex: true }),
                React.createElement(SInput, { placeholder: "Ingrese el centro de costo", type: "textArea", height: 26, style: __assign(__assign({ fontWeight: "bold" }, styleInput), { paddingTop: 2, padding: 0 }), value: ((_a = this.state) === null || _a === void 0 ? void 0 : _a.centro_costo.codigo) ? (((_c = (_b = this.state) === null || _b === void 0 ? void 0 : _b.centro_costo) === null || _c === void 0 ? void 0 : _c.codigo) + " " + ((_e = (_d = this.state) === null || _d === void 0 ? void 0 : _d.centro_costo) === null || _e === void 0 ? void 0 : _e.descripcion)) : "", ref: function (ref) { return _this.inp_cuenta = ref; }, required: true, editable: false, onPress: function () {
                        var _a, _b;
                        SNavigation.navigate("/contabilidad/cuenta_centro_costo", {
                            codigo: (_b = (_a = _this.props) === null || _a === void 0 ? void 0 : _a.cuenta_contable) === null || _b === void 0 ? void 0 : _b.codigo,
                            onSelect: function (data) {
                                _this.state.centro_costo = data;
                                console.log(data);
                                _this.saveChange();
                                SNavigation.goBack();
                            }
                        });
                    } })),
            React.createElement(SView, { col: "xs-5.5 md-2.8" },
                React.createElement(SView, { flex: true }),
                React.createElement(SHr, { height: this.props.space }),
                React.createElement(SInput, { type: 'money', icon: React.createElement(SText, { color: STheme.color.gray, fontSize: 9 }, "DEBE"), placeholder: "DEBE", value: !this.state.debe ? "" : parseFloat(this.state.debe).toFixed(2), ref: function (ref) { return _this.inp_debe = ref; }, required: true, height: 26, style: __assign(__assign({}, styleInput), { fontSize: 11, fontWeight: "bold" }), onChangeText: function (obj) {
                        _this.state.debe = obj;
                        _this.state.haber = "";
                        _this.setState(__assign({}, _this.state));
                        new SThread(500, "thiloreder", true).start(function () {
                            _this.saveChange();
                        });
                    } })),
            React.createElement(SView, { col: "xs-5.5 md-2.8" },
                React.createElement(SHr, { height: this.props.space }),
                React.createElement(SView, { flex: true }),
                React.createElement(SInput, { type: 'money', icon: React.createElement(SText, { color: STheme.color.gray, fontSize: 9 }, "HABER"), placeholder: "HABER", value: !this.state.haber ? "" : parseFloat(this.state.haber).toFixed(2), ref: function (ref) { return _this.inp_haber = ref; }, required: true, height: 26, style: __assign(__assign({}, styleInput), { fontSize: 11, fontWeight: "bold" }), onChangeText: function (obj) {
                        _this.state.debe = "";
                        _this.state.haber = obj;
                        _this.setState(__assign({}, _this.state));
                        new SThread(500, "thiloreder", true).start(function () {
                            _this.saveChange();
                        });
                    } })));
    };
    Centro.prototype.getDelete = function () {
        var _this = this;
        return React.createElement(SView, { width: 26, height: 26, center: true, onPress: function () {
                var _a;
                if ((_a = _this.state.centro_costo) === null || _a === void 0 ? void 0 : _a.codigo) {
                    SPopup.confirm({
                        title: "Seguro que desea eliminar?",
                        onPress: function () {
                            _this.state.estado = 0;
                            _this.saveChange();
                        }
                    });
                }
                else {
                    _this.state.estado = 0;
                    _this.saveChange();
                }
            }, style: { padding: 2 } },
            React.createElement(SIcon, { name: 'Close', height: "100%", fill: STheme.color.danger }));
    };
    Centro.prototype.render = function () {
        return (React.createElement(SView, { col: "xs-12" },
            this.render_new(),
            React.createElement(SHr, { h: this.props.space * 2 })));
    };
    Centro.defaultProps = {
        padding: 0,
        space: 2
    };
    return Centro;
}(Component));
export default Centro;
