var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import { Text, View } from 'react-native';
import SModel from '../SModel';
var Root = /** @class */ (function (_super) {
    __extends(Root, _super);
    function Root(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {};
        return _this;
    }
    Root.prototype.describeModels = function () {
        var Models = SModel._models;
        return Object.keys(Models).map(function (name) {
            var obj = Models[name];
            var list = obj._getFunctions();
            console.log(list);
            return React.createElement(View, { key: name },
                React.createElement(Text, { style: {
                        textDecorationLine: "underline",
                        fontWeight: "bold"
                    } }, "\t" + name + "\t"),
                list.map(function (obj) { return React.createElement(Text, null, obj); }),
                React.createElement(Text, null, "\n"));
        });
    };
    Root.prototype.render = function () {
        return (React.createElement(View, { style: {
                backgroundColor: "#fff",
                flex: 1
            } },
            React.createElement(Text, null, "\n"),
            React.createElement(Text, { style: {
                    fontSize: 20,
                    fontWeight: "bold"
                } }, "servisofts-model"),
            React.createElement(Text, null, "\n"),
            React.createElement(Text, null, "\n"),
            React.createElement(View, null, this.describeModels())));
    };
    return Root;
}(Component));
export default Root;
