var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { SDate, SHr, SImage, SList, SText, STheme, SView } from 'servisofts-component';
import SSocket from 'servisofts-socket';
import PageAbstract from './PageAbstract';
var item = /** @class */ (function (_super) {
    __extends(item, _super);
    function item(props, config) {
        var _this = this;
        var _a, _b;
        _this = _super.call(this, props, __assign(__assign({}, config), { type: (_a = config.type) !== null && _a !== void 0 ? _a : "component" }), "item") || this;
        _this.row = (_b = config.row) !== null && _b !== void 0 ? _b : props.row;
        _this.padding = 4;
        if (config.padding !== null && config.padding !== undefined) {
            _this.padding = config.padding;
        }
        return _this;
    }
    item.prototype.$getData = function () {
        return this.props.data;
    };
    item.prototype.loadImage = function (pk) {
        var _a, _b;
        var src = (_a = this.Parent.model) === null || _a === void 0 ? void 0 : _a._get_image_download_path(SSocket.api, pk);
        if (!src)
            return null;
        var imageSize = { w: 60, h: 60 };
        switch ((_b = this.props) === null || _b === void 0 ? void 0 : _b.itemType) {
            case "2":
                imageSize = { w: 40, h: 40 };
                break;
        }
        return React.createElement(SView, { center: true, row: true, width: imageSize.w },
            React.createElement(SView, { width: 8 }),
            React.createElement(SView, { width: imageSize.w, height: imageSize.h, card: true, style: {
                    overflow: 'hidden'
                } },
                React.createElement(SImage, { src: src, style: {
                        position: "absolute",
                        resizeMode: "cover"
                    } }),
                React.createElement(SImage, { style: {
                        resizeMode: "cover"
                    }, src: src + "?time=" + (new Date().getTime() / 1000), enablePreview: true })),
            React.createElement(SView, { width: 8 }));
    };
    item.prototype.buildLabel = function (_a) {
        var _b;
        var label = _a.label, value = _a.value;
        // console.log(this.Parent)
        switch ((_b = this.props) === null || _b === void 0 ? void 0 : _b.itemType) {
            case "2":
                return React.createElement(SView, { col: "xs-12" },
                    React.createElement(SText, { col: "xs-12", fontSize: 14 }, value));
            case "1":
                return React.createElement(SView, { col: "xs-12", center: true },
                    React.createElement(SHr, null),
                    React.createElement(SText, { color: STheme.color.gray, fontSize: 14 }, "".concat(label, ": "),
                        " "),
                    React.createElement(SHr, { height: 4 }),
                    React.createElement(SView, { flex: true },
                        React.createElement(SText, { col: "xs-12", fontSize: 14 }, value)));
            default:
                return React.createElement(SView, { col: "xs-12", style: {} },
                    React.createElement(SView, { col: "xs-12", style: {
                            // alignItems: 'center',
                            padding: 4
                        } },
                        React.createElement(SText, { col: "xs-12", fontSize: 14, style: {
                                maxWidth: "100%"
                            } },
                            React.createElement(SText, { color: STheme.color.gray, fontSize: 12 }, "".concat(label, ": "),
                                " "),
                            value)));
        }
    };
    item.prototype.$renderContent = function () {
        var _this = this;
        var _a, _b;
        var arr = Object.keys((_b = (_a = this.Parent) === null || _a === void 0 ? void 0 : _a.model) === null || _b === void 0 ? void 0 : _b.Columns);
        return React.createElement(SList, { data: arr, filter: function (x) { return _this.excludes.indexOf(x) <= -1; }, space: 0, render: function (key) {
                var _a, _b, _c;
                var col = (_b = (_a = _this.Parent) === null || _a === void 0 ? void 0 : _a.model) === null || _b === void 0 ? void 0 : _b.Columns[key];
                var label = key;
                var value = _this.data[key];
                if (col) {
                    label = (_c = col.label) !== null && _c !== void 0 ? _c : key;
                    if (col.type == "timestamp") {
                        value = new SDate(value).toString('yyyy-MM-dd hh:mm');
                    }
                    if (col.type == "boolean") {
                        value = JSON.parse(value) == true ? "SI" : "NO";
                    }
                    if (col.type == "json") {
                        value = JSON.stringify(value);
                    }
                }
                return _this.buildLabel({ label: label, value: value });
            } });
    };
    item.prototype.renderContent = function () {
        var _a, _b, _c;
        this.data = this.$getData();
        if (!this.data)
            return this.$onLoading();
        return React.createElement(SView, { col: "xs-12", row: this.row, center: !this.row },
            !this.row ? null : React.createElement(SView, { width: this.padding }),
            this.loadImage(this.data[this.Parent.model.pk]),
            !this.row ? React.createElement(SHr, { height: this.padding }) : React.createElement(SView, { width: this.padding }),
            React.createElement(SView, { flex: true, center: true, col: (_a = this.props) === null || _a === void 0 ? void 0 : _a.col, style: {
                    maxWidth: "100%"
                } },
                ((_b = this.props) === null || _b === void 0 ? void 0 : _b.header) ? this.props.header(this) : null,
                this.$renderContent(),
                ((_c = this.props) === null || _c === void 0 ? void 0 : _c.footer) ? this.props.footer(this) : null));
    };
    item.prototype.$render = function () {
        return (React.createElement(SView, { card: true, col: "xs-12", style: {
                padding: this.padding
            }, onPress: this.props.onPress }, this.renderContent()));
    };
    return item;
}(PageAbstract));
export default item;
