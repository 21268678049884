var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import { SDate, SHr, SIcon, SInput, STheme, SView } from 'servisofts-component';
var index = /** @class */ (function (_super) {
    __extends(index, _super);
    function index(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {};
        return _this;
    }
    index.prototype.getValue = function () {
        var exito = true;
        if (!this.rconcepto.verify())
            exito = false;
        if (!this.rfecha.verify())
            exito = false;
        if (!this.rtipo.verify())
            exito = false;
        return {
            observacion: this.rconcepto.getValue(),
            fecha: this.rfecha.getValue(),
            tipo: this.rtipo.getValue(),
            exito: exito
        };
    };
    index.prototype.getBTN = function () {
        var _this = this;
        return React.createElement(SView, { width: 40, height: 40, center: true, onPress: function () {
                if (_this.props.onAdd)
                    _this.props.onAdd();
            }, style: { position: "absolute", top: 0, right: 0, padding: 8 } },
            React.createElement(SView, { style: { borderRadius: 100, overflow: "hidden" } },
                React.createElement(SIcon, { name: 'Add', height: "100%", fill: STheme.color.success })));
    };
    index.prototype.render = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h;
        return React.createElement(SView, { col: "xs-12", center: true },
            React.createElement(SView, { col: "xs-12", row: true },
                React.createElement(SView, { width: 120 },
                    React.createElement(SInput, { ref: function (ref) { return _this.rtipo = ref; }, placeholder: "TIPO", defaultValue: (_c = (_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.asiento_contable) === null || _b === void 0 ? void 0 : _b.tipo) !== null && _c !== void 0 ? _c : "ingreso", type: "select", options: ["ingreso", "egreso", "traspaso"], height: 30, style: {
                            textAlign: "center"
                        } })),
                React.createElement(SView, { flex: true }),
                React.createElement(SView, { width: 120 },
                    React.createElement(SInput, { ref: function (ref) { return _this.rfecha = ref; }, placeholder: "FECHA", type: "date", defaultValue: (_f = (_e = (_d = this.props) === null || _d === void 0 ? void 0 : _d.asiento_contable) === null || _e === void 0 ? void 0 : _e.fecha) !== null && _f !== void 0 ? _f : new SDate().toString("yyyy-MM-dd"), height: 30 }))),
            React.createElement(SHr, { h: 4 }),
            React.createElement(SView, { col: "xs-12" },
                React.createElement(SInput, { ref: function (ref) { return _this.rconcepto = ref; }, placeholder: "Ingrese el concepto...", defaultValue: (_h = (_g = this.props) === null || _g === void 0 ? void 0 : _g.asiento_contable) === null || _h === void 0 ? void 0 : _h.observacion, type: "textArea", required: true, height: 38, style: {
                        paddingTop: 2,
                        fontWeight: "bold",
                        fontSize: 13
                    } }),
                this.getBTN()));
    };
    return index;
}(Component));
export default index;
