import dato from "./dato";
import rol_dato from "./rol_dato";
import usuario_dato from "./usuario_dato";
import banco from "./banco";
import banco_cuenta from "./banco_cuenta";
import cuenta_movimiento from "./cuenta_movimiento";
export default {
    dato,
    rol_dato,
    usuario_dato,
    banco,
    banco_cuenta,
    cuenta_movimiento
}