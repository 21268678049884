var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var SReducer = /** @class */ (function () {
    function SReducer(_model) {
        this.model = _model;
    }
    SReducer.prototype.initialState = function (extra) {
        if (extra === void 0) { extra = {}; }
        return __assign({ service: this.model.info.service, component: this.model.info.component, version: this.model.info.version }, extra);
    };
    SReducer.prototype.getAll = function (state, action) {
        if (action.estado == "exito") {
            state.data = action.data;
        }
    };
    SReducer.prototype.registro = function (state, action) {
        if (action.estado == "exito") {
            if (state.data) {
                state.data[action.data[this.model.pk]] = action.data;
            }
        }
    };
    SReducer.prototype.editar = function (state, action) {
        if (action.estado == "exito") {
            if (state.data) {
                state.data[action.data[this.model.pk]] = action.data;
            }
        }
    };
    return SReducer;
}());
export default SReducer;
