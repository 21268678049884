var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import { SText, SView } from 'servisofts-component';
var CuentaContable = /** @class */ (function (_super) {
    __extends(CuentaContable, _super);
    function CuentaContable(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {};
        return _this;
    }
    CuentaContable.prototype.render = function () {
        return (React.createElement(SView, { card: true, style: {
                padding: 4
            } },
            React.createElement(SText, null, 'Seleccionar la cuenta contable')));
    };
    return CuentaContable;
}(Component));
export default CuentaContable;
