var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { SButtom, SHr, SLoad, SText, STheme, SView } from 'servisofts-component';
import PageAbstract from './PageAbstract';
import Enviroment from './Enviroment';
var index = /** @class */ (function (_super) {
    __extends(index, _super);
    function index(props, config) {
        var _this = this;
        var _a;
        config.params = __spreadArray(["pk"], (_a = config.params) !== null && _a !== void 0 ? _a : [], true);
        _this = _super.call(this, props, __assign({ type: "pageContainer" }, config), "restore") || this;
        _this.pk = _this.$params["pk"];
        return _this;
    }
    index.prototype.$onRestore = function () {
        return null;
    };
    index.prototype.$render = function () {
        var _this = this;
        this.data = this.$getData();
        if (!this.data)
            return React.createElement(SLoad, null);
        return React.createElement(SView, { center: true },
            React.createElement(SHr, { height: 30 }),
            React.createElement(SText, { center: true, fontSize: 18 },
                "Esta seguro que desea recuperar el elemento ",
                this.Parent.name,
                "?"),
            React.createElement(SHr, { height: 20 }),
            React.createElement(SText, { center: true, fontSize: 12, color: STheme.color.gray }, "Por su seguridad esta accion quedara registrada."),
            React.createElement(SHr, { height: 30 }),
            React.createElement(SButtom, { variant: "confirm", type: "outline", onPress: function () {
                    _this.$onRestore();
                } }, Enviroment.buttoms.restore));
    };
    return index;
}(PageAbstract));
export default index;
