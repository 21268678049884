import React from "react";
import { SExcelReader, SPopup, SText, SView } from "servisofts-component";
import Model from "../../Model";
export default (function (_a) {
    var data = _a.data;
    return React.createElement(SExcelReader, { onSubmit: function (resp, callback) {
            console.log("Entro al onSubmit");
            Model.cuenta_contable.Action.registroExcel(resp).then(function (r) {
                console.log(r);
                Model.cuenta_contable.Action.CLEAR();
                callback();
            })["catch"](function (e) {
                callback();
                if ((e === null || e === void 0 ? void 0 : e.error) == "elimine_asientos") {
                    SPopup.alert("No es posible sincronizar las cuentas debido a que ya existen asientos registrados.");
                    return;
                }
                SPopup.alert(e.error);
                console.error(e);
            });
        } },
        React.createElement(SView, { padding: 8, card: true },
            React.createElement(SText, null, "Importar")));
});
