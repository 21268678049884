var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SAction } from "servisofts-model";
var Action = /** @class */ (function (_super) {
    __extends(Action, _super);
    function Action() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Action.prototype.getAllByKeyUsuario = function (key) {
        var reducer = this._getReducer();
        if (reducer.key_usr != key) {
            reducer.data_usr = "";
            reducer.data = "";
            reducer.key_usr = key;
        }
        if (!reducer.data_usr) {
            var data = _super.prototype.getAll.call(this, {
                key_usuario: key
            });
            if (!data)
                return null;
            reducer.data_usr = data;
        }
        return reducer.data_usr;
    };
    Action.prototype.getAllByKeyRol = function (key) {
        var reducer = this._getReducer();
        if (reducer.key_rol != key) {
            reducer.data_rol = "";
            reducer.data = "";
            reducer.key_rol = key;
        }
        if (!reducer.data_rol) {
            var data = _super.prototype.getAll.call(this, {
                key_rol: key
            });
            if (!data)
                return null;
            reducer.data_rol = data;
        }
        return reducer.data_rol;
    };
    return Action;
}(SAction));
export default Action;
