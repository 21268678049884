var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from 'react';
import { SHr, SIcon, SInput, SLoad, SPopup, SText, STheme, SView } from 'servisofts-component';
import Model from '../../Model';
import PopupSelectCuentaContable from '../PopupSelectCuentaContable';
var Item = /** @class */ (function (_super) {
    __extends(Item, _super);
    function Item(props) {
        var _this = _super.call(this, props) || this;
        _this.state = __assign({ debe: null, haber: null, cuenta_contable: null }, _this.props.data);
        return _this;
    }
    Item.prototype.getValue = function () {
        var _a;
        var exito = true;
        if (!this.inp_glosa.verify())
            exito = false;
        if (!this.inp_cuenta.verify())
            exito = false;
        var glosa = this.inp_glosa.getValue();
        if (!exito)
            return null;
        return {
            debe: this.state.debe,
            haber: this.state.haber,
            key_cuenta_contable: (_a = this.state.cuenta_contable) === null || _a === void 0 ? void 0 : _a.key,
            descripcion: glosa
        };
    };
    Item.prototype.render_header = function () {
        return React.createElement(SView, { col: "xs-12", row: true },
            React.createElement(SView, { flex: true, style: { padding: this.props.padding } },
                React.createElement(SText, null, "DETALLE")),
            React.createElement(SView, { width: this.props.space }),
            React.createElement(SView, { width: 100, center: true, style: { padding: this.props.padding } },
                React.createElement(SText, null, "DEBE")),
            React.createElement(SView, { width: this.props.space }),
            React.createElement(SView, { width: 100, center: true, style: { padding: this.props.padding } },
                React.createElement(SText, null, "HABER")));
    };
    Item.prototype.saveChange = function () {
        if (this.props.onChange)
            this.props.onChange(this.state);
        this.setState(__assign({}, this.state));
    };
    Item.prototype.render_new = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
        if (!this.state.cuenta_contable) {
            if ((_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.key_cuenta_contable) {
                var cuenta_contable = Model.cuenta_contable.Action.getByKey((_d = (_c = this.props) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.key_cuenta_contable, {}, null);
                if (!cuenta_contable)
                    return React.createElement(SLoad, null);
                this.state.cuenta_contable = cuenta_contable;
            }
            else {
                this.state.cuenta_contable = {};
            }
        }
        return React.createElement(SView, { col: "xs-12", row: true, style: {
                justifyContent: "space-between"
            } },
            React.createElement(SView, { col: "xs-12 md-6", style: { padding: this.props.padding } },
                React.createElement(SInput, { placeholder: "Ingrese la cuenta...", type: "textArea", height: 44, style: {
                        paddingTop: 2,
                        padding: 0
                    }, value: ((_e = this.state) === null || _e === void 0 ? void 0 : _e.cuenta_contable.key) ? (((_g = (_f = this.state) === null || _f === void 0 ? void 0 : _f.cuenta_contable) === null || _g === void 0 ? void 0 : _g.codigo) + " " + ((_j = (_h = this.state) === null || _h === void 0 ? void 0 : _h.cuenta_contable) === null || _j === void 0 ? void 0 : _j.descripcion)) : "", ref: function (ref) { return _this.inp_cuenta = ref; }, required: true, editable: false, onPress: function () {
                        PopupSelectCuentaContable.open({
                            codigo: "",
                            soloHijos: true,
                            onSelect: function (obj) {
                                _this.state.cuenta_contable = obj;
                                _this.saveChange();
                            }
                        });
                    } }),
                React.createElement(SHr, { height: this.props.space }),
                React.createElement(SInput, { placeholder: "Ingrese la glosa...", type: "textArea", height: 44, defaultValue: (_l = (_k = this.props) === null || _k === void 0 ? void 0 : _k.data) === null || _l === void 0 ? void 0 : _l.descripcion, style: {
                        paddingTop: 2
                    }, center: true, ref: function (ref) { return _this.inp_glosa = ref; }, required: true, onChangeText: function (obj) {
                        _this.state.descripcion = obj;
                        _this.saveChange();
                    } }),
                React.createElement(SHr, { height: this.props.space })),
            React.createElement(SView, { col: "xs-5.5 md-2.8" },
                React.createElement(SInput, { type: 'money', icon: React.createElement(SText, { color: STheme.color.gray, fontSize: 9 }, "DEBE"), placeholder: "DEBE", value: !this.state.debe ? "" : parseFloat(this.state.debe).toFixed(2), ref: function (ref) { return _this.inp_debe = ref; }, required: true, onChangeText: function (obj) {
                        _this.state.debe = obj;
                        _this.state.haber = "";
                        _this.saveChange();
                    } })),
            React.createElement(SView, { col: "xs-5.5 md-2.8" },
                React.createElement(SInput, { type: 'money', icon: React.createElement(SText, { color: STheme.color.gray, fontSize: 9 }, "HABER"), placeholder: "HABER", value: !this.state.haber ? "" : parseFloat(this.state.haber).toFixed(2), ref: function (ref) { return _this.inp_haber = ref; }, required: true, onChangeText: function (obj) {
                        _this.state.debe = "";
                        _this.state.haber = obj;
                        _this.saveChange();
                    } })));
    };
    Item.prototype.getDelete = function () {
        var _this = this;
        return React.createElement(SView, { width: 40, height: 40, center: true, onPress: function () {
                var _a;
                if ((_a = _this.state.cuenta_contable) === null || _a === void 0 ? void 0 : _a.key) {
                    SPopup.confirm({
                        title: "Seguro que desea eliminar?",
                        onPress: function () {
                            _this.state.estado = 0;
                            _this.saveChange();
                        }
                    });
                }
                else {
                    _this.state.estado = 0;
                    _this.saveChange();
                }
            }, style: { position: "absolute", top: 48, right: 0, padding: 8 } },
            React.createElement(SIcon, { name: 'Close', height: "100%", fill: STheme.color.danger }));
    };
    Item.prototype.render = function () {
        return (React.createElement(SView, { col: "xs-12" },
            this.render_new(),
            React.createElement(SHr, null),
            this.getDelete()));
    };
    Item.defaultProps = {
        padding: 0,
        space: 4
    };
    return Item;
}(Component));
export default Item;
