var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import { SHr, SIcon, SInput, SMath, SText, STheme, SView } from 'servisofts-component';
import SSocket from 'servisofts-socket';
import { Linking } from 'react-native';
import Model from '../../Model';
var index = /** @class */ (function (_super) {
    __extends(index, _super);
    function index(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {};
        return _this;
    }
    // getTotal() {
    //     let total_debe = 0;
    //     let total_haber = 0;
    //     Object.values(this.props.data).map((obj: any) => {
    //         if (obj.debe)
    //             total_debe += parseFloat(obj.debe ?? 0) ?? 0
    //         if (obj.haber)
    //             total_haber += parseFloat(obj.haber ?? 0) ?? 0
    //     })
    //     return { debe: total_debe, haber: total_haber }
    // }
    index.prototype.getButtom = function () {
        var _this = this;
        return React.createElement(SView, { width: 40, height: 40, center: true, onPress: function () {
                var exito = true;
                if (!_this.rdesc.verify())
                    exito = false;
                if (_this.props.onAdd)
                    _this.props.onAdd({
                        descripcion: _this.rdesc.getValue(),
                        exito: exito
                    });
            }, style: { position: "absolute", bottom: 10, right: 0, padding: 4 } },
            React.createElement(SIcon, { name: 'Check', height: "100%", fill: !this.partida_doble ? STheme.color.danger : STheme.color.success }));
    };
    index.prototype.getButtomExport = function () {
        var _this = this;
        return React.createElement(SView, { width: 40, height: 40, center: true, onPress: function () {
                var exito = true;
                if (_this.props.asiento_contable) {
                    Model.asiento_contable.Action.pdf({ key: _this.props.asiento_contable.key }).then(function (resp) {
                        _this.setState({ loading: false });
                        Linking.openURL(SSocket.api.contabilidad + "pdf/" + resp.data);
                    })["catch"](function (e) {
                        _this.setState({ loading: false });
                        console.error(e);
                    });
                }
                // if (!this.rdesc.verify()) exito = false;
                // if (this.props.onAdd) this.props.onAdd({
                //     descripcion: this.rdesc.getValue(),
                //     exito: exito
                // })
            }, style: { position: "absolute", bottom: 10, right: 40, padding: 4 } },
            React.createElement(SText, null, "PDF"));
    };
    index.prototype.render = function () {
        var _this = this;
        var _a, _b;
        var _c = this.props.total, debe = _c.debe, haber = _c.haber;
        this.partida_doble = (parseFloat(debe).toFixed(2) == parseFloat(haber).toFixed(2)) && debe > 0 && haber > 0;
        return React.createElement(SView, { col: 'xs-12' },
            React.createElement(SView, { row: true, col: "xs-12", height: 40 },
                React.createElement(SView, { flex: true, style: {
                        justifyContent: "center",
                        alignItems: "flex-end"
                    } },
                    React.createElement(SText, { bold: true }, "TOTAL:")),
                React.createElement(SView, { width: 4 }),
                React.createElement(SView, { width: 120, center: true, card: true },
                    React.createElement(SText, { fontSize: 16, color: !this.partida_doble ? STheme.color.danger : STheme.color.success }, SMath.formatMoney(debe))),
                React.createElement(SView, { width: 4 }),
                React.createElement(SView, { width: 120, center: true, card: true },
                    React.createElement(SText, { fontSize: 16, color: !this.partida_doble ? STheme.color.danger : STheme.color.success }, SMath.formatMoney(haber)))),
            React.createElement(SHr, null),
            React.createElement(SView, { col: "xs-12", row: true },
                React.createElement(SView, { col: "xs-12" },
                    React.createElement(SInput, { required: true, placeholder: "Ingrese la descripcion...", defaultValue: (_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.asiento_contable) === null || _b === void 0 ? void 0 : _b.descripcion, type: "textArea", ref: function (ref) { return _this.rdesc = ref; }, height: 44, style: {
                            paddingTop: 2
                        } }))),
            React.createElement(SHr, null),
            this.getButtom(),
            this.getButtomExport());
    };
    return index;
}(Component));
export default index;
