var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
// import { connect } from 'servisofts-page';
import { SLoad, SPopup, STheme, SView } from 'servisofts-component';
import { Model } from 'servisofts-model';
import { PlanDeCuentasTable } from '..';
var CODE_POPUP = "popupSelectCuenta";
var PopupSelectCuentaContable = /** @class */ (function (_super) {
    __extends(PopupSelectCuentaContable, _super);
    function PopupSelectCuentaContable(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            loading: true
        };
        return _this;
    }
    PopupSelectCuentaContable.open = function (_a) {
        var codigo = _a.codigo, soloHijos = _a.soloHijos, onSelect = _a.onSelect;
        SPopup.open({
            key: CODE_POPUP,
            content: React.createElement(PopupSelectCuentaContable, { onSelect: onSelect, codigo: codigo, soloHojas: soloHijos })
        });
    };
    PopupSelectCuentaContable.prototype.render = function () {
        var _this = this;
        this.data = Model.cuenta_contable.Action.getAll();
        if (!this.data) {
            if (!this.state.loading) {
                this.setState({ loading: true });
            }
        }
        else {
            if (this.state.loading) {
                this.setState({ loading: false });
            }
        }
        return React.createElement(SView, { col: "xs-12 sm-10 md-8", height: 1024, withoutFeedback: true, style: {
                maxHeight: "100%"
            }, center: true },
            React.createElement(SView, { col: "xs-12", height: true, backgroundColor: STheme.color.background + "ee", style: {
                    borderRadius: 16,
                    padding: 8
                }, center: true }, this.state.loading ? React.createElement(SLoad, null) : React.createElement(PlanDeCuentasTable, { soloHojas: this.props.soloHojas, initialCode: this.props.codigo, onSelect: function (obj) {
                    if (_this.props.onSelect) {
                        if (Model.cuenta_contable.Action.getFirstChildrens(obj.codigo).length <= 0) {
                            _this.props.onSelect(obj);
                            SPopup.close(CODE_POPUP);
                            return;
                        }
                    }
                } })));
    };
    return PopupSelectCuentaContable;
}(Component));
export default PopupSelectCuentaContable;
