var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Model, SAction } from "servisofts-model";
import SSocket from "servisofts-socket";
import { PARAMS } from "../../PARAMS";
// import Model from "../..";
var Action = /** @class */ (function (_super) {
    __extends(Action, _super);
    function Action() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.props = {
            maxLvl: 6
        };
        return _this;
        // getAllOrder() {
        //     let separator = this.getSeparador();
        //     var data = this.getAll();
        //     if (!data) return null;
        //     var arr = Object.values(data);
        //     arr.sort((a: any, b: any) => {
        //         if (!a.codigo || !b.codigo) return 0;
        //         var arra = b.codigo.split(separator);
        //         var arrb = a.codigo.split(separator);
        //         for (let i = 0; i < arra.length; i++) {
        //             if (arrb.length <= i) {
        //                 return -1;
        //             }
        //             if (parseInt(arra[i]) > parseInt(arrb[i])) {
        //                 return -1;
        //             }
        //             if (parseInt(arra[i]) < parseInt(arrb[i])) {
        //                 return 1;
        //             }
        //         }
        //         return 0;
        //     })
        //     return arr;
        // }
    }
    Action.prototype.getSeparador = function () {
        return PARAMS.separador;
    };
    Action.prototype.getAllByKeyEmpresa = function (key_empresa, eliminados) {
        if (eliminados === void 0) { eliminados = false; }
        var reducer = this._getReducer();
        if (reducer.key_empresa != key_empresa) {
            reducer.data = "";
            reducer.key_empresa = key_empresa;
        }
        var resp = _super.prototype.getAll.call(this, {
            eliminado: eliminados,
            key_empresa: key_empresa,
            key_usuario: Model.usuario.Action.getKey()
        });
        return resp;
    };
    Action.prototype.getAll = function () {
        var empresa = Model.empresa.Action.getSelect();
        if (!empresa)
            return null;
        return this.getAllByKeyEmpresa(empresa.key);
    };
    Action.prototype.getFormat = function () {
        var data = this.getAll();
        if (!data)
            return null;
        var obj = {};
        Object.values(data).map(function (cuenta) {
            obj[cuenta.codigo] = cuenta;
        });
        return obj;
    };
    // getLvl(code) {
    //     return (code + "").split(this.getSeparador()).length;
    // }
    // getChildrens(code) {
    //     let separador = this.getSeparador();
    //     var data = this.getAll();
    //     if (!data) return null;
    //     if (!code) {
    //         return Object.values(data).filter((o: any) => o.codigo.length == 1);
    //     }
    //     let all = this.getAllChildrens(code);
    //     console.log(all);
    //     // let niveles = this.getNivelesArray();
    //     // var nivel = 0;
    //     // if (code) {
    //     // nivel = (code + "").split(separador).length;
    //     // }
    //     var values = Object.values(data).filter((o: any) => {
    //         if (!o.estado) return false;
    //         if (o.estado != 1) return false;
    //         // let lvl = (o.codigo + "").split(separador).length;
    //         // if (lvl - 1 == nivel) {
    //         // if (!code) return true;
    //         if ((o.codigo + "").length == (code + "").length) return false;
    //         if ((o.codigo + "").startsWith(code)) return true;
    //         // return false;
    //         // }
    //         return false;
    //     })
    //     console.log(values)
    //     return values;
    // }
    Action.prototype.getAllChildrens = function (code) {
        var data = this.getAll();
        if (!data)
            return null;
        var arr = Object.values(data);
        return arr.filter(function (o) {
            if ((o === null || o === void 0 ? void 0 : o.estado) != 1)
                return false;
            if (code) {
                if (!(o.codigo + "").startsWith(code))
                    return false;
            }
            return true;
        });
    };
    Action.prototype.getFirstChildrens = function (code) {
        var data = this.getAll();
        if (!data)
            return null;
        var arr = Object.values(data);
        var lowLvl = 0;
        arr = arr.filter(function (o) {
            if ((o === null || o === void 0 ? void 0 : o.estado) != 1)
                return false;
            var lvl = (o.codigo + "").length;
            if (code) {
                if (!(o.codigo + "").startsWith(code))
                    return false;
                if ((code + "").length >= lvl)
                    return false;
            }
            if (!lowLvl)
                lowLvl = lvl;
            if (lowLvl > lvl) {
                lowLvl = lvl;
            }
            return true;
        });
        arr = arr.filter(function (o) {
            var lvl = (o.codigo + "").length;
            if (lvl != lowLvl)
                return false;
            return true;
        });
        return arr;
    };
    Action.prototype.getNiveles = function (data) {
        var niveles = {};
        Object.values(data).map(function (obj) {
            var _a;
            var size = ((_a = obj.codigo) !== null && _a !== void 0 ? _a : "").length;
            if (!niveles[size])
                niveles[size] = [];
            niveles[size].push(obj);
        });
        return niveles;
    };
    // getNivelesArray() {
    //     var data = this.getNiveles();
    //     if (!data) return null;
    //     var niveles = Object.keys(data).sort((a: any, b: any) => a - b).map((key) => {
    //         return data[key]
    //     })
    //     return niveles;
    // }
    // getLvlCount(code) {
    //     let niveles = this.getNiveles();
    //     if (!niveles) return null;
    // }
    // getNivel(code) {
    //     var data = this.getAll();
    //     if (!data) return null;
    //     return niveles;
    // }
    // getChildrens(code) {
    //     let separador = this.getSeparador();
    //     console.log("entro al getchildrens")
    //     var data = this.getAll();
    //     if (!data) return null;
    //     var nivel = 0;
    //     if (code) {
    //         nivel = (code + "").split(separador).length;
    //     }
    //     return Object.values(data).filter((o: any) => {
    //         if (!o.estado) return false;
    //         if (o.estado != 1) return false;
    //         let lvl = (o.codigo + "").split(separador).length;
    //         if (lvl - 1 == nivel) {
    //             if (!code) return true;
    //             if ((o.codigo + "").startsWith(code)) return true;
    //             return false;
    //         }
    //         return false;
    //     })
    // }
    Action.prototype.registro = function (data) {
        var _a;
        data.key_empresa = (_a = Model.empresa.Action.getSelect()) === null || _a === void 0 ? void 0 : _a.key; //TODO
        // data.key_empresa = "74ac7008-5a7f-49da-9b0c-9a9961e4327e";
        if (!data.key_empresa) {
            return null;
        }
        return _super.prototype.registro.call(this, {
            data: data,
            key_usuario: Model.usuario.Action.getKey()
        });
    };
    Action.prototype.registroExcel = function (data) {
        var empresa = Model.empresa.Action.getSelect();
        if (!empresa)
            return null;
        return SSocket.sendPromise(__assign(__assign({}, this.model.info), { type: "registroExcel", data: data, key_empresa: empresa.key, key_usuario: Model.usuario.Action.getKey() }));
    };
    Action.prototype.editar = function (data) {
        return _super.prototype.editar.call(this, {
            data: data,
            key_usuario: Model.usuario.Action.getKey()
        });
    };
    Action.prototype.getByKey = function (key, extra, _default) {
        var _a;
        var data = this.getAll();
        if (!data)
            return null;
        return (_a = data[key]) !== null && _a !== void 0 ? _a : {};
    };
    Action.prototype.getByCode = function (code) {
        var data = this.getAll();
        if (!data)
            return null;
        return Object.values(data).find(function (a) { return a.codigo == code; });
    };
    Action.prototype.getAllOrder = function () {
        // let separator = this.getSeparador();
        var data = this.getAll();
        if (!data)
            return null;
        var arr = Object.values(data).sort(function (a, b) {
            if (!a.codigo || !b.codigo)
                return -1;
            var numA = a.codigo.replace(/[^\d]/g, "");
            var numB = b.codigo.replace(/[^\d]/g, "");
            if (numA < numB) {
                return -1;
            }
            if (numA > numB) {
                return 1;
            }
            return 0;
        });
        return arr;
    };
    Action.prototype.calcular_debe_haber = function (obj) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        if (obj.codigo.startsWith("1")) {
            return ((_a = obj.debe) !== null && _a !== void 0 ? _a : 0) - ((_b = obj.haber) !== null && _b !== void 0 ? _b : 0);
        }
        else if (obj.codigo.startsWith("2")) {
            return ((_c = obj.haber) !== null && _c !== void 0 ? _c : 0) - ((_d = obj.debe) !== null && _d !== void 0 ? _d : 0);
        }
        else if (obj.codigo.startsWith("3")) {
            return ((_e = obj.haber) !== null && _e !== void 0 ? _e : 0) - ((_f = obj.debe) !== null && _f !== void 0 ? _f : 0);
        }
        else if (obj.codigo.startsWith("4")) {
            return ((_g = obj.haber) !== null && _g !== void 0 ? _g : 0) - ((_h = obj.debe) !== null && _h !== void 0 ? _h : 0);
        }
        else if (obj.codigo.startsWith("5")) {
            return ((_j = obj.debe) !== null && _j !== void 0 ? _j : 0) - ((_k = obj.haber) !== null && _k !== void 0 ? _k : 0);
        }
        else if (obj.codigo.startsWith("6")) {
            return ((_l = obj.debe) !== null && _l !== void 0 ? _l : 0) - ((_m = obj.haber) !== null && _m !== void 0 ? _m : 0);
        }
    };
    return Action;
}(SAction));
export default Action;
