var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import Svg from "react-native-svg";
import { Path, Rect } from "react-native-svg";
var Web = function (props) { return (React.createElement(Svg, __assign({}, props, { viewBox: "0 0 141.95 141.73" }),
    React.createElement(Rect, { fill: "#fff", x: "0.07", width: "141.73", height: "141.73", rx: "34.98" }),
    React.createElement(Path, { d: "M74.49,86.67l1.39.18a3.4,3.4,0,0,1-1.25,1.89,4.14,4.14,0,0,1-5-.46,4.2,4.2,0,0,1-1-3,4.42,4.42,0,0,1,1.07-3.11,3.49,3.49,0,0,1,2.69-1.08A3.41,3.41,0,0,1,75,82.19a4.3,4.3,0,0,1,1,3.06v.36L70,85.55a3.12,3.12,0,0,0,.71,2,2.22,2.22,0,0,0,1.66.71,2,2,0,0,0,1.28-.38A2.64,2.64,0,0,0,74.49,86.67Zm-4.41-2.23,4.44,0A2.72,2.72,0,0,0,74,83a2,2,0,0,0-1.66-.8,2.14,2.14,0,0,0-1.57.6A2.49,2.49,0,0,0,70.08,84.44Z" }),
    React.createElement(Path, { d: "M76.68,89.25l.09-8H78v1.21a3.35,3.35,0,0,1,.87-1.11,1.52,1.52,0,0,1,.85-.26,2.62,2.62,0,0,1,1.38.44l-.48,1.25a1.89,1.89,0,0,0-1-.31,1.34,1.34,0,0,0-.8.26,1.48,1.48,0,0,0-.51.74,5.15,5.15,0,0,0-.24,1.57l0,4.16Z" }),
    React.createElement(Path, { d: "M84.32,89.34l-2.93-8h1.42l1.65,4.79c.18.51.34,1,.49,1.61.13-.43.3-.92.52-1.51l1.82-4.84,1.38,0-3.1,7.92Z" }),
    React.createElement(Path, { d: "M89.88,80l0-1.56,1.36,0V80Zm-.1,9.43.08-8,1.37,0-.09,8Z" }),
    React.createElement(Path, { id: "aXLOQSRsN", "fill-opacity": "0", stroke: "#010101", strokeWidth: "3.41px", d: "M41.48,102.1l54.41-2.34a11.24,11.24,0,0,0,10.75-11.69,9.23,9.23,0,0,0-.2-1.58c-.44-2.35.12.64-.44-2.4a10.46,10.46,0,0,0-11.25-8.47l-16.9,1.61a6.54,6.54,0,0,1-7.13-5.88,7.69,7.69,0,0,1,.06-1.79l.4-2.16a11.64,11.64,0,0,1,10.55-9.52l60.08-5" }),
    React.createElement(Path, { d: "M109.34,85.64a4,4,0,0,1,1.26-3.26,3.66,3.66,0,0,1,2.51-.86,3.53,3.53,0,0,1,2.67,1.1,4,4,0,0,1,1,3,5.22,5.22,0,0,1-.48,2.4A3.3,3.3,0,0,1,115,89.37a4,4,0,0,1-1.93.46,3.58,3.58,0,0,1-2.69-1.1A4.23,4.23,0,0,1,109.34,85.64Zm1.38,0a3.44,3.44,0,0,0,.64,2.3,2.23,2.23,0,0,0,3.14.24,2.08,2.08,0,0,0,.21-.21,3.4,3.4,0,0,0,.7-2.32,3.33,3.33,0,0,0-.65-2.24,2.23,2.23,0,0,0-3.13-.25l-.22.21A3.38,3.38,0,0,0,110.72,85.65Z" }),
    React.createElement(Path, { d: "M118.54,89.71l.07-6.92h-1.19v-1l1.19,0V80.9a3.72,3.72,0,0,1,.16-1.19,1.73,1.73,0,0,1,.69-.84,2.47,2.47,0,0,1,1.38-.32,6.58,6.58,0,0,1,1.26.15l-.21,1.18a5.44,5.44,0,0,0-.8-.09,1.16,1.16,0,0,0-.86.26,1.41,1.41,0,0,0-.26,1v.74l1.55,0v1H120l-.08,6.9Z" }),
    React.createElement(Path, { d: "M125.82,88.59l.18,1.19a4.9,4.9,0,0,1-1,.11,2.23,2.23,0,0,1-1.13-.25,1.3,1.3,0,0,1-.57-.61,5,5,0,0,1-.14-1.59l0-4.6h-1v-1h1l0-2,1.35-.8,0,2.78,1.35,0v1h-1.36l0,4.68a2.73,2.73,0,0,0,.07.75.51.51,0,0,0,.22.26,1,1,0,0,0,.47.11A5.6,5.6,0,0,0,125.82,88.59Z" }),
    React.createElement(Path, { d: "M126.61,87.43l1.34-.2a1.89,1.89,0,0,0,.61,1.24,2.18,2.18,0,0,0,1.44.44,2.13,2.13,0,0,0,1.38-.36,1.12,1.12,0,0,0,.45-.89.81.81,0,0,0-.39-.72,5.84,5.84,0,0,0-1.37-.47,13.28,13.28,0,0,1-2-.67,2,2,0,0,1-.84-.77,2.12,2.12,0,0,1,0-2,2.1,2.1,0,0,1,.67-.75,2.89,2.89,0,0,1,.86-.38,3.81,3.81,0,0,1,1.16-.15,4.45,4.45,0,0,1,1.65.29,2.19,2.19,0,0,1,1,.74A2.79,2.79,0,0,1,133,84l-1.32.16a1.45,1.45,0,0,0-.51-1,1.89,1.89,0,0,0-1.22-.36,2.18,2.18,0,0,0-1.32.29.89.89,0,0,0-.41.72.78.78,0,0,0,.16.47,1.24,1.24,0,0,0,.51.37c.14,0,.53.17,1.19.35a19.27,19.27,0,0,1,2,.65,1.9,1.9,0,0,1,1.19,1.88,2.21,2.21,0,0,1-.41,1.27,2.48,2.48,0,0,1-1.14.91A4.26,4.26,0,0,1,130,90a3.8,3.8,0,0,1-2.35-.67A3,3,0,0,1,126.61,87.43Z" }),
    React.createElement(Path, { id: "cjVyUe03N", "fill-opacity": "0", stroke: "#010101", strokeWidth: "3.41px", d: "M.07,99.43l54.42-2.35A11.22,11.22,0,0,0,65.23,85.4,9.22,9.22,0,0,0,65,83.82c-.45-2.35.12.64-.45-2.4A10.39,10.39,0,0,0,53.35,73l-16.9,1.6a6.59,6.59,0,0,1-7.15-6,7.07,7.07,0,0,1,.07-1.72l.41-2.17a11.68,11.68,0,0,1,10.55-9.51l60-5" }))); };
var Native = Web;
export default { Native: Native, Web: Web };
