var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from 'react';
import { SForm, SHr, SPopup, SText, STheme, SView } from 'servisofts-component';
import Model from '../../Model';
var POPUP_KEY = "popup_add_cuenta";
var PopupEditarCuenta = /** @class */ (function (_super) {
    __extends(PopupEditarCuenta, _super);
    function PopupEditarCuenta(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {};
        return _this;
    }
    PopupEditarCuenta.open = function (data) {
        SPopup.open({
            key: POPUP_KEY,
            content: React.createElement(PopupEditarCuenta, { data: data })
        });
    };
    PopupEditarCuenta.close = function () {
        SPopup.close(POPUP_KEY);
    };
    PopupEditarCuenta.prototype.formatCode = function (n) {
        return n < 10 ? "0" + n : n;
    };
    PopupEditarCuenta.prototype.getForm = function () {
        var _this = this;
        var _a, _b, _c, _d;
        // (!itmNewParent.codigo ? itmNewParent.codigo : (itmNewParent.codigo + SEPARATOR)) + this.formatCode((hijos.length + 1))
        var codigo = (_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.codigo;
        // var hio
        var disableCode = false;
        if (codigo) {
            if (Model.cuenta_contable.Action.getAllChildrens(codigo).length > 0) {
                // disableCode = true;
            }
        }
        return React.createElement(SForm, { inputs: {
                codigo: { label: "Codigo", defaultValue: codigo, required: true, disabled: disableCode },
                descripcion: { label: "Nombre", autoFocus: true, required: true, defaultValue: (_d = (_c = this.props) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.descripcion }
            }, onSubmitName: "EDITAR", onSubmit: function (data) {
                _this.setState({ loading: true, error: "cargando" });
                Model.cuenta_contable.Action.editar(__assign(__assign({}, _this.props.data), data)).then(function (resp) {
                    _this.setState({ loading: false, error: "" });
                    PopupEditarCuenta.close();
                })["catch"](function (e) {
                    _this.setState({ loading: false, error: "Error" });
                    console.log(e);
                });
            } });
    };
    PopupEditarCuenta.prototype.render = function () {
        var _a, _b, _c, _d, _e;
        return React.createElement(SView, { col: "xs-12 sm-10 md-8", height: 350, withoutFeedback: true, style: {
                maxHeight: "100%"
            }, center: true },
            React.createElement(SView, { col: "xs-12", height: true, backgroundColor: STheme.color.background + "ee", style: {
                    borderRadius: 16,
                    padding: 8
                }, center: true },
                React.createElement(SView, { col: "xs-12 sm-10 md-8", center: true, flex: true },
                    React.createElement(SHr, null),
                    React.createElement(SText, null, "Editar la cuenta"),
                    React.createElement(SHr, null),
                    React.createElement(SView, { col: "xs-12", center: true },
                        React.createElement(SText, { fontSize: 16, bold: true }, (_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 :
                            _b.codigo,
                            " - ", (_d = (_c = this.props) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 :
                            _d.descripcion)),
                    React.createElement(SView, { col: "xs-12", flex: true },
                        this.getForm(),
                        React.createElement(SText, { color: STheme.color.danger }, (_e = this.state) === null || _e === void 0 ? void 0 : _e.error)))));
    };
    return PopupEditarCuenta;
}(Component));
export default (PopupEditarCuenta);
